import { hasUserPermissionAnywhere } from ".";
import { UserPermissions } from "../constants";
import { UserMyPermissions } from "../types";

export const getDefaultRedirectUrlOnLogin = (
  userPermissions: UserMyPermissions,
) => {
  const hasCampaignListRole = hasUserPermissionAnywhere({
    permission: UserPermissions.ROLE_CAMPAIGN_LIST,
    userPermissions,
  });

  return hasCampaignListRole ? "/campaigns" : "/home";
};
