import React from "react";
import { Route, Routes } from "react-router-dom";
import { CampaignEditorPage } from "../../pages/campaign";
import { UserPermissions } from "../../store/slices/userData/constants";
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";
import CampaignsPage from "../../pages/campaigns/CampaignsPage";
import NotFoundPage from "../../pages/NotFoundPage";

const CampaignRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_CAMPAIGN_LIST}
            isAnywhereRole={true}
          >
            <CampaignsPage />
          </ProtectedByRoleRoute>
        }
      />

      <Route
        path="company/:companyId/campaign/:campaignId"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_CAMPAIGN_READ}
            isCompanyRole={true}
          >
            <CampaignEditorPage />
          </ProtectedByRoleRoute>
        }
      >
        <Route
          path="phase/:phaseId/step/:stepId"
          element={<CampaignEditorPage />}
        />
        <Route path="phase/:phaseId" element={<CampaignEditorPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default CampaignRoutes;
