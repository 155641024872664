import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageInstance } from "antd/es/message/interface";
import { HookAPI } from "antd/es/modal/useModal";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import handleRequestError, {
  TCustomError,
} from "../../utils/handleRequestError";
import { getSystemPermissionsApi } from "../../api/permissions-group.api";
import { TSystemPermissions } from "../../types/securityGroups";

const initialState = {
  isFetching: false,
  messageApi: {} as MessageInstance,
  modalApi: {} as HookAPI,
  systemPermissions: null as TSystemPermissions | null,
};

type InitialStateType = typeof initialState;

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleIsFetching: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.isFetching = action.payload;
    },
    setMessageApi: (
      state: InitialStateType,
      action: PayloadAction<MessageInstance>,
    ) => {
      state.messageApi = action.payload;
    },
    setModalApi: (state: InitialStateType, action: PayloadAction<HookAPI>) => {
      state.modalApi = action.payload;
    },
    setSystemPermissions: (
      state: InitialStateType,
      action: PayloadAction<TSystemPermissions>,
    ) => {
      state.systemPermissions = action.payload;
    },
  },
});

export const {
  toggleIsFetching,
  setMessageApi,
  setModalApi,
  setSystemPermissions,
} = appSlice.actions;

export default appSlice.reducer;

/* eslint-disable*/
export const getIsFetching = (): boolean =>
  useSelector((state: RootState) => state.app.isFetching);
export const getMessageApi = (): MessageInstance =>
  useSelector((state: RootState) => state.app.messageApi);
export const selectMessageApi = (state: RootState) => state.app.messageApi;
export const getModalApi = (): HookAPI =>
  useSelector((state: RootState) => state.app.modalApi);
export const selectSystemPermissions = (state: RootState) =>
  state.app.systemPermissions;

export const loadSystemPermissions = createAsyncThunk<
  TSystemPermissions,
  undefined,
  { state: RootState; rejectValue: TCustomError }
>(
  "app/loadSystemPermissionsThunk",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const { app: {systemPermissions} } = getState();

      if (!systemPermissions) {
        const { data } = await getSystemPermissionsApi();

        dispatch(setSystemPermissions(data));
        return data;
      }

      return systemPermissions;
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(e);

      return rejectWithValue(customError);
    }
  },
);
