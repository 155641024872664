import React from "react";
import { TNavigationItem } from "../../globalTypes";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import Navigation from "../common/Navigation";
import useRedirectToFirstChildOutlet from "../../hooks/navigation/useRedirectToFirstChildOutlet";

const UserDetailsTabs = () => {
  const { hasUserGlobalPermReadRole, hasUserCompaniesReadRole } =
    useUserHasPermission({});

  const navigationItems = [
    hasUserGlobalPermReadRole && {
      path: "global-security",
      title: "Global",
    },
    hasUserCompaniesReadRole && {
      path: "company-security",
      title: "Company",
    },
  ].filter(Boolean) as TNavigationItem[];

  useRedirectToFirstChildOutlet({ navigationItems });

  if (navigationItems.length === 0) {
    return null;
  }

  return (
    <div>
      <h2 className="font-bold text-[16px] pt-[24px] text-[#475569]">
        Security Groups
      </h2>
      <Navigation items={navigationItems} replace={true}/>
    </div>
  );
};

export default UserDetailsTabs;
