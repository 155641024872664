import clsx from "clsx";
import React from "react";
import { TFileTreeItem } from "src/globalTypes";
import useHover from "../../../hooks/useHover";
import {
  ChevronRightIcon,
  Icons,
  StorageDirClosedIcon,
  StorageDirOpenedIcon,
} from "../../common/Icons";
import StaticStorageItemActions from "./ItemActions";
import { GetMenuActions } from "./hooks/types";

type Props = {
  isSelected: boolean;
  item: TFileTreeItem;
  onExpandFolder: (folder: TFileTreeItem) => void;
  onSelectItem: () => void;
  selectedItem: TFileTreeItem | null;
  getItemMenuActions: GetMenuActions;
  setTreeData: (tree: TFileTreeItem[]) => void;
};
const StaticStorageFolder: React.FC<Props> = ({
  item,
  onExpandFolder,
  onSelectItem,
  isSelected,
  selectedItem,
  getItemMenuActions,
  setTreeData,
}) => {
  const { expanded, title } = item;
  const folderRef = React.useRef<HTMLDivElement | null>(null);
  const isHovering = useHover(folderRef);
  const handleExpandDir = () => {
    onExpandFolder(item);
  };
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSelectItem();
  };
  const hasMenuItems =
    getItemMenuActions({ item, setTree: setTreeData }).length > 0;
  return (
    <div
      ref={folderRef}
      onClick={handleClick}
      className={clsx(
        "inline-flex items-center gap-[4px] h-[32px] py-[6px] pr-[8px] transition-all cursor-pointer rounded-md",
        {
          "text-vePrimary bg-[#EEF2FF]": isSelected,
          "hover:bg-[#eee]": !isSelected,
        },
      )}
    >
      <div className="ml-[6px]">
        <ChevronRightIcon
          className={clsx("w-[20px] h-[20px]", {
            "rotate-90": expanded,
          })}
          onClick={handleExpandDir}
        />
      </div>
      <div className="flex items-center gap-[8px]">
        {expanded ? (
          <StorageDirOpenedIcon className="size-[20px]" />
        ) : (
          <StorageDirClosedIcon className="size-[20px]" />
        )}
        <div className="flex items-center gap-[28px]">
          <div>{title}</div>
          {isSelected && selectedItem ? (
            <StaticStorageItemActions
              item={selectedItem}
              getItemMenuActions={getItemMenuActions}
              setTree={setTreeData}
            />
          ) : hasMenuItems ? (
            <Icons.MoreDots
              className={clsx("size-[20px]", {
                "opacity-100": isHovering,
                "opacity-0": !isHovering,
              })}
            />
          ) : (
            <div className="size-[20px]" />
          )}
        </div>
      </div>
    </div>
  );
};
export default React.memo(StaticStorageFolder);
