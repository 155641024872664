import { useLocation } from "react-router-dom";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { TCcGridItemData } from "./CampaignGrid";

type Props = {
  isFetching: boolean;
  gridData: TCcGridItemData[];
};

const useScrollToKey = ({ isFetching, gridData }: Props) => {
  const { hash } = useLocation();
  const tableRef = useRef<any>(null);
  const [highlightedRowKey, setHighlightedRowKey] = useState<string>("");
  const timeoutRef: MutableRefObject<any> = useRef(null);

  useEffect(() => {
    if (!isFetching && gridData.length && hash) {
      if (tableRef.current && tableRef.current.scrollTo) {
        const key = hash.replace("#", "");

        tableRef.current.scrollTo({ key });
        setHighlightedRowKey(key);

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          setHighlightedRowKey("");
        }, 2000);
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [gridData, isFetching, hash]);

  return { tableRef, highlightedRowKey };
};

export default useScrollToKey;
