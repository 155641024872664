import { ClearOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Empty, Input, Skeleton, Table } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { TLabelsListData } from "../../globalTypes";
import useConfirm from "../../hooks/useConfirm";
import { getMessageApi } from "../../store/slices/appSlice";
import {
  deleteCampaignLabelsThunk,
  getCampaignLabels,
  getCampaignLabelsThunk,
  setCampaignLabels,
} from "../../store/slices/campaignLabelsSlice";
import { AppDispatch } from "../../store/store";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import handleRequestError from "../../utils/handleRequestError";
import CampaignLabelModal from "./CampaignLabelModal";
import { convertLabelsToArray } from "../../utils/cm.utils";

const { Column } = Table;

const CampaignLabelsList: FC = () => {
  const { companyId, campaignId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const confirm = useConfirm();
  const messageApi = getMessageApi();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [editModeProps, setEditModeProps] = useState<TLabelsListData | null>(
    null,
  );
  const { hasCampaignI18nDeleteRole, hasCampaignI18nUpdateRole } =
    useUserHasPermission({ companyId });
  const labels = convertLabelsToArray(getCampaignLabels());

  useEffect(() => {
    if (campaignId) {
      loadLabels(+campaignId);
    }

    return () => {
      dispatch(setCampaignLabels({}));
    };
  }, [campaignId]);

  const loadLabels = async (campaignId: number) => {
    try {
      await dispatch(getCampaignLabelsThunk(campaignId)).unwrap();
    } catch (e: any) {
      const error = handleRequestError(e);
      messageApi.error(error.message);
    } finally {
      setInitialized(true);
    }
  };

  // Filter labels based on search text
  const filteredData = useMemo(() => {
    if (!searchText) return labels;
    return labels.filter((item) =>
      item.labelKey.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [labels, searchText]);

  const handleBulkDelete = async () => {
    try {
      if (!campaignId) {
        messageApi.error("Campaign ID is undefined");
        return;
      }

      await dispatch(
        deleteCampaignLabelsThunk({
          keys: selectedRowKeys as string[],
          lang: "en",
          campaignId,
        }),
      );
      setSelectedRowKeys([]);
    } catch (e: any) {
      const error = handleRequestError(e);
      messageApi.error(error.message);
    }
  };

  const handleClearFilter = () => {
    setSearchText("");
    setSelectedRowKeys([]);
  };

  const handleSearchKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleEditLabel = (labelData: TLabelsListData) => {
    setEditModeProps(labelData);
    setIsEditModalOpen(true);
  };

  const rowSelection: TableRowSelection<TLabelsListData> = {
    selectedRowKeys,
    columnWidth: 30,
    onChange: (keys) => setSelectedRowKeys(keys),
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  if (!initialized) return <Skeleton active />;

  if (!labels.length) {
    return (
      <Empty
        imageStyle={{ height: "30vh" }}
        description={
          <span className="font-sans font-medium text-[#0F172A]">
            There are no Campaign Labels.
          </span>
        }
      />
    );
  }

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex items-center justify-between flex-wrap gap-2">
        <div className="flex items-center gap-2">
          <Input.Search
            size="small"
            placeholder="Enter label key"
            onChange={handleSearchKey}
            value={searchText}
          />
          <Button
            disabled={!searchText}
            size="small"
            type="default"
            icon={<ClearOutlined />}
            onClick={handleClearFilter}
          >
            Clear filter
          </Button>

          <div className="flex-shrink-0">{`Items: ${filteredData.length}`}</div>
        </div>

        {hasCampaignI18nDeleteRole && (
          <Button
            icon={<DeleteOutlined />}
            size="small"
            disabled={!selectedRowKeys.length}
            onClick={() =>
              confirm({
                action: handleBulkDelete,
                title: "Delete Labels",
              })
            }
            danger
          >
            Delete Selected
          </Button>
        )}
      </div>

      <Table
        size="small"
        dataSource={filteredData}
        rowSelection={hasCampaignI18nDeleteRole ? rowSelection : undefined}
        bordered
        pagination={false}
        scroll={{ x: 500, y: 600 }}
        virtual
      >
        <Column title="Key" dataIndex="labelKey" key="labelKey" width={150} />
        <Column title="Value" dataIndex="value" key="value" />
        {hasCampaignI18nUpdateRole && (
          <Column
            title=""
            key="action"
            align="center"
            width={70}
            render={(_, record: TLabelsListData) => (
              <Button
                size="small"
                type="link"
                onClick={() => handleEditLabel(record)}
              >
                Edit
              </Button>
            )}
          />
        )}
      </Table>

      {hasCampaignI18nUpdateRole && isEditModalOpen && (
        <CampaignLabelModal
          isOpened={isEditModalOpen}
          setIsOpened={setIsEditModalOpen}
          labelData={editModeProps}
        />
      )}
    </div>
  );
};

export default CampaignLabelsList;
