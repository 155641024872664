import React, { FC, useState } from "react";
import {
  copyMicroSiteStorageFileApi,
  deleteMicroSiteStorageFileApi,
  downloadMicroSiteStoragePDFFileApi,
  getMicroSiteStorageFileApi,
  getMicroSiteStorageFileLinkApi,
  getMicroSiteStorageFilesApi,
  uploadMicroSiteStorageFileApi,
} from "../../../api/microsite-storage.api";
import { TFileTreeItem, TStorageFileItem } from "../../../globalTypes";
import { CopyFileParams } from "../../../types";
import { MenuDropdownItem } from "../../common/MenuDropdown";
import { CopyFileModal } from "../../common/modals/CopyFileModal";
import { CreateFolderModal } from "../../common/modals/CreateFolderModal";
import EditFileDrawer from "../dynamicStorage/EditFileDrawer";
import useCopyFile from "../dynamicStorage/hooks/useCopyFile.hook";
import useCreateFolder from "../dynamicStorage/hooks/useCreateFolder.hook";
import useDelete from "../dynamicStorage/hooks/useDelete.hook";
import useDownload from "../dynamicStorage/hooks/useDownload.hook";
import useEditFile from "../dynamicStorage/hooks/useEditFile.hook";
import useGetLink from "../dynamicStorage/hooks/useGetLink.hook";
import useSaveFile from "../dynamicStorage/hooks/useSaveFile.hook";
import useUpload from "../dynamicStorage/hooks/useUpload.hook";
import useConfirm from "../../../hooks/useConfirm";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { allowedFilesToEdit, menuOptions } from "../dynamicStorage/constants";
import ItemActions from "../dynamicStorage/ItemActions";
import useTabPreview from "../dynamicStorage/hooks/useTabPreview.hook";
import UploadAssetsModal from "../../common/modals/UploadAssetsModal";
import { TMicroSite } from "../../../globalTypes";

type Props = {
  treeData: TFileTreeItem[];
  setTreeData: React.Dispatch<React.SetStateAction<TFileTreeItem[]>>;
  contextPath: string;
  companyId: string;
  item: TFileTreeItem;
  isHovering: boolean;
  isSelected: boolean;
  microsite: TMicroSite;
};

const ItemMenu: FC<Props> = ({
  setTreeData,
  treeData,
  contextPath,
  companyId,
  item,
  isHovering,
  isSelected,
  microsite,
}) => {
  const [isOpenCreateFolder, setIsOpenCreateFolder] = useState(false);
  const [isOpenCopyFile, setIsOpenCopyFile] = useState(false);
  const [isOpenUploadFiles, setIsOpenUploadFiles] = useState(false);
  const [isEditFileOpen, setIsEditFileOpen] = useState(false);
  const [fileData, setFileData] = useState<string | undefined>();
  const { fileName, folder, isRootDir, mimeType } = item;
  const isHTMLFile = mimeType === "text/html";
  const isEditFileAllowed = allowedFilesToEdit.includes(mimeType);
  const confirm = useConfirm();
  const {
    hasMsFsUploadRole,
    hasMsFsCopyRole,
    hasMsFsReadRole,
    hasMsFsDeleteRole,
    hasMsFsEditRole,
  } = useUserHasPermission({ companyId });

  const onDownloadFile = useDownload({
    download: async (path: string) => {
      return await getMicroSiteStorageFileApi({
        path,
        companyId,
      });
    },
  });
  const onDownloadPDFFile = useDownload({
    download: async (path: string) => {
      return await downloadMicroSiteStoragePDFFileApi({
        path,
        companyId,
      });
    },
  });
  const onUploadFile = useUpload({
    upload: async (formData: FormData) => {
      const { data } = await uploadMicroSiteStorageFileApi({
        formData,
        companyId,
      });
      return data;
    },
    setTreeData,
    selectedItem: item,
  });
  const onSaveFile = useSaveFile({
    saveFile: async (formData: FormData) => {
      const { data } = await uploadMicroSiteStorageFileApi({
        formData,
        companyId,
      });
      return data;
    },
    closeModal: () => setIsEditFileOpen(false),
    setTreeData,
    selectedItem: item,
  });
  const onDelete = useDelete({
    deleteFile: async (path: string) => {
      await deleteMicroSiteStorageFileApi({
        path,
        companyId,
      });
    },
    setTreeData,
  });
  const handleCopyFile = useCopyFile({
    copyFile: async (params: CopyFileParams) => {
      const { data } = await copyMicroSiteStorageFileApi({
        ...params,
        companyId,
      });
      return data;
    },
    setTreeData,
    treeData,
  });
  const onEditFile = useEditFile({
    getFileData: async (path: string) => {
      const { data } = await getMicroSiteStorageFileApi({
        path,
        companyId,
      });
      const decoder = new TextDecoder("utf-8");
      return decoder.decode(data);
    },
    setFileData,
    openEditDrawer: () => setIsEditFileOpen(true),
  });
  const onGetLink = useGetLink({
    getFileData: async (path: string) => {
      const { data } = await getMicroSiteStorageFileLinkApi({
        path,
        companyId,
      });
      return data;
    },
  });
  const handleCreateFolder = useCreateFolder({
    createFolder: async (formData: FormData) => {
      const { data } = await uploadMicroSiteStorageFileApi({
        formData,
        companyId,
      });
      return data;
    },
    setTreeData,
    selectedItem: item,
  });

  const onGetPublicLink = useGetLink({
    getFileData: async (path: string) => {
      const { data } = await getMicroSiteStorageFileLinkApi({
        path,
        companyId,
      });
      return data.replace(/^.*?:\/\//, "https://s3.us-west-1.amazonaws.com/");
    },
  });
  const onTabPreview = useTabPreview({
    getFileData: async (path: string) => {
      const { data } = await getMicroSiteStorageFileLinkApi({
        path,
        companyId,
      });
      return data;
    },
  });

  const getFolderFiles = async (path?: string): Promise<TStorageFileItem[]> => {
    const { data } = await getMicroSiteStorageFilesApi({
      path: path || contextPath,
      companyId: companyId as string,
    });
    return data;
  };
  const menuItems = [
    !folder &&
      hasMsFsEditRole &&
      isEditFileAllowed && {
        ...menuOptions.editFile,
        onClick: () => onEditFile(fileName),
      },
    !folder &&
      hasMsFsCopyRole && {
        ...menuOptions.copyFile,
        onClick: () => setIsOpenCopyFile(true),
      },
    hasMsFsReadRole &&
      !folder && {
        ...menuOptions.getLink,
        onClick: () => onGetLink(fileName),
      },
    hasMsFsReadRole &&
      !folder && {
        ...menuOptions.getPublicLink,
        onClick: () => onGetPublicLink(fileName),
      },
    hasMsFsReadRole &&
      !folder && {
        ...menuOptions.tabPreview,
        onClick: () => onTabPreview(fileName),
      },
    hasMsFsUploadRole &&
      folder && {
        ...menuOptions.createFolder,
        onClick: () => setIsOpenCreateFolder(true),
      },
    hasMsFsReadRole &&
      !folder && {
        ...menuOptions.download,
        onClick: () => onDownloadFile(fileName),
      },
    hasMsFsReadRole &&
      isHTMLFile &&
      microsite.enableHttps && {
        ...menuOptions.downloadPDF,
        onClick: () => onDownloadPDFFile(fileName),
      },
    folder &&
      hasMsFsUploadRole && {
        ...menuOptions.upload,
        onClick: () => setIsOpenUploadFiles(true),
      },
    hasMsFsDeleteRole &&
      !isRootDir && {
        ...menuOptions.delete,
        onClick: () =>
          confirm({
            action: () => onDelete(fileName),
            title: `Delete ${folder ? "directory" : "file"}`,
          }),
        className: "!text-red-600",
      },
  ].filter(Boolean) as MenuDropdownItem[];
  return (
    <>
      <ItemActions
        isSelected={isSelected}
        isHovering={isHovering}
        menuItems={menuItems}
      />
      <EditFileDrawer
        fileData={fileData}
        setIsEditFileOpen={setIsEditFileOpen}
        isEditFileOpen={isEditFileOpen}
        onSaveFile={onSaveFile}
        setFileData={setFileData}
        mimeType={mimeType}
      />
      {isOpenCreateFolder && (
        <CreateFolderModal
          open={isOpenCreateFolder}
          setOpen={setIsOpenCreateFolder}
          selectedPath={fileName}
          onCreate={handleCreateFolder}
        />
      )}
      {isOpenCopyFile && (
        <CopyFileModal
          getFolderFiles={getFolderFiles}
          open={isOpenCopyFile}
          setOpen={setIsOpenCopyFile}
          selectedPath={fileName}
          onCopy={handleCopyFile}
          pathPrefix={contextPath}
        />
      )}
      {isOpenUploadFiles && (
        <UploadAssetsModal
          uploadAsset={onUploadFile}
          setIsModalOpen={(open) => setIsOpenUploadFiles(open)}
          isModalOpen={isOpenUploadFiles}
        />
      )}
    </>
  );
};
export default ItemMenu;
