import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export type TCompany = {
  createdTime: null | string;
  id: number;
  logo: null | string;
  name: string;
  website: null | string;
  disabled: boolean;
};

const initialState = {
  current: null as TCompany | null,
  list: [] as Array<TCompany>,
};

type InitialStateType = typeof initialState;

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompanies: (
      state: InitialStateType,
      action: PayloadAction<Array<TCompany>>,
    ) => {
      state.list = action.payload;
    },
    setCurrentCompany: (
      state: InitialStateType,
      action: PayloadAction<TCompany | null>,
    ) => {
      state.current = action.payload;
    },
    setCurrentCompanyId: (
      state: InitialStateType,
      action: PayloadAction<number | null>,
    ) => {
      const company = state.list.find(
        (company) => company.id === action.payload,
      );
      if (company) {
        state.current = company;
      }
    },
  },
});

export const { setCompanies, setCurrentCompany, setCurrentCompanyId } =
  companiesSlice.actions;

export default companiesSlice.reducer;

export const selectCompanies = (state: RootState) => state.companies.list;
export const useCompaniesList = () => useSelector(selectCompanies);

export const getCurrentCompany = (): TCompany | null =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelector((state: RootState) => state.companies.current);
