import { TSocketMsg, TSocketMsgType } from "../types";
import Pako from "pako";

const parseMessageDataFromWS = async (
  data: any,
): Promise<TSocketMsg<TSocketMsgType>[]> => {
  let messages: TSocketMsg<TSocketMsgType>[] = [];

  if (data instanceof Blob) {
    try {
      const arrayBuffer = await data.arrayBuffer();
      const compressedData = new Uint8Array(arrayBuffer);
      const decompressedData = Pako.inflate(compressedData, {
        to: "string",
      });

      messages = JSON.parse(decompressedData);
    } catch (e) {
      console.error("Error parsing decompressed JSON", e);
    }
  } else if (typeof data === "string") {
    try {
      messages = JSON.parse(data);
    } catch (e) {
      console.warn("Error parsing string to JSON: ", data);
    }
  } else {
    console.warn("Unexpected data type received:", typeof data);
  }

  return messages;
};
export default parseMessageDataFromWS;
