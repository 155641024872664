import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { PostProcessingItem } from "src/components/dynamicFormItems/PostProcessing/PostProcessingItems";
import {
  deleteCcVarApi,
  editCcVarKeyApi,
  getCcVarsApi,
  getCcVarTypesApi,
  saveResultOvrApi,
  swapCcVarSequenceApi,
  updateCcVarStateApi,
} from "../../api/cc-variables.api";
import { sortBySequence } from "../../utils/cm.utils";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import { AppDispatch, RootState } from "../store";
import { toggleIsFetching } from "./appSlice";
import { FileMimeTypes, TModifiedInfo } from "../../globalTypes";

export type TCcVariables = {
  list: Array<TCcVariable>;
  dynamicFormItems: TCcFormItems;
  varsTypes: Array<TCcVariableType>;
};

export type TCcVariableType =
  | "string"
  | "function"
  | "prompt"
  | "asset"
  | "web_scraper"
  | "json"
  | "xml"
  | "file_content";

export type TComputationState = "created" | "processing" | "computed" | "error";

export type TCcVariableId = {
  campaignId: number;
  key: string;
  stepId: number;
};

export type TCcVariable = TModifiedInfo & {
  id: TCcVariableId;
  seq: number;
  type: TCcVariableType;
  mimeType: FileMimeTypes;
  result: null | string;
  resultOvr: null | string;
  executable: boolean;
  supportOverride: boolean;
  options?: TCcVarsFormOptionsValues;
  state?: TComputationState;
};

export type TCcVarsFormValues = TCcVarsFormRequiredValues &
  TCcVarsFormOptionsValues;

export type TCcVarsFormRequiredValues = {
  type: TCcVariableType;
  key: string;
  result: string | null;
  modifiedTime: string;
};

export type TPromptCcVarServicePovider = ["OPENAI", "CLAUDE"];

export type TModelServiceProvider = "BEDROCK" | "VERTEX_AI" | "OPENAI";

export type TCcVarsFormOptionsValues = {
  modelName?: string;
  serviceProvider?: TPromptCcVarServicePovider;
  cacheResult?: boolean;
  scriptContent?: string;
  arguments?: Array<string>;
  postProcessingParams?: PostProcessingItem[] | string;
  cacheResponse?: boolean;
  promptKey?: null | string;
  assetTitle?: string;
  overwriteIfExists?: boolean;
  publicStorage?: boolean;
  stepId?: number;
  templateId?: string;
  microSiteTargetFolder?: string;
};

//todo add types
export type TCcDynamicFormItemsFunction = [any, any];
export type TCcDynamicFormItemsPrompt = [any, any];
export type TCcDynamicFormItemsString = Array<any>;
export type TCcDynamicFormItemsAsset = Array<any>;
export type TCcDynamicFormItemsWebScrapper = Array<any>;

export type TCcDynamicFormItems =
  | TCcDynamicFormItemsFunction
  | TCcDynamicFormItemsPrompt
  | TCcDynamicFormItemsString
  | TCcDynamicFormItemsAsset
  | TCcDynamicFormItemsWebScrapper;

export type TCcResultType = "read_only" | "modifiable";

export type TCcItemProps<
  T extends TCcResultType,
  K extends TCcDynamicFormItems,
> = {
  executable: boolean;
  resultType: T;
  items: K;
};

export type TCcFormItems = {
  function: TCcItemProps<"read_only", TCcDynamicFormItemsFunction>;
  prompt: TCcItemProps<"read_only", TCcDynamicFormItemsPrompt>;
  string: TCcItemProps<"modifiable", TCcDynamicFormItemsString>;
  asset: TCcItemProps<"modifiable", TCcDynamicFormItemsAsset>;
  web_scraper: TCcItemProps<"modifiable", TCcDynamicFormItemsWebScrapper>;
  json: TCcItemProps<"modifiable", any[]>;
  xml: TCcItemProps<"modifiable", any[]>;
  file_content: TCcItemProps<"modifiable", any[]>;
};

type InitialStateType = TCcVariables;

const initialState: InitialStateType = {
  list: [],
  dynamicFormItems: {} as TCcFormItems,
  varsTypes: [],
};

const ccVariablesSlice = createSlice({
  name: "ccVariables",
  initialState,
  reducers: {
    setCcVariables: (
      state: InitialStateType,
      action: PayloadAction<Array<TCcVariable>>,
    ) => {
      state.list = action.payload;
    },
    setCcDynamicFormItems: (
      state: InitialStateType,
      action: PayloadAction<TCcFormItems>,
    ) => {
      const dynamicFormItems = Object.fromEntries(
        Object.entries(action.payload).map(([key, value]) => {
          return [
            key,
            {
              ...value,
              items: value.items.map((item) => {
                if (item.type === "checkbox") {
                  return {
                    ...item,
                    defaultValue: item.defaultValue === "true",
                  };
                }
                return item;
              }),
            },
          ];
        }),
      );

      state.dynamicFormItems = { ...action.payload, ...dynamicFormItems };
    },
    setCcVarsTypes: (
      state: InitialStateType,
      action: PayloadAction<Array<TCcVariableType>>,
    ) => {
      state.varsTypes = action.payload;
    },
    deleteCcVariable: (
      state: InitialStateType,
      action: PayloadAction<string>,
    ) => {
      state.list = state.list.filter((env) => env.id.key !== action.payload);
    },
    bulkUpdateCcVariables: (
      state: InitialStateType,
      action: PayloadAction<Array<TCcVariable>>,
    ) => {
      // Convert the list to an object for quick search
      const newItemsMap = action.payload.reduce(
        (acc, item) => {
          const idKey = `${item.id.campaignId}-${item.id.key}-${item.id.stepId}`;
          acc[idKey] = item;
          return acc;
        },
        {} as Record<string, TCcVariable>,
      );

      // Update existing items
      state.list = state.list.map((item) => {
        const idKey = `${item.id.campaignId}-${item.id.key}-${item.id.stepId}`;
        if (newItemsMap[idKey]) {
          return newItemsMap[idKey];
        }
        return item;
      });

      // Add new items that were not in the list
      const existingIds = new Set(
        state.list.map(
          (item) => `${item.id.campaignId}-${item.id.key}-${item.id.stepId}`,
        ),
      );
      state.list = [
        ...state.list,
        ...Object.values(newItemsMap).filter(
          (item) =>
            !existingIds.has(
              `${item.id.campaignId}-${item.id.key}-${item.id.stepId}`,
            ),
        ),
      ];
    },
  },
});

export const {
  setCcVariables,
  setCcDynamicFormItems,
  setCcVarsTypes,
  deleteCcVariable,
  bulkUpdateCcVariables,
} = ccVariablesSlice.actions;

export default ccVariablesSlice.reducer;

export const useCcVariables = (): Array<TCcVariable> =>
  useSelector((state: RootState) => state.ccVariables.list);
export const useCcDynamicFormItems = (): TCcFormItems =>
  useSelector((state: RootState) => state.ccVariables.dynamicFormItems);
export const useCcVarTypes = (): Array<TCcVariableType> =>
  useSelector((state: RootState) => state.ccVariables.varsTypes);

export const loadCcVarsThunk = createAsyncThunk<
  undefined,
  { campaignId: string | undefined; stepId: string | undefined },
  { rejectValue: any }
>(
  "ccVariables/loadCcVars",
  async ({ campaignId, stepId }, { rejectWithValue, dispatch }) => {
    try {
      if (!campaignId || !stepId) {
        console.error("Unable to get [campaignId] or [stepId]");
        return;
      }

      const { data: ccVars } = await getCcVarsApi({ campaignId, stepId });
      const sorted = sortBySequence(ccVars);

      dispatch(setCcVariables(sorted));
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const deleteCcVarThunk = createAsyncThunk<
  undefined,
  { key: string },
  { state: RootState; rejectValue: TCustomError }
>(
  "ccVariables/deleteCcVar",
  async ({ key }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaigns, steps } = getState();

      await deleteCcVarApi({
        campaignId: campaigns.current!.id,
        stepId: steps.current!.id,
        key,
      });

      dispatch(deleteCcVariable(key));
    } catch (e: any) {
      console.error(
        `An error occurred while trying to delete the grid item:`,
        e,
      );
      const customError = handleRequestError(e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);

export const saveCcItemResultOvrThunk = createAsyncThunk<
  undefined,
  {
    varId: TCcVariableId;
    value: string;
    resetDependencies?: boolean;
  },
  { rejectValue: TCustomError; dispatch: AppDispatch }
>(
  "ccVariables/saveCcItemResultOvrThunk",
  async (
    { varId, value, resetDependencies },
    { rejectWithValue, dispatch },
  ) => {
    try {
      dispatch(toggleIsFetching(true));
      const { campaignId, stepId, key } = varId;
      await saveResultOvrApi({
        campaignId,
        key,
        stepId,
        value,
        recomputeDownStream: resetDependencies,
      });

      await dispatch(
        loadCcVarsThunk({
          campaignId: campaignId.toString(),
          stepId: stepId.toString(),
        }),
      ).unwrap();
    } catch (e: any) {
      console.error(
        `An error occurred while trying to save the result override:`,
        e,
      );
      const customError = handleRequestError(e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);

export const editCcItemKeyThunk = createAsyncThunk<
  undefined,
  { currentKey: string; newKey: string; campaignId: number; stepId: number },
  { dispatch: AppDispatch; rejectValue: TCustomError }
>(
  "ccVariables/editCcItemKeyThunk",
  async (
    { currentKey, newKey, stepId, campaignId },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await editCcVarKeyApi({
        campaignId,
        currentKey,
        newKey,
        stepId,
      });

      await dispatch(
        loadCcVarsThunk({
          campaignId: campaignId.toString(),
          stepId: stepId.toString(),
        }),
      ).unwrap();
    } catch (e: any) {
      console.error(e);
      const customError = handleRequestError(e);

      return rejectWithValue(customError);
    }
  },
);

export const updateCcItemStateThunk = createAsyncThunk<
  undefined,
  {
    varId: TCcVariableId;
    state: TComputationState;
  },
  { rejectValue: TCustomError; dispatch: AppDispatch }
>(
  "ccVariables/updateCcItemStateThunk",
  async ({ varId, state }, { rejectWithValue, dispatch }) => {
    try {
      const { campaignId, stepId, key } = varId;

      await updateCcVarStateApi({ campaignId, state, stepId, key });

      await dispatch(
        loadCcVarsThunk({
          campaignId: campaignId.toString(),
          stepId: stepId.toString(),
        }),
      ).unwrap();
    } catch (e: any) {
      console.error(
        `An error occurred while trying to update the grid item state:`,
        e,
      );
      const customError = handleRequestError(e);

      return rejectWithValue(customError);
    }
  },
);

export const swapCcItemSequenceThunk = createAsyncThunk<
  undefined,
  { currentVar: TCcVariable; seqToSwap: number },
  { state: RootState; rejectValue: TCustomError }
>(
  "ccVariables/swapCcItemSequenceThunk",
  async (
    { currentVar, seqToSwap },
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaigns, steps } = getState();
      const campaignId = campaigns.current!.id;
      const stepId = steps.current!.id;

      const { data: updatedCcVarsList } = await swapCcVarSequenceApi({
        campaignId,
        stepId,
        cc1Seq: currentVar.seq,
        cc2Seq: seqToSwap,
      });
      const sorted = sortBySequence(updatedCcVarsList);

      dispatch(setCcVariables(sorted));
    } catch (e: any) {
      console.error(`An error occurred while trying to move the grid item:`, e);
      const customError = handleRequestError(e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);

export const loadDynamicItemsThunk = createAsyncThunk<
  void,
  void,
  { state: RootState; rejectValue: TCustomError }
>(
  "ccVariables/swapCcItemSequenceThunk",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const { ccVariables } = getState();

      // if types and dyn from items already loaded - skip loading
      if (!ccVariables.varsTypes.length) {
        const { data } = await getCcVarTypesApi();
        const ccVarTypes = Object.keys(data) as Array<TCcVariableType>;
        const ccDynamicFormItems = {} as TCcFormItems;

        for (const type in data) {
          // @ts-ignore
          ccDynamicFormItems[type] = data[type];
        }

        dispatch(setCcDynamicFormItems(ccDynamicFormItems));
        dispatch(setCcVarsTypes(ccVarTypes));
      }
    } catch (e: any) {
      console.error(`An error occurred while trying to move the grid item:`, e);
      const customError = handleRequestError(e);

      return rejectWithValue(customError);
    }
  },
);
