import { Empty } from "antd";
import React from "react";
import CompanyUsersListItem from "../CompanyUsersListItem/CompanyUsersListItem";
import { Table, TableBody } from "../../common/ui/table";
import { TCompanyUser } from "../../../globalTypes";
import { TableHeader } from "../../table/Header";

type PropsType = {
  users: TCompanyUser[];
  loadUsers: () => Promise<void>;
};

const CompanyUsersList: React.FC<PropsType> = ({ users, loadUsers }) => {
  if (!users.length) {
    return (
      <div className="container">
        <Empty
          imageStyle={{ height: "30vh" }}
          description={
            <div>
              <span>There are no users in this company.</span>
            </div>
          }
        />
      </div>
    );
  }

  const headerClassName = "font-sans font-bold text-[#0F172A] text-[14px]";
  const tableHeaders = [
    {
      title: "User",
      className: headerClassName,
    },
    {
      title: "Email",
      className: headerClassName,
    },
    {
      title: "Department",
      className: headerClassName,
    },
    {
      title: "Security Groups",
      className: headerClassName,
    },
    {
      title: "Last Login Time",
      className: headerClassName,
    },
    null,
  ];

  return (
    <div className="rounded-md border w-full mx-auto">
      <Table>
        <TableHeader items={tableHeaders} />
        <TableBody className="font-sans">
          {users.map((item) => (
            <CompanyUsersListItem
              key={item.id}
              user={item}
              loadUsers={loadUsers}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default CompanyUsersList;
