import { Checkbox, Form, Modal } from "antd";
import React, { FC, useEffect, useState } from "react";
import { TCampaign } from "src/store/slices/campaignsSlice";
import {
  exportCampaignApi,
  getCampaignExportInfoApi,
} from "../../../api/campaigns.api";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  downloadFileUtil,
  getExportSettingQueryParam,
} from "../../../utils/cm.utils";
import { CampaignExportInfo, ExportCampaignParams } from "../../../globalTypes";
import handleRequestError from "../../../utils/handleRequestError";
import customizeFormLabels from "../../../utils/customizeFormLabels";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";

type LoadingState = "submit" | "init" | null;

type Props = {
  campaignId: number;
  currentCampaign: TCampaign | null;
  setIsOpenExportModal: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenExportModal: boolean;
};

const ExportCampaignModal: FC<Props> = ({
  campaignId,
  currentCampaign,
  setIsOpenExportModal,
  isOpenExportModal,
}) => {
  const messageApi = getMessageApi();
  const [isLoading, setIsLoading] = useState<LoadingState>("init");
  const [exportInfo, setExportInfo] = useState<CampaignExportInfo | null>(null);
  const [form] = Form.useForm<ExportCampaignParams>();

  useSubmitFormOnEnter(() => onFormSubmit(), { condition: !isLoading });

  useEffect(() => {
    getExportInfo();
  }, []);

  const getExportInfo = async () => {
    try {
      const { data } = await getCampaignExportInfoApi({ campaignId });
      setExportInfo(data);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(null);
    }
  };

  const onExport = async (values: ExportCampaignParams) => {
    try {
      setIsLoading("submit");
      const params = getExportSettingQueryParam(values);

      const response = await exportCampaignApi({
        campaignId,
        params,
      });
      const fileName = `${currentCampaign ? currentCampaign.name : "CampaignData"}.zip`;

      downloadFileUtil(response, fileName);

      messageApi.success("Successfully exported");
    } catch (e: any) {
      if (e.response && e.response.data instanceof Blob) {
        const errorText = await e.response.data.text();
        try {
          const errMessage = JSON.parse(errorText);
          messageApi.error(errMessage?.debugMessage || e?.message);
        } catch (e) {
          console.error("Error converting Blob to JSON", e);
        }
      } else {
        messageApi.error(`Export error: ${e?.message}`);
      }

      console.error("Can't export campaign", e);
    } finally {
      setIsOpenExportModal(false);
      setIsLoading(null);
    }
  };

  const onFormSubmit = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: ExportCampaignParams) => {
      await onExport(values);
    },
  });

  return (
    <Modal
      title="Export Campaign"
      open={isOpenExportModal}
      okText="Export"
      cancelText="Cancel"
      confirmLoading={isLoading === "submit"}
      loading={isLoading === "init"}
      onOk={onFormSubmit}
      onCancel={() => setIsOpenExportModal(false)}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={customizeFormLabels}
        name="export_campaign"
        initialValues={{
          I18N: true,
          ENV: true,
          CC: true,
          ASSETS_AND_STORAGE: true,
        }}
      >
        <Form.Item name="ENV" className="mb-1" valuePropName="checked">
          <Checkbox>
            {`${exportInfo ? `Environment Variables (${exportInfo.environmentItemsCount})` : "Environment Variables"}`}
          </Checkbox>
        </Form.Item>
        <Form.Item name="CC" className="mb-1" valuePropName="checked">
          <Checkbox>
            {`${exportInfo ? `Computation Items (${exportInfo.ccItemsCount})` : "Computation Items"}`}
          </Checkbox>
        </Form.Item>
        <Form.Item name="I18N" className="mb-1" valuePropName="checked">
          <Checkbox>
            {`${exportInfo ? `Labels (${exportInfo.campaignI18nsCount})` : "Labels"}`}
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="ASSETS_AND_STORAGE"
          className="mb-1"
          valuePropName="checked"
        >
          <Checkbox>
            {`${exportInfo ? `Storage and Assets (${exportInfo.assetsCount})` : "Storage and Assets"}`}
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ExportCampaignModal;
