import ModelConfigsList from "src/components/modelConfigs/List";
import ModelConfigsHeader from "src/components/modelConfigs/TableHeader";

const ModelConfigsListPage = () => {
  return (
    <div className="w-full flex flex-col gap-4">
      <ModelConfigsHeader />
      <ModelConfigsList />
    </div>
  );
};

export default ModelConfigsListPage;
