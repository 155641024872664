import React, { FC } from "react";
import { useParams } from "react-router-dom";
import useConfirm from "../../hooks/useConfirm";
import {
  TCcVariableType,
  TComputationState,
} from "../../store/slices/ccVariablesSlice";
import { NodeType } from "./EditorPane";
import StateIcon from "./StateIcon";

type Props = {
  nodeType: NodeType | "EDIT";
  ccItemKey: string;
  type: TCcVariableType;
  stepId: number;
  phaseId: number;
  phaseName: string;
  stepName: string;
  state: TComputationState | undefined;
};
const PaneInfoSection: FC<Props> = ({
  nodeType,
  ccItemKey,
  type,
  stepId,
  phaseId,
  stepName,
  phaseName,
  state,
}) => {
  const { companyId, campaignId } = useParams();
  const { origin } = window.location;
  const url = `${origin}/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}?view=GRID#${ccItemKey}`;
  const confirm = useConfirm();

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    confirm({
      title: "Confirm Navigation",
      content: "Are you sure you want to navigate to this link?",
      action: () => {
        const newWindow = window.open();
        if (newWindow) {
          newWindow.opener = null;
          newWindow.location.href = url;
        }
      },
    });
  };

  return (
    <div className="flex items-center gap-[12px]">
      <span>
        <span className="font-bold">{nodeType}</span>:{" "}
        <span className="italic">{type}</span> - {ccItemKey}
      </span>
      <StateIcon state={state} />
      <div>
        <a
          onClick={handleLinkClick}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="font-normal text-[16px] text-vePrimary break-words"
        >
          {`/${phaseName}/${stepName}/${ccItemKey}`}
        </a>
      </div>
    </div>
  );
};

export default PaneInfoSection;
