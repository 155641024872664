import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { Alert, Skeleton } from "antd";
import handleRequestError from "../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../store/slices/appSlice";
import { ApiVersionData } from "../../globalTypes";

const ApiVersion = () => {
  const [apiData, setApiData] = useState<ApiVersionData | null>(null);
  const [initialized, setInitialized] = useState(false);
  const messageApi = useSelector(selectMessageApi);

  useEffect(() => {
    getReleaseData();
  }, []);

  const getReleaseData = async () => {
    try {
      const { data } = await axios.get<ApiVersionData>(`/api/public/version`);

      setApiData(data);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setInitialized(true);
    }
  };

  if (!initialized) {
    return <Skeleton active />;
  }

  return (
    <div>
      {apiData && (
        <div className="flex flex-col gap-2">
          <h3 className="text-base font-bold text-[#475569] self-center">
            API Version
          </h3>

          <div className="flex gap-4">
            <div className="w-[130px] flex flex-col">
              <span className="font-semibold text-[#475569] text-sm">
                {format(apiData.time, "MMMM d")}
              </span>
              <span className="text-xs">
                {format(apiData.time, "h:mm a zzz")}
              </span>
            </div>

            <Alert
              className="w-full"
              type="warning"
              message={
                <ul className="version-details">
                  <li>
                    <span className="font-semibold text-[#475569]">Name:</span>{" "}
                    {apiData.name}
                  </li>
                  <li>
                    <span className="font-semibold text-[#475569]">
                      Version:
                    </span>{" "}
                    {apiData.version}
                  </li>
                </ul>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ApiVersion;
