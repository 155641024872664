import {
  getCurrentCompany,
  selectCompanies,
} from "../../store/slices/companiesSlice";
import { useSelector } from "react-redux";
import CampaignListHeader from "../../components/campaignsList/CampaignListHeader";
import CampaignsList from "../../components/campaignsList/CampaignsList";
import CampaignsPagination from "../../components/campaignsList/CampaignsPagination";
import useCampaignsList, {
  ALL_TEMPLATES_ID,
} from "../campaigns/useCampaignsList";
import { EmptyResult } from "src/components/common/EmptyResult";

const CampaignTemplatesPage = () => {
  const {
    requestParams,
    additionalParams,
    requestCampaignsList,
    handleChangePage,
    handleChangePageSize,
    handleChangeCompany,
    handleChangeCampaignType,
    updateSorting,
    fetching,
  } = useCampaignsList();

  const companies = useSelector(selectCompanies);
  const currentCompany = getCurrentCompany();

  if (!companies.length && !fetching) {
    return <EmptyResult message="There are no campaigns in your profile." />;
  }

  return (
    <div className="flex flex-col w-full gap-[24px]">
      <CampaignListHeader
        requestParams={requestParams}
        backUrl={additionalParams.backUrl || ""}
        currentCompany={currentCompany}
        onCompanyChange={handleChangeCompany}
        onCampaignTypeChange={handleChangeCampaignType}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        enabledTypes={[
          ALL_TEMPLATES_ID,
          "DRAFT_TEMPLATE",
          "PUBLISHED_TEMPLATE",
        ]}
      />
      <CampaignsList
        requestParams={requestParams}
        requestCampaignsList={requestCampaignsList}
        updateSorting={updateSorting}
        fetching={fetching}
      />
      <CampaignsPagination
        page={requestParams.page}
        pageSize={requestParams.pageSize}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
      />
    </div>
  );
};

export default CampaignTemplatesPage;
