import { FC } from "react";
import { TableHeader } from "src/components/table/Header";
import { CampaignsListRequestParams } from "src/pages/campaigns/useCampaignsList";
import { TCampaign, useCampaigns } from "../../store/slices/campaignsSlice";
import { Table, TableBody } from "../common/ui/table";
import CampaignListItem from "./CampaignListItem";
import { EmptyResult } from "../common/EmptyResult";
import { SortingDirection } from "src/types/docTemplates";
import { CampaignListItemSkeleton } from "./CampaignListItemSkeleton";
import { useLocation } from "react-router-dom";
import { getNextOrder } from "src/utils";

type PropsType = {
  requestParams: CampaignsListRequestParams;
  requestCampaignsList: (params: CampaignsListRequestParams) => void;
  updateSorting: (
    sortBy: keyof TCampaign,
    orderBy: SortingDirection | undefined,
  ) => void;
  fetching: boolean;
};

const CampaignsList: FC<PropsType> = ({
  requestParams,
  requestCampaignsList,
  updateSorting,
  fetching,
}) => {
  const campaigns = useCampaigns();

  const isTemplatesPage = useLocation().pathname.includes("/templates");

  const tableHeaderItems = [
    {
      title: "Campaign",
      className: "font-sans w-2/8",
      name: "name",
      isSortable: true,
    },
    {
      title: "Company",
      className: "font-sans w-[240px]",
      name: "companyName",
      isSortable: true,
    },
    {
      title: "Type",
      className: "font-sans w-[120px]",
      name: "type",
      isSortable: isTemplatesPage,
    },
    {
      title: "Last Modified",
      className: "font-sans w-[140px]",
      name: "modifiedTime",
      isSortable: true,
    },
    { title: "", className: "font-sans w-[40px]" },
  ];

  if (!campaigns.length && !fetching) {
    const emptyResultMessage = isTemplatesPage
      ? "There are no campaigns with type Draft or Publish in this company."
      : "There are no active campaigns in this company.";

    return <EmptyResult message={emptyResultMessage} />;
  }

  const handleReloadCurrentList = () => {
    requestCampaignsList(requestParams);
  };

  const handleUpdateSorting = (field: string) => {
    if (field !== requestParams.sortBy) {
      updateSorting(field as keyof TCampaign, SortingDirection.ASC);
      return;
    }

    updateSorting(
      field as keyof TCampaign,
      getNextOrder(requestParams.orderBy),
    );
  };

  return (
    <div className="rounded-md border w-full mx-auto">
      <Table>
        <TableHeader
          items={tableHeaderItems}
          sorting={{
            field: requestParams.sortBy,
            direction: requestParams.orderBy,
          }}
          handleUpdateSorting={handleUpdateSorting}
        />
        <TableBody className="font-sans">
          {fetching ? (
            <>
              {Array.from({ length: 15 }).map((_, index) => (
                <CampaignListItemSkeleton key={index} />
              ))}
            </>
          ) : (
            campaigns.map((item) => (
              <CampaignListItem
                key={item.id}
                item={item}
                onReloadCurrentList={handleReloadCurrentList}
              />
            ))
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default CampaignsList;
