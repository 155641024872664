import { Checkbox, Typography } from "antd";
import { FC, useState } from "react";
import { TAsset } from "../../globalTypes";
import DeleteWithFilesModal from "../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import { TableCell, TableRow } from "../common/ui/table";
import MenuDropdown, { MenuDropdownItem } from "../common/MenuDropdown";
import { DeleteIcon, GetLinkIcon, Icons } from "../common/Icons";
import { Button } from "../common/ui/button";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../store/slices/appSlice";
import { MICROSITE_INPUT_PLACEHOLDER } from "../../constants";
import clsx from "clsx";
import { GlobeAsiaAustraliaIcon } from "@heroicons/react/16/solid";

type Props = {
  asset: TAsset;
  onApproveAsset: (id: number, approved: boolean) => Promise<void>;
  onApprovePublish: (asset: TAsset, approved: boolean) => Promise<void>;
  onPublishAsset: (id: number) => Promise<void>;
  onEditAsset: (asset: TAsset) => void;
  onDeleteAsset: (id: number, removeFiles?: boolean) => Promise<void>;
};

const AssetListItem: FC<Props> = ({
  asset,
  onApproveAsset,
  onApprovePublish,
  onEditAsset,
  onPublishAsset,
  onDeleteAsset,
}) => {
  const { companyId } = useParams();
  const {
    title,
    ref,
    id,
    approved,
    publishToMicroSite,
    publishedDate,
    microSiteTargetFolder,
    type,
    deployedUrl,
  } = asset;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const publishedDateValidated = publishedDate
    ? `Published: ${new Date(publishedDate).toLocaleString()}`
    : "Not published yet";
  const {
    hasAssetDeployRole,
    hasAssetDeleteRole,
    hasAssetUpdateRole,
    hasAssetApproveRole,
    hasAssetApprovePubRole,
    hasAssetUIGetPubAWSLink,
  } = useUserHasPermission({ companyId });

  const onLink = async (ref: string) => {
    try {
      await navigator.clipboard.writeText(ref);

      messageApi.success("The link has been copied to the clipboard!");
    } catch (e) {
      messageApi.error("Failed to copy the link");
      console.error("Copy link ERROR:", e);
    }
  };

  const menuItems = [
    hasAssetUIGetPubAWSLink &&
      deployedUrl && {
        key: "get_link",
        label: "Get Link",
        onClick: () => onLink(deployedUrl),
        icon: GetLinkIcon,
      },
    hasAssetUIGetPubAWSLink &&
      ref && {
        key: "get_aws_link",
        label: "Get Public AWS Link",
        onClick: () => onLink(ref),
        icon: GlobeAsiaAustraliaIcon,
      },
    hasAssetDeployRole && {
      key: "publish",
      label: publishedDate ? "Republish" : "Publish",
      onClick: () => onPublishAsset(id),
      icon: Icons.edit,
      disabled: !publishToMicroSite,
    },
    hasAssetUpdateRole &&
      type !== "GENERATED" && {
        key: "edit",
        label: "Edit",
        onClick: () => onEditAsset(asset),
        icon: Icons.edit,
      },
    hasAssetDeleteRole && {
      key: "delete",
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  return (
    <TableRow
      key={id}
      className="cursor-pointer relative text-[#475569] font-sans h-10"
    >
      <TableCell className="w-[400px]">
        {ref ? (
          <Typography.Link href={ref} target="_blank" className="text-[18px]">
            {title}
          </Typography.Link>
        ) : (
          <div className="text-orange-600">
            <span className="text-[18px] mr-[6px]">{title}</span>
            <span>(the link is lost, update it manually)</span>
          </div>
        )}
      </TableCell>
      <TableCell
        className={clsx("w-[400px]", {
          "opacity-30": !microSiteTargetFolder,
        })}
      >
        {microSiteTargetFolder || MICROSITE_INPUT_PLACEHOLDER}
      </TableCell>
      <TableCell className="w-[150px]">
        <Checkbox
          disabled={!ref || !hasAssetApproveRole}
          checked={approved}
          onChange={(e) => onApproveAsset(id, e.target.checked)}
        />
      </TableCell>
      <TableCell className="w-[150px]">
        <Checkbox
          className="mr-[10px]"
          disabled={!ref || !approved || !hasAssetApprovePubRole}
          checked={publishToMicroSite}
          onChange={(e) => onApprovePublish(asset, e.target.checked)}
        />
      </TableCell>
      <TableCell className="w-[125px]">
        <span className="text-[10px]">{publishedDateValidated}</span>
      </TableCell>

      {menuItems.length ? (
        <TableCell
          className="relative w-[25px]"
          onClick={(e) => e.stopPropagation()}
        >
          <MenuDropdown
            items={menuItems}
            dropdownClassName="w-[190px]"
            align="end"
          >
            <Button variant={"ghostPrimary"} className="size-[40px] p-2">
              <Icons.MoreDots className="size-[24px]" />
            </Button>
          </MenuDropdown>
        </TableCell>
      ) : (
        <TableCell />
      )}

      {isDeleteModalOpen && (
        <DeleteWithFilesModal
          isModalOpen={isDeleteModalOpen}
          entity="Asset"
          title={title}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={(removeFiles) => onDeleteAsset(id, removeFiles)}
        />
      )}
    </TableRow>
  );
};

export default AssetListItem;
