import {
  executeCcVarApi,
  getDownstreamDependenciesApi,
} from "../../../api/cc-variables.api";
import getStepPhaseMetaInfo from "../../../pages/graph/utils/getStepPhaseMetaInfo";
import { Edge, MarkerType, Node } from "reactflow";
import removeLastUnderscorePart from "../../../pages/graph/utils/removeLastUnderscorePart";
import {
  INIT_NODE_DIMENSIONS,
  INIT_NODE_POSITION,
} from "../../../pages/graph/constants";
import getLayoutedElements from "../../../pages/graph/utils/getLayoutedElements";
import handleRequestError from "../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useEffect, useState } from "react";
import { NodeColor } from "../../../pages/graph/hooks/useGetNodes.hook";
import { CogIcon, MoveDownIcon } from "../../common/Icons";
import { MenuDropdownItem } from "../../common/MenuDropdown";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useNavigate, useParams } from "react-router-dom";
import useConfirm from "../../../hooks/useConfirm";
import { CampaignViewTypes } from "../../../types";
import { NodeProps } from "../../graph/GraphNodes";
import { selectEventMessages } from "../../../store/slices/computationMessages/slice";

type Props = {
  campaignId: string;
  stepId: string;
  ccItemKey: string;
};

const OVERRIDE_COLOR = "#967206";

const useGetDownstreamDependencies = ({
  campaignId,
  stepId,
  ccItemKey,
}: Props) => {
  const { companyId } = useParams();
  const initialNodeId = `${ccItemKey}_${stepId}`;
  const messageApi = useSelector(selectMessageApi);
  const eventMessages = useSelector(selectEventMessages);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [nodes, setNodes] = useState<Node<NodeProps>[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const { hasCampaignCcItemComputationRole } = useUserHasPermission({
    companyId,
  });
  // const updateNodesAndEdges = useUpdateNodesAndEdges();
  const confirm = useConfirm();
  const navigate = useNavigate();

  useEffect(() => {
    if (campaignId && stepId && ccItemKey) {
      getNodesAndEdges(campaignId, stepId, ccItemKey);
    }
  }, [campaignId, stepId, ccItemKey]);

  useEffect(() => {
    if (eventMessages.length) {
      const toUpdate = nodes.map((node) => {
        const messageToUpdate = eventMessages.find(
          ({ item }) => `${item?.id.key}_${item?.id.stepId}` === node.id,
        );

        if (messageToUpdate && messageToUpdate.item) {
          return {
            ...node,
            data: {
              ...node.data,
              state: messageToUpdate.item.state,
              hasOverride: !!messageToUpdate.item.resultOvr,
              label: messageToUpdate.item.id.key,
            },
          };
        } else {
          return node;
        }
      });

      setNodes(toUpdate);
    }
  }, [eventMessages]);

  const getNodesAndEdges = async (
    campaignId: string,
    stepId: string,
    key: string,
  ) => {
    try {
      // setIsTargetLoading(true);
      // setIsInputLoading(true);

      // await dispatch(loadDynamicItemsThunk()).unwrap();

      const { data } = await getDownstreamDependenciesApi({
        campaignId,
        stepId,
        key,
      });

      const { edges, nodes, metaInfo } = data;
      const stepPhaseMetaInfoData = getStepPhaseMetaInfo(metaInfo);
      // const itemStepMetaInfo = stepPhaseMetaInfoData[+stepId];
      // const path = itemStepMetaInfo
      //   ? `${itemStepMetaInfo.phaseName}/${itemStepMetaInfo.stepName}/`
      //   : "";
      console.log("nodes.ll", nodes.length);
      // const { targetCCItem, inputCCItem } = await getTargetWithInputCCItem({
      //   campaignId,
      //   stepId,
      //   key: path + key,
      // });
      const checkHasOverride = (nodeId: string): boolean => {
        const node = nodes.find((node) => node.id === nodeId);

        if (node) {
          return !!node.data.resultOvr;
        } else {
          return false;
        }
      };

      //TODO use in downstreap deps page, not it modal

      // const onSelectNode = (ccItemKey: string) => {
      //   const { updatedEdges, updatedNodes } = updateNodesAndEdges({
      //     selectedNodeId: ccItemKey,
      //   });
      //
      //   setEdges(updatedEdges);
      //   setNodes(updatedNodes);
      //   setSelectedNode(ccItemKey);
      // };

      const processedEdges: Edge[] = edges.map(
        ({ id, targetNodeId: target, sourceNodeId }) => {
          const hasOverride = checkHasOverride(sourceNodeId);

          return {
            id: id,
            source: target,
            target: sourceNodeId,
            type: "simplebezier",
            focusable: false,
            style: {
              // strokeWidth: isTargetOrInput ? 3 : 1,
              strokeWidth: 1,
              // stroke: NodeColor.DEFAULT,
              strokeDasharray: hasOverride ? "5 5" : undefined,
              stroke: hasOverride ? OVERRIDE_COLOR : NodeColor.TARGET,
              // stroke: isTarget
              //   ? NodeColor.TARGET
              //   : isInput
              //     ? NodeColor.INPUT
              //     : NodeColor.DEFAULT,
            },
            // markerEnd: {
            //   type: MarkerType.ArrowClosed,
            //   width: 20,
            //   height: 20,
            // },
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 15,
              height: 15,
              color: hasOverride ? OVERRIDE_COLOR : NodeColor.TARGET,
            },
            markerStart: "target",
            // markerStart: {color: NodeColor.TARGET, type: "default"},
            // zIndex: isTargetOrInput ? 1 : 0,
            // }
            //   ...getUpdatedEdgeStyles({
            //     nodeId: target,
            //     targetNodeId: `${targetCCItem.id.key}_${targetCCItem.id.stepId}`,
            //     inputNodeId: inputCCItem
            //       ? `${inputCCItem.id.key}_${inputCCItem.id.stepId}`
            //       : "",
            //   }),
          };
        },
      );

      const processedNodes: Node<NodeProps>[] = nodes.map(({ id, data }) => {
        // const nodeStepMetaInfo = stepPhaseMetaInfoData[data.id.stepId];
        // const path = nodeStepMetaInfo
        //   ? `${nodeStepMetaInfo.phaseName}/${nodeStepMetaInfo.stepName}/`
        //   : "";
        // const itemKeyWithFullPath = path + data.id.key;
        const { stepId, key, campaignId } = data.id;
        const { phaseId } = stepPhaseMetaInfoData[stepId];

        const nodeMenuItems = [
          {
            key: "goToKeyDefinition",
            label: "Go To Key Definition",
            disabled: false,
            icon: MoveDownIcon,
            onClick: () => {
              if (phaseId) {
                const path = `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}?view${CampaignViewTypes.GRID}#${key}`;
                navigate(path);
              }
            },
            className: "!text-vePrimary",
          },
          hasCampaignCcItemComputationRole && {
            key: "reRunDependencies",
            label: "Re-Run Dependencies",
            disabled: false,
            icon: CogIcon,
            onClick: () => {
              confirm({
                action: async () => {
                  try {
                    await executeCcVarApi({
                      campaignId,
                      key,
                      stepId,
                      updateDownstreamDependencies: false,
                    });

                    messageApi.success("The execution has been started");
                  } catch (e: any) {
                    const customError = handleRequestError(e);
                    messageApi.error(customError.message);
                    console.error(customError);
                  }
                },
                title: "Execute Item",
              });
            },
            className: "!text-vePrimary",
          },
        ].filter((v) => !!v) as MenuDropdownItem[];

        return {
          id,
          data: {
            label: removeLastUnderscorePart(id),
            touched: false,
            state: data.state,
            hasOverride: !!data.resultOvr,
            nodeMenuItems,
          },
          position: INIT_NODE_POSITION,
          parentId: stepId.toString(),
          draggable: false,
          selectable: false,
          zIndex: 2,
          // style: getUpdatedNodeStyles({
          //   nodeId: id,
          //   targetNodeId: `${targetCCItem.id.key}_${targetCCItem.id.stepId}`,
          //   inputNodeId: inputCCItem
          //     ? `${inputCCItem.id.key}_${inputCCItem.id.stepId}`
          //     : "",
          // }),
          style: {
            padding: 0,
            // boxShadow: id === initialNodeId ? "inset 0 0 0 2px #623CEA" : "none",
            borderColor:
              id === initialNodeId
                ? "#623CEA"
                : !!data.resultOvr
                  ? OVERRIDE_COLOR
                  : "#475569",
            borderWidth: id === initialNodeId ? 2 : 1,
          },
        };
      });

      const groupNodes: Node<any>[] = metaInfo.steps.map(({ name, id }) => {
        let label = name;
        const { phaseName } = stepPhaseMetaInfoData[id];

        if (phaseName) {
          label = `${phaseName} / ${name}`;
        } else {
          console.warn("Couldn't find phase");
        }

        return {
          id: id.toString(),
          type: "group",
          data: { label },
          position: INIT_NODE_POSITION,
          style: {
            ...INIT_NODE_DIMENSIONS,
            borderColor: "#475569",
          },
          draggable: false,
          selectable: false,
          zIndex: 2,
        };
      });

      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements([...groupNodes, ...processedNodes], processedEdges);

      setEdges(layoutedEdges);
      setNodes(layoutedNodes);
      // setTargetNode(targetCCItem);
      // setInputNode(inputCCItem);
      // setStepPhaseMetaInfo(getStepPhaseMetaInfo(metaInfo));
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsInitialized(true);
      // setIsTargetLoading(false);
      // setIsInputLoading(false);
    }
  };

  return {
    edges,
    nodes,
    isInitialized,
    initialNodeId,
    // isTargetLoading,
    // isInputLoading,
    // targetNode: useMemo(() => targetNode, [targetNode]),
    // inputNode: useMemo(() => inputNode, [inputNode]),
    // stepPhaseMetaInfo: useMemo(() => stepPhaseMetaInfo, [stepPhaseMetaInfo]),
    // onGoBack,
    // changeNodeProps,
    // setTargetNode,
    // setInputNode,
  };
};

export default useGetDownstreamDependencies;
