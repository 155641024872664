import { Spin, Tooltip } from "antd";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editCcItemKeyThunk,
  TCcVariable,
  useCcVariables,
} from "../../../../store/slices/ccVariablesSlice";
import { AppDispatch } from "../../../../store/store";
import { Badge } from "../../../common/ui/badge";
import { campaignStatusBadgeSettings } from "../../../../constants";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import ModifiedBy from "../../../common/ModifiedBy";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import TextArea from "antd/es/input/TextArea";
import useSaveOnBlurWithEscape from "../../../../hooks/useSaveOnBlurWithEscape";

type Props = {
  ccItem: TCcVariable;
};

const GridItemKey: FC<Props> = ({ ccItem }) => {
  const {
    id: { key, stepId, campaignId },
    type,
    modifiedBy,
    modifiedTime,
  } = ccItem;
  const { companyId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const ccVars = useCcVariables();
  const [tooltipTitle, setTooltipTitle] = useState("");
  const messageApi = useSelector(selectMessageApi);
  const { hasCampaignCcRenameRole } = useUserHasPermission({ companyId });
  const { value, setValue, isLoading, handleBlur, handleKeyDown } =
    useSaveOnBlurWithEscape({
      initialValue: key,
      onSave: () => onSave(),
      onCancel: () => setTooltipTitle(""),
      validate: () => !tooltipTitle,
    });

  const onSave = async () => {
    await dispatch(
      editCcItemKeyThunk({
        currentKey: key,
        newKey: value,
        stepId,
        campaignId,
      }),
    ).unwrap();

    messageApi.success("The key was successfully renamed");
  };

  const getValidateErrorMessage = (value: string): string => {
    const lowerCaseValue = value.toLowerCase();
    const validationWarning = "The changes will not be saved!";

    if (!value.length) {
      return `The value should not be empty. ${validationWarning}`;
    }

    if (!/^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(value)) {
      return `Invalid Javascript variable name. ${validationWarning}`;
    }

    for (let i = 0; i < ccVars.length; i++) {
      const existingCCItemKey = ccVars[i].id.key;

      if (existingCCItemKey === key) continue;

      if (existingCCItemKey.toLowerCase() === lowerCaseValue) {
        return `The key already exists in this grid. ${validationWarning}`;
      }
    }

    return "";
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const validationErrorMessage = getValidateErrorMessage(value);

    setValue(value);
    setTooltipTitle(validationErrorMessage);
  };

  return (
    <div id={key} className="p-[3px]">
      {hasCampaignCcRenameRole ? (
        <div>
          <Spin size="small" spinning={isLoading}>
            <Tooltip title={tooltipTitle} color="red" open={!!tooltipTitle}>
              <TextArea
                autoSize
                value={value || undefined}
                placeholder="not defined"
                onBlur={handleBlur}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                disabled={isLoading}
                className="hover:border-indigo-600 border-transparent"
              />
            </Tooltip>
          </Spin>
        </div>
      ) : (
        <div className="ml-[8px]">{key}</div>
      )}

      <div className="ml-[8px] mb-4 mt-1">
        <Badge
          className={
            campaignStatusBadgeSettings[type]?.className ||
            "bg-orange-100 text-orange-500"
          }
          variant="table"
        >
          {type}
        </Badge>
      </div>

      <ModifiedBy modifiedBy={modifiedBy} modifiedTime={modifiedTime} />
    </div>
  );
};

export default GridItemKey;
