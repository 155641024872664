import React, { FC, useState } from "react";
import { GetProp, Spin, Upload, UploadProps } from "antd";
import { TCompany } from "../../store/slices/companiesSlice";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getMessageApi } from "../../store/slices/appSlice";
import handleRequestError from "../../utils/handleRequestError";
import { uploadCompanyLogoApi } from "../../api/companies.api";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { Link, useLocation } from "react-router-dom";
import { ArrowTopRightOnSquareIcon } from "../common/Icons";
import { ALLOWED_LOGO_FILE_TYPES } from "../../constants";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

type Props = {
  companyData: TCompany;
  activeCampaigns: number;
  activeUsers: number;
};

const CompanyDetailsInfo: FC<Props> = ({
  companyData,
  activeUsers,
  activeCampaigns,
}) => {
  const { name, website, logo, createdTime, id } = companyData;
  let foundingDate = null;
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(logo);
  const messageApi = getMessageApi();
  const { hasCompanyUpdateRole } = useUserHasPermission({ companyId: id });

  if (createdTime) {
    const dateObject = new Date(createdTime);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };
    // @ts-ignore
    foundingDate = dateObject.toLocaleDateString("en-US", options);
  }

  const beforeUpload = async (file: FileType) => {
    try {
      if (!ALLOWED_LOGO_FILE_TYPES.includes(file.type)) {
        messageApi.error(
          `${file.name} is not supported. Allowed formats: PNG, JPEG, SVG, WebP.`,
        );
        return false;
      }

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        messageApi.error("The image size must be less than 2 MB.");
        return false;
      }

      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await uploadCompanyLogoApi({ companyId: id, formData });

      setImageUrl(data.logo);

      messageApi.success("The logo was successfully uploaded");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
    }
    return false;
  };

  const currentPath = useLocation().pathname;

  return (
    <div className="flex gap-[24px]">
      <div className="w-[230px] h-[230px] company-logo">
        <Upload
          name="files"
          listType="picture-card"
          accept={ALLOWED_LOGO_FILE_TYPES.join(",")}
          showUploadList={false}
          beforeUpload={beforeUpload}
          disabled={!hasCompanyUpdateRole}
        >
          {imageUrl ? (
            <Spin spinning={loading}>
              <img
                src={imageUrl}
                alt="avatar"
                className="w-[230px] h-[230px] object-cover rounded-[8px]"
              />
            </Spin>
          ) : (
            <button
              className="w-[230px] h-[230px] bg-none border-0"
              type="button"
            >
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Upload the logo</div>
            </button>
          )}
        </Upload>
      </div>
      <div className="flex flex-grow flex-col pt-[24px]">
        <h1 className="font-semibold text-[24px] pb-[24px] text-[#0F172A]">
          {name}
        </h1>
        <div className="h-[1px] bg-[#E2E8F0]" />
        <div className="flex justify-between pt-[24px] pb-[50px]">
          <div className="flex flex-col w-[30%] gap-[4px]">
            <span className="font-bold text-[14px] text-[#475569]">
              Website URL
            </span>

            {website ? (
              <a
                href={
                  website.startsWith("http") ? website : `http://${website}`
                }
                target="_blank"
                rel="noopener noreferrer"
                className="font-normal text-[16px] text-vePrimary break-words"
              >
                {website}
              </a>
            ) : (
              <span>Website not designated.</span>
            )}
          </div>

          <div className="flex flex-col w-[20%] gap-[4px]">
            <span className="font-bold text-[14px] text-[#475569]">
              Company Created
            </span>
            <span className="font-normal text-[16px] text-[#0F172A]">
              {foundingDate}
            </span>
          </div>

          <Link
            to={`/campaigns?companyId=${companyData.id}&campaignType=CAMPAIGN&page=1&pageSize=50&backUrl=${currentPath}`}
            className="flex flex-col w-[18%] gap-[4px] group"
          >
            <span className="font-bold text-[14px] text-[#475569] group-hover:text-vePrimary flex items-center gap-[4px]">
              Active Campaigns{" "}
              <ArrowTopRightOnSquareIcon className="w-[16px] h-[16px] group-hover:text-vePrimary" />
            </span>
            <span className="font-normal text-[16px] group-hover:text-vePrimary text-black">
              {activeCampaigns}
            </span>
          </Link>

          <div className="flex flex-col w-[18%] gap-[4px]">
            <span className="font-bold text-[14px] text-[#475569]">
              Number of Users
            </span>
            <span className="font-normal text-[16px]">{activeUsers}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsInfo;
