import { FC, useEffect } from "react";
import CampaignDocView from "src/components/CampaignDocView";
import CampaignGrid from "../../../../components/CampaignGrid/Grid/CampaignGrid";
import CampaignAssets from "../../../../components/campaignAssets/CampaignAssets";
import { CampaignViewTypes } from "../../../../types";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { useCampaignParams } from "../CampaignEditorPage/useCampaignParams";
import { cn } from "src/utils";
import {
  selectCurrentStep,
  selectStepsList,
  setCurrentStep,
} from "src/store/slices/stepsSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store/store";

type Props = {};

const StepData: FC<Props> = () => {
  const { companyId, stepId, view, campaignId, phaseId } = useCampaignParams();
  const { hasAssetListRole } = useUserHasPermission({ companyId });
  const currentStep = useSelector(selectCurrentStep);
  const stepsList = useSelector(selectStepsList);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (view === CampaignViewTypes.DOC && currentStep?.hiddenDocView) {
      const closestStepWithShowDocView = stepsList.find(
        (step) => !step.hiddenDocView,
      );

      if (!closestStepWithShowDocView) return;

      dispatch(setCurrentStep(closestStepWithShowDocView)); // this is needed to update the current step in the store, that's BAD: we should be able to use just URL params

      navigate(
        `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${closestStepWithShowDocView?.id}?view=${CampaignViewTypes.DOC}&documentTemplateId=${closestStepWithShowDocView?.documentTemplateId}`,
        {
          replace: true,
        },
      );
    }
  }, [view, currentStep?.hiddenDocView]);

  if (!stepId) return null;

  return (
    <div className="flex flex-col gap-4">
      <div className={cn(view === CampaignViewTypes.GRID ? "block" : "hidden")}>
        <CampaignGrid />
      </div>

      <div className={cn(view === CampaignViewTypes.DOC ? "block" : "hidden")}>
        <CampaignDocView />
      </div>

      {hasAssetListRole && (
        <>
          <div className="h-[1px] bg-[#E2E8F0]" />
          <CampaignAssets />
        </>
      )}
    </div>
  );
};

export default StepData;
