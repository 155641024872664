import { FloatButton } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import UserDetailsTabs from "../../components/users/UserDetailsTabs";
import UserDetailsMainInfo from "../../components/users/UserDetailsMainInfo";

const UserDetailsPage = () => {
  const { hasUserUpdateRole } = useUserHasPermission({});

  return (
    <div>
      <UserDetailsMainInfo
        hasUserUpdateRole={hasUserUpdateRole}
        goBackDefaultPath="/users"
      />

      <div className="h-[1px] bg-[#E2E8F0]" />

      <UserDetailsTabs />

      <Outlet />

      <FloatButton.BackTop />
    </div>
  );
};

export default UserDetailsPage;
