import React from "react";
import { TableCell, TableRow } from "../../common/ui/table";
import { TCompanyUser } from "../../../globalTypes";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import UserMenuTableCell from "../../users/UserMenuTableCell";
import clsx from "clsx";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  deleteCompanyUserApi,
  disableCompanyUserApi,
  enableCompanyUserApi,
} from "../../../api/user.api";

type PropsType = {
  user: TCompanyUser;
  loadUsers: () => Promise<void>;
};
const CompanyUsersListItem: React.FC<PropsType> = ({ user, loadUsers }) => {
  const {
    securityGroups,
    userName,
    userId,
    disabled,
    position,
    email,
    lastLoginTime,
    companyId,
  } = user;
  const { hasUserReadRole } = useUserHasPermission({ companyId });
  const navigate = useNavigate();

  const onNavigateToUserDetails = () => {
    navigate(`/companies/${companyId}/user/${userId}`);
  };

  const deleteUser = async ({
    userId,
    companyId,
  }: {
    userId: number;
    companyId: number;
  }) => {
    await deleteCompanyUserApi({ userId, companyId });
  };

  const enableUser = async () => {
    await enableCompanyUserApi({ userId, companyId });
  };

  const disableUser = async () => {
    await disableCompanyUserApi({ userId, companyId });
  };

  return (
    <>
      <TableRow
        onClick={hasUserReadRole ? onNavigateToUserDetails : undefined}
        className={clsx("cursor-pointer text-[#475569] font-sans h-[48px]", {
          "opacity-30": disabled,
        })}
      >
        <TableCell className="font-medium text-[#0F172A]">{userName}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{position}</TableCell>
        <TableCell>{securityGroups}</TableCell>
        <TableCell>
          {lastLoginTime
            ? format(lastLoginTime, "MMMM d, yyyy h:mm a zzz")
            : "-"}
        </TableCell>
        <UserMenuTableCell
          updateUsers={loadUsers}
          userName={userName}
          disabled={disabled}
          onNavigateToUserDetails={onNavigateToUserDetails}
          deleteUser={() => deleteUser({ userId, companyId })}
          enableUser={enableUser}
          disableUser={disableUser}
        />
      </TableRow>
    </>
  );
};

export default CompanyUsersListItem;
