import React from 'react';
import CampaignLabelsHeader from '../../components/campaignLabels/CampaignLabelsHeader';
import CampaignLabelsList from '../../components/campaignLabels/CampaignLabelsList';

const CampaignLabelsPage = () => {
  return (
    <div className="flex flex-col gap-[24px]">
      <CampaignLabelsHeader />
      <CampaignLabelsList />
    </div>
  );
};

export default CampaignLabelsPage;