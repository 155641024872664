import { Drawer } from "antd";
import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCcVarsThunk,
  TCcVariable,
} from "src/store/slices/ccVariablesSlice";
import { selectCurrentPhase } from "src/store/slices/phasesSlice";
import { selectCurrentStep } from "src/store/slices/stepsSlice";
import { AppDispatch } from "src/store/store";
import { KeyPanelMode } from "src/types";
import EditPrompt from "../../graph/EditPrompt/EditPrompt";
import { NodeType } from "../../graph/EditorPane";
import PaneDynamicForm from "../../graph/PaneDynamicForm/PaneDynamicForm";
import PaneExecuteButton from "../../graph/PaneExecuteButton";
import PaneInfoSection from "../../graph/PaneInfoSection";
import PaneResultOverride from "../../graph/PaneResultOverride";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import { ActiveTab, NodeNavItem } from "../types";
import PaneResult from "../../graph/PaneResult";

export type TVarEditPanelProps = {
  variableData?: TCcVariable | undefined;
  onClose: () => void;
  onSaved: () => void;
  mode?: KeyPanelMode;
  ccVariables: TCcVariable[];
  isOpen?: boolean;
};

export const VarKeyPanel: FC<TVarEditPanelProps> = ({
  variableData,
  onClose,
  onSaved,
  isOpen,
}) => {
  const { companyId, campaignId, stepId } = useParams();
  const [item, setItem] = useState<TCcVariable | null>(variableData || null);
  const currentStep = useSelector(selectCurrentStep);
  const currentPhase = useSelector(selectCurrentPhase);
  const [activeComponent, setActiveComponent] = useState(ActiveTab.Result);
  const { hasCampaignCcUpdateStateRole, hasCampaignEnvItemUpdateRole } =
    useUserHasPermission({ companyId });
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    setActiveComponent(item?.resultOvr ? ActiveTab.Override : ActiveTab.Result);
  }, [item]);

  if (!item) {
    return (
      <div className="text-red-600">Error: Unable to get computation item</div>
    );
  }

  const allowedTypesForModel =
    item.type === "prompt" ||
    item.type === "asset" ||
    item.type === "web_scraper" ||
    item.type === "function";

  const isString = item.type === "string";

  const onAfterResultUpdate = async () => {
    await dispatch(loadCcVarsThunk({ campaignId, stepId })).unwrap();

    onSaved();
  };

  const handleOverrideSaved = () => {
    onSaved();
  };

  const navDataList = [
    !isString && {
      label: ActiveTab.Override,
      active: activeComponent === ActiveTab.Override,
      renderComponent: () => (
        <PaneResultOverride
          ccItem={item}
          setItem={setItem}
          onOverrideSave={handleOverrideSaved}
        />
      ),
    },
    {
      label: ActiveTab.Result,
      active: activeComponent === ActiveTab.Result,
      renderComponent: () => (
        <PaneResult
          ccItem={item}
          setActiveOverrideTab={() => setActiveComponent(ActiveTab.Override)}
          onAfterResultUpdate={onAfterResultUpdate}
        />
      ),
    },
    hasCampaignCcUpdateStateRole &&
      allowedTypesForModel && {
        label: ActiveTab.Model,
        active: activeComponent === ActiveTab.Model,
        renderComponent: () => (
          <PaneDynamicForm
            nodeType={NodeType.TARGET}
            ccItem={item!}
            ccItemPhaseId={currentPhase?.id!}
            setItem={setItem}
          />
        ),
      },
    hasCampaignEnvItemUpdateRole &&
      item.type === "prompt" && {
        label: ActiveTab.Prompt,
        active: activeComponent === ActiveTab.Prompt,
        renderComponent: () => <EditPrompt ccItem={item!} />,
      },
  ].filter(Boolean) as NodeNavItem[];

  return (
    <Drawer
      placement={"bottom"}
      closable={false}
      open={isOpen}
      onClose={onClose}
      height={340}
    >
      <div className="flex justify-between">
        <PaneInfoSection
          stepName={currentStep?.name!}
          phaseName={currentPhase?.name!}
          nodeType={"EDIT"}
          phaseId={currentPhase?.id!}
          type={item.type}
          state={item.state}
          stepId={item.id.stepId}
          ccItemKey={item.id.key}
        />
        <PaneExecuteButton
          campaignId={item.id.campaignId}
          ccItemKey={item.id.key}
          stepId={item.id.stepId}
        />
      </div>
      <div>
        {navDataList.map(({ label, active }) => (
          <div
            key={label}
            className={clsx(
              "cursor-pointer capitalize inline-flex items-center font-sans font-semibold transition-colors text-[#334155] text-[14px] h-[34px] mr-[24px] border-y-transparent hover:text-vePrimary border-y-[3px]",
              {
                "border-b-vePrimary": active,
              },
            )}
            onClick={() => setActiveComponent(label)}
          >
            <span className={active ? "text-vePrimary" : ""}>{label}</span>
          </div>
        ))}
        <div className="h-[1px] bg-[#E2E8F0]" />
      </div>
      <div className="mt-2">
        {navDataList.map(({ label, active, renderComponent }) => {
          if (active) {
            return (
              <div key={label} className="flex-grow overflow-y-auto h-[220px]">
                {active && renderComponent && renderComponent()}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </Drawer>
  );
};
