import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Edge, Node } from "reactflow";
import { TCcVariable } from "src/store/slices/ccVariablesSlice";
import { executeCcVarApi, getCCItemApi } from "../../../api/cc-variables.api";
import { selectMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import getTargetWithInputCCItem from "../utils/getTargetWithInputCCItem";
import useUpdateNodesAndEdges from "./useUpdateNodesAndEdges.hook";
import { CampaignViewTypes } from '../../../types';

const useNodeMenuActions = ({
  setNodes,
  setEdges,
  setTargetNode,
  setInputNode,
  targetNode,
  inputNode,
  setIsInputLoading,
  setIsTargetLoading,
}: {
  setNodes: Dispatch<SetStateAction<Node<any, string | undefined>[]>>;
  setEdges: Dispatch<SetStateAction<Edge<any>[]>>;
  setTargetNode: Dispatch<SetStateAction<TCcVariable | null>>;
  setInputNode: Dispatch<SetStateAction<TCcVariable | null>>;
  setIsTargetLoading: Dispatch<SetStateAction<boolean>>;
  setIsInputLoading: Dispatch<SetStateAction<boolean>>;
  targetNode: TCcVariable | null;
  inputNode: TCcVariable | null;
}) => {
  const { companyId, campaignId, stepId, key } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const updateNodesAndEdges = useUpdateNodesAndEdges();
  const targetNodeStateRef = useRef(targetNode);
  const inputNodeStateRef = useRef(inputNode);
  const navigate = useNavigate();

  useEffect(() => {
    targetNodeStateRef.current = targetNode;
    inputNodeStateRef.current = inputNode;
  }, [targetNode, inputNode]);

  const reRun = useCallback(
    async ({
      campaignId,
      stepId,
      ccItemKey,
    }: {
      campaignId: number;
      stepId: number;
      ccItemKey: string;
    }) => {
      try {
        await executeCcVarApi({
          campaignId,
          key: ccItemKey,
          stepId,
          updateDownstreamDependencies: false,
        });

        messageApi.success("The execution has been started");
      } catch (e: any) {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);
      }
    },
    [companyId, campaignId],
  );

  const goToKeyDefinition = useCallback(
    ({ phaseId, stepId, ccItemKey }: { phaseId: number; stepId: number; ccItemKey: string }) => {
      const path = `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}?view${CampaignViewTypes.GRID}#${ccItemKey}`;
      navigate(path);
    },
    [companyId, campaignId],
  );

  const setTargetWithInput = useCallback(async (ccItemKey: string) => {
    try {
      setIsTargetLoading(true);
      setIsInputLoading(true);

      if (campaignId && stepId && key) {
        const { targetCCItem, inputCCItem } = await getTargetWithInputCCItem({
          campaignId,
          stepId,
          key: ccItemKey,
        });
        const targetNodeId = `${targetCCItem.id.key}_${targetCCItem.id.stepId}`;
        const inputNodeId = inputCCItem
          ? `${inputCCItem.id.key}_${inputCCItem.id.stepId}`
          : "";
        const { updatedEdges, updatedNodes } = updateNodesAndEdges({
          targetNodeId,
          inputNodeId,
        });

        setEdges(updatedEdges);
        setNodes(updatedNodes);
        setTargetNode(targetCCItem);
        setInputNode(inputCCItem);
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsTargetLoading(false);
      setIsInputLoading(false);
    }
  }, []);

  const setInput = useCallback(async (ccItemKey: string) => {
    try {
      setIsInputLoading(true);

      if (campaignId && stepId && key) {
        const { data: inputCCItem } = await getCCItemApi({
          campaignId,
          stepId,
          key: ccItemKey,
        });
        const targetNodeFromRef = targetNodeStateRef.current;
        const targetNodeId = targetNodeFromRef
          ? `${targetNodeFromRef.id.key}_${targetNodeFromRef.id.stepId}`
          : "";
        const inputNodeId = `${inputCCItem.id.key}_${inputCCItem.id.stepId}`;
        const { updatedEdges, updatedNodes } = updateNodesAndEdges({
          targetNodeId,
          inputNodeId,
        });

        setEdges(updatedEdges);
        setNodes(updatedNodes);
        setInputNode(inputCCItem);
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsInputLoading(false);
    }
  }, []);

  const setTarget = useCallback(async (ccItemKey: string) => {
    try {
      setIsTargetLoading(true);

      if (campaignId && stepId && key) {
        const { data: targetCCItem } = await getCCItemApi({
          campaignId,
          stepId,
          key: ccItemKey,
        });
        const inputNodeFromRef = inputNodeStateRef.current;
        const targetNodeId = `${targetCCItem.id.key}_${targetCCItem.id.stepId}`;
        const inputNodeId = inputNodeFromRef
          ? `${inputNodeFromRef.id.key}_${inputNodeFromRef.id.stepId}`
          : "";
        const { updatedEdges, updatedNodes } = updateNodesAndEdges({
          targetNodeId,
          inputNodeId,
        });

        setEdges(updatedEdges);
        setNodes(updatedNodes);
        setTargetNode(targetCCItem);
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsTargetLoading(false);
    }
  }, []);

  return {
    goToKeyDefinition,
    setTargetWithInput,
    setInput,
    setTarget,
    reRun,
  };
};

export default useNodeMenuActions;
