import { Flex, Form, Input, InputRef, Modal, Select } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCcVarApi,
  executeCcVarApi,
  updateCcVarApi,
  validateCcVarApi,
} from "src/api/cc-variables.api";
import { CC_VAR_SEQUENCE_STEP } from "src/utils/campaigns.constant";
import { getLastSequence } from "src/utils/cm.utils";
import { required, validateKey } from "src/utils/validations";
import useCCVarData from "../../../hooks/useCCVarData";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  loadCcVarsThunk,
  TCcVariable,
  TCcVarsFormValues,
  useCcVariables,
} from "../../../store/slices/ccVariablesSlice";
import { AppDispatch } from "../../../store/store";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import TextAreaWithAltEnter from "../../common/TextAreaWithAltEnter";
import { Button } from "../../common/ui/button";
import DynamicCCForm from "../../dynamicForms/DynamicCCForm";
import {
  TEditModeProps,
  TitleModal,
} from "../../CampaignGrid/Grid/CampaignGrid";
import { getCurrentCompany } from "../../../store/slices/companiesSlice";
import { TStorageFileItem } from "../../../globalTypes";
import { getCompanyPublicFilesListApi } from "../../../api/company-storage.api";
import SelectStorageItems from "../../common/SelectStorageItems/SelectStorageItems";
import saveCCItemWithRepublish from "../../../utils/saveCCItemWithRepublish";
import { resetComputationMessages } from "../../../store/slices/computationMessages/slice";
import { useParams } from "react-router-dom";
import customizeFormLabels from "../../../utils/customizeFormLabels";

type Props = {
  isOpened: boolean;
  setIsOpened: (val: boolean) => void;
  ccVarData: TEditModeProps | null;
  varKeysLowerCase: Array<string>;
  ccVariables: TCcVariable[];
  createKey: string | undefined;
};

const AddCcVarForm: FC<Props> = ({
  isOpened,
  setIsOpened,
  ccVarData,
  varKeysLowerCase,
  ccVariables,
  createKey,
}) => {
  const { ccVarTypes, form, setDynamicData, type, resultType, dynamicItems } =
    useCCVarData(ccVarData);
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const { campaignId, phaseId, stepId } = useParams();
  const ccVars = useCcVariables();
  const inputTagRef = useRef<InputRef>(null);
  const [isFetching, setIsFetching] = useState(false);
  const gridItemSequence = getLastSequence(ccVariables, CC_VAR_SEQUENCE_STEP);
  const currentCompany = getCurrentCompany();
  const contextPathPrefix = `/${currentCompany?.name}/`;

  useSubmitFormOnEnter(() => submitForm(), { condition: !isFetching });

  useEffect(() => {
    if (createKey) {
      form.setFieldValue("key", createKey);
    }
  }, [createKey]);

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus({
        cursor: "end",
      });
    }
  }, []);

  if (!campaignId || !phaseId || !stepId) {
    messageApi.error("Failed to load the required data.");
    return null;
  }

  const handleCancel = () => {
    form.resetFields();

    setDynamicData(null);
    setIsOpened(false);
  };

  const onSave = async ({
    values,
    rebuild = false,
  }: {
    rebuild?: boolean;
    values: TCcVarsFormValues;
  }) => {
    try {
      setIsFetching(true);

      if (ccVarData && ccVarData.actionType !== TitleModal.CLONE) {
        await updateCcVarApi({
          ccItem: ccVarData.variableData,
          values,
          rebuild,
        });
      } else {
        await createCcVarApi({
          campaignId,
          values,
          stepId,
          seq: gridItemSequence,
        });
      }

      await dispatch(loadCcVarsThunk({campaignId, stepId})).unwrap();

      setIsOpened(false);
      setDynamicData(null);
      form.resetFields();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const insertToFormField = (data: string) => {
    form.setFieldValue("result", data);
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TCcVarsFormValues) => {
      const postProcessingParams = values.postProcessingParams;
      if (postProcessingParams) {
        values.postProcessingParams = JSON.stringify(postProcessingParams);
      }

      await saveCCItemWithRepublish({
        values,
        saveCCItem: onSave,
        ccItem: ccVarData?.variableData,
      });
    },
  });

  const onExecute = async (key: string) => {
    try {
      const { data: isExecuting } = await validateCcVarApi({
        campaignId,
      });

      if (!isExecuting) {
        await executeCcVarApi({
          campaignId,
          key,
          stepId,
        });

        dispatch(resetComputationMessages());

        messageApi.success("Execution started successfully");
      } else {
        messageApi.error(
          "There are some active computation elements in progress, please wait before execute",
        );
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const submitFormAndCompute = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TCcVarsFormValues) => {
      const postProcessingParams = values.postProcessingParams;
      if (postProcessingParams) {
        values.postProcessingParams = JSON.stringify(postProcessingParams);
      }

      await saveCCItemWithRepublish({
        values,
        saveCCItem: onSave,
        ccItem: ccVarData?.variableData,
      });
      await onExecute(values.key);
    },
  });

  const getFolderFiles = async (path?: string): Promise<TStorageFileItem[]> => {
    const { data } = await getCompanyPublicFilesListApi({
      path: path || contextPathPrefix,
    });
    return data;
  };

  return (
    <Modal
      title={ccVarData?.actionType || TitleModal.ADD}
      open={isOpened}
      onCancel={handleCancel}
      destroyOnClose={true}
      centered={true}
      width={"80vw"}
      footer={() => {
        return (
          <div className="flex justify-end items-center gap-[12px]">
            <div className="flex gap-[12px]">
              <Button
                onClick={handleCancel}
                variant="primaryOutline"
                className="rounded-full self-start"
                size="sm"
              >
                Cancel
              </Button>
              <Button
                onClick={submitForm}
                className="rounded-full self-start px-5"
                loading={isFetching}
                disabled={isFetching}
                size="sm"
              >
                Save
              </Button>
              <Button
                onClick={submitFormAndCompute}
                className="rounded-full self-start px-5"
                loading={isFetching}
                disabled={isFetching}
                size="sm"
              >
                Save and Compute
              </Button>
            </div>
          </div>
        );
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="cc_variable_form"
        style={{ height: "80vh", overflowY: "scroll" }}
        requiredMark={customizeFormLabels}
      >
        <Flex gap="middle" align="center">
          <Form.Item name="type" label="Type" rules={[required(true)]}>
            <Select
              placeholder="Select type"
              style={{ width: 140 }}
              options={ccVarTypes.map((type) => ({
                value: type,
                label: type,
              }))}
              disabled={!!ccVarData}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            hidden={!type}
            name="key"
            label="Key"
            tooltip="Enter a valid JavaScript identifier. It should start with a letter, underscore (_), or dollar sign ($), and can include letters, numbers, underscores, and dollar signs."
            rules={[
              {
                required: true,
                validator: validateKey(ccVarData, varKeysLowerCase),
              },
            ]}
          >
            <Input ref={inputTagRef} placeholder="Enter unique key name" />
          </Form.Item>
        </Flex>
        <Form.Item hidden={!type} name="result" label="Result">
          <TextAreaWithAltEnter
            placeholder="Enter result"
            currentForm={form}
            fieldName="result"
            allowClear
            rows={5}
            disabled={resultType === "read_only"}
          />
        </Form.Item>
        {type === "string" && (
          <div className="flex flex-col gap-[8px] mt-[16px]">
            <div className="font-sans font-bold text-[#475569] text-[14px]">
              Select file from storage:
            </div>
            <SelectStorageItems
              selectType="FILE"
              pathPrefix={contextPathPrefix}
              getFolderFiles={getFolderFiles}
              onSelect={insertToFormField}
            />
          </div>
        )}
        <DynamicCCForm
          hidden={!type}
          form={form}
          items={dynamicItems}
          ccVarData={ccVarData}
          gridItemSequence={gridItemSequence}
          campaignId={+campaignId}
          stepId={+stepId}
          phaseId={+phaseId}
          stepCCVars={ccVars}
        />
      </Form>
    </Modal>
  );
};

export default AddCcVarForm;
