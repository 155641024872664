import { FC } from "react";
import { TCampaign } from "../../../store/slices/campaignsSlice";
import { Icons } from "../../common/Icons";
import { Button } from "../../common/ui/button";
import EditCampaignName from "../EditCampaignName/EditCampaignName";
import Settings from "../Settings/Settings";
import { useGoBack } from "../../../hooks/useGoBack";
import EventsLog from "../../EventsLog/EventsLog";

type Props = {
  currentCampaign: TCampaign;
  companyId: number;
};

const CampaignHeader: FC<Props> = ({ currentCampaign, companyId }) => {
  const goBack = useGoBack("/campaigns");

  const onGoBack = () => {
    goBack();
  };

  return (
    <div className="flex gap-[20px] justify-between">
      <div className="flex gap-[16px] items-center flex-grow">
        <Button
          variant={"ghost"}
          icon={Icons.back}
          onClick={onGoBack}
          className="p-[12px] h-auto"
        />

        <EditCampaignName
          currentCampaign={currentCampaign}
          companyId={companyId}
        />
      </div>
      <div className="flex items-center justify-between gap-[6px]">
        <EventsLog />
        <Settings currentCampaign={currentCampaign} companyId={companyId} />
      </div>
    </div>
  );
};

export default CampaignHeader;
