import { Link } from "react-router-dom";

const footerList = [
  {
    label: "Privacy",
    path: "https://www.velocityengine.co/privacy",
  },
];

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="w-full h-[106px]">
      <div className="w-full h-[106px]">
        <div className="w-full h-[106px] bg-white border-t border-slate-200 flex justify-between items-center px-[48px]">
          <div className="h-8 justify-start items-center gap-2 inline-flex">
            <div className="w-8 h-8">
              <img src={"/icon.svg"} alt="logo" className="h-[32px]" />
            </div>
            <div className="text-[#64748B] text-sm font-medium font-sans">
              Copyright © {currentYear} Velocity Engine Inc.
            </div>
          </div>
          <div className="h-2.5 justify-end items-center gap-[33px] inline-flex">
            {footerList.map((item) => {
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className="text-[#64748B] text-sm font-medium font-sans cursor-pointer"
                  target="_blank"
                >
                  {item.label}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="w-full h-1 bg-gradient-to-tr from-purple-700 to-rose-600" />
      </div>
    </div>
  );
};

export default Footer;
