import React, { FC } from "react";
import Graph from "../../graph/Graph";
import useGetDownstreamDependencies from "../hooks/useGetDownstreamDependencies";
import { Modal, Spin } from "antd";

type Props = {
  campaignId: string;
  stepId: string;
  ccItemKey: string;
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const DownstreamDependenciesModal: FC<Props> = ({
  campaignId,
  ccItemKey,
  stepId,
  isOpened,
  setIsOpened,
}: Props) => {
  const { nodes, initialNodeId, isInitialized, edges } =
    useGetDownstreamDependencies({ campaignId, ccItemKey, stepId });
  const graphHeight = window.innerHeight - 200;

  return (
    <Modal
      title="Downstream Dependencies"
      open={isOpened}
      onCancel={() => setIsOpened(false)}
      width={1150}
      centered
      footer={null}
      // getContainer={false}
    >
      <Spin spinning={!isInitialized} tip="Loading the data...">
        <div className="w-full border-2" style={{ height: `${graphHeight}px` }}>
          <Graph
            nodes={nodes}
            edges={edges}
            initialNodeId={initialNodeId}
            isInitialized={isInitialized}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default DownstreamDependenciesModal;
