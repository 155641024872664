import { useCallback, useEffect, useRef, useState } from "react";
import { selectMessageApi } from "../store/slices/appSlice";
import { useSelector } from "react-redux";
import handleRequestError from "../utils/handleRequestError";

type UseSaveOnBlurWithEscapeProps = {
  initialValue: string;
  onCancel?: (value: string) => void;
  onSave?: (value: string) => Promise<void>;
  validate?: (value: string) => boolean;
};

const useSaveOnBlurWithEscape = ({
  initialValue,
  onCancel,
  onSave,
  validate,
}: UseSaveOnBlurWithEscapeProps) => {
  const [value, setValue] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const skipBlurRef = useRef(false);
  const messageApi = useSelector(selectMessageApi);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const resetValue = useCallback(() => {
    setValue(initialValue);
    onCancel?.(initialValue);
  }, [initialValue, onCancel]);

  const handleBlur = useCallback(async () => {
    if (skipBlurRef.current) {
      skipBlurRef.current = false;
      return;
    }

    if (validate && !validate(value)) {
      resetValue();
      return;
    }

    if (value !== initialValue) {
      try {
        setIsLoading(true);
        await onSave?.(value);
      } catch (e: any) {
        const customError = handleRequestError(e);

        messageApi.error(customError.message);
        console.error(customError);
        resetValue();
      } finally {
        setIsLoading(false);
      }
    }
  }, [value, initialValue, validate, onSave, resetValue]);

  const handleEscape = useCallback(() => {
    skipBlurRef.current = true;
    resetValue();
  }, [resetValue]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Escape") {
        handleEscape();
        (e.target as HTMLElement).blur();
      }
    },
    [handleEscape],
  );

  return {
    value,
    setValue,
    isLoading,
    handleBlur,
    handleKeyDown,
  };
};

export default useSaveOnBlurWithEscape;
