import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { TNavigationItem } from "../../globalTypes";

type Props = {
  items: TNavigationItem[];
  replace?: boolean;
};

const Navigation: FC<Props> = ({ items, replace = false }) => {
  return (
    <nav className="flex gap-6 border-b border-[#E2E8F0]">
      {items.map(({ path, title }) => {
        return (
          <NavLink
            replace={replace}
            to={path}
            key={path}
            className={({ isActive }) => {
              return clsx(
                "text-[14px] cursor-pointer capitalize inline-flex items-center font-sans font-semibold transition-colors border-y-[3px] border-y-transparent pb-1",
                {
                  "text-vePrimary border-b-vePrimary hover:text-vePrimary":
                    isActive,
                  "text-[#334155] hover:text-vePrimary": !isActive,
                },
              );
            }}
          >
            {title}
          </NavLink>
        );
      })}
    </nav>
  );
};
export default Navigation;
