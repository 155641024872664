import { getMicrositeApi } from "../../../api/microsite.api";
import handleRequestError from "../../../utils/handleRequestError";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  selectCompanyStorageMicroSiteData,
  setCompanyMicroSiteData,
} from "../../../store/slices/companyStorageSlice";
import { AppDispatch } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";

const useGetMicroSiteData = () => {
  const dispatch: AppDispatch = useDispatch();
  const { companyId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [initialized, setInitialized] = useState(false);
  const microSite = useSelector(selectCompanyStorageMicroSiteData);
  const microSiteState = microSite?.state;

  useEffect(() => {
    if (companyId) {
      getMicroSite(companyId);
    }

    return () => {
      dispatch(setCompanyMicroSiteData(null));
    };
  }, []);

  useEffect(() => {
    if (microSiteState === "PENDING_VALIDATION" && companyId) {
      const interval = setInterval(() => {
        getMicroSite(companyId);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [microSiteState]);

  const getMicroSite = async (companyId: string) => {
    try {
      const { data } = await getMicrositeApi({ companyId });

      if (data) {
        dispatch(setCompanyMicroSiteData(data));
      }
    } catch (error: any) {
      const customError = handleRequestError(error);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setInitialized(true);
    }
  };

  return {
    initialized,
    microSite,
  };
};

export default useGetMicroSiteData;
