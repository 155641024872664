import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TCompany } from "./companiesSlice";
import { SortingDirection } from "src/types/docTemplates";

type CMCompaniesState = {
  list: TCompany[];
  pageNumber: number;
  pageSize: number;
  total: number;
  sortBy?: keyof TCompany;
  orderBy?: SortingDirection;
};

const initialState: CMCompaniesState = {
  list: [],
  pageNumber: 1,
  pageSize: 50,
  total: 0,
};

const companiesListSlice = createSlice({
  name: "companiesList",
  initialState,
  reducers: {
    setCompanies: (
      state: CMCompaniesState,
      action: PayloadAction<{ items: TCompany[]; totalItems: number }>,
    ) => {
      state.list = action.payload.items;
      state.total = action.payload.totalItems;
    },
    setPageSize: (state: CMCompaniesState, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNumber: (state: CMCompaniesState, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    setSorting: (
      state: CMCompaniesState,
      action: PayloadAction<{ sortBy?: keyof TCompany; orderBy?: SortingDirection }>,
    ) => {
      state.sortBy = action.payload.sortBy;
      state.orderBy = action.payload.orderBy;
    },
  },
});

export const { setCompanies, setPageNumber, setPageSize, setSorting } =
  companiesListSlice.actions;

export default companiesListSlice.reducer;

export const selectCompaniesListState = (state: RootState) =>
  state.companiesList;
