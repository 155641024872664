import { useLocation, useNavigate } from "react-router-dom";

export const useGoBack = (defaultPath = "/campaigns") => {
  const navigate = useNavigate();
  const location = useLocation();

  return () => {
    if (location.key === "default") {
      navigate(defaultPath);
    } else {
      navigate(-1);
    }
  };
};
