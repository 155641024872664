import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  connectSocketThunk,
  disconnectSocketThunk,
  selectSocketByCampaignId,
  selectSocketShouldReconnect,
} from "../slice";
import { useParams } from "react-router-dom";
import { wait } from "../../../../utils";
import { SOCKET_RECONNECT_MAX_DELAY } from "../constants";

const useConnectSocket = () => {
  const { campaignId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const socket = useSelector((state: RootState) =>
    selectSocketByCampaignId(state, campaignId ? +campaignId : null),
  );
  const socketShouldReconnect = useSelector(selectSocketShouldReconnect);
  const [reconnectDelay, setReconnectDelay] = useState(1000);

  useEffect(() => {
    const connectSocket = async (campaignId: number) => {
      await dispatch(
        connectSocketThunk({
          campaignId,
          shouldUpdateCurrentStepCcItems: true,
        }),
      );
    };

    if (campaignId) {
      connectSocket(+campaignId);
    }

    return () => {
      if (campaignId) {
        dispatch(disconnectSocketThunk({ campaignId: +campaignId }));
      }
    };
  }, [campaignId]);

  // Effect for reconnection processing
  useEffect(() => {
    const connect = async (campaignId: number) => {
      if (!socket && socketShouldReconnect) {
        console.log(
          `Attempting to reconnect socket in ${reconnectDelay} ms... campaignId: ${campaignId}`,
        );

        await wait(reconnectDelay);
        await dispatch(
          connectSocketThunk({
            campaignId,
            shouldUpdateCurrentStepCcItems: true,
          }),
        );

        setReconnectDelay((prevDelay) =>
          Math.min(prevDelay * 2, SOCKET_RECONNECT_MAX_DELAY),
        ); // Increasing the delay, but not more than SOCKET_RECONNECT_MAX_DELAY seconds
      } else {
        setReconnectDelay(1000);
      }
    };

    if (campaignId) {
      connect(+campaignId);
    }
  }, [socket, socketShouldReconnect]);
};
export default useConnectSocket;
