import { useDispatch, useSelector } from "react-redux";
import {
  loadSystemPermissions,
  selectMessageApi,
} from "../../../store/slices/appSlice";
import { useEffect, useState } from "react";
import handleRequestError from "../../../utils/handleRequestError";
import { useParams } from "react-router-dom";
import {
  addSecurityGroupToUserApi,
  deleteSecurityGroupFromUserApi,
  getUserGlobalPermissionsApi,
} from "../../../api/user.api";
import { TUserGlobalPermissions } from "../../../globalTypes";
import {
  AddGroupAndUpdateList,
  LoadPermissionGroups,
} from "../../../types/securityGroups";
import { getGlobalPermissionGroupsApi } from "../../../api/permissions-group.api";
import { AppDispatch } from "../../../store/store";

const useGetGlobalPermissions = () => {
  const { userId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");
  const [globalPermissions, setGlobalPermissions] =
    useState<TUserGlobalPermissions | null>(null);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (userId !== undefined) {
      loadPermissions({ userId });
    }
  }, [userId]);

  const loadPermissions = async ({ userId }: { userId: number | string }) => {
    try {
      await dispatch(loadSystemPermissions()).unwrap();

      const { data: userGroups } = await getUserGlobalPermissionsApi({
        userId,
      });

      setGlobalPermissions(userGroups);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    } finally {
      setIsInitialized(true);
    }
  };

  const addGroupAndUpdateList: AddGroupAndUpdateList = async ({
    permissionsGroupId,
    userId,
    companyId,
  }) => {
    await addSecurityGroupToUserApi({
      userId,
      companyId,
      permissionsGroupId,
    });
    await updatePermissionsList();
  };

  const updatePermissionsList = async () => {
    try {
      if (userId === undefined) return;

      const { data: userGroups } = await getUserGlobalPermissionsApi({
        userId,
      });

      setGlobalPermissions(userGroups);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    }
  };

  const deleteGroup = async ({
    permissionsGroupId,
  }: {
    permissionsGroupId: number;
  }) => {
    try {
      if (userId === undefined) return;

      setIsFetching(true);

      await deleteSecurityGroupFromUserApi({
        userId,
        permissionsGroupId,
      });

      const { data: userGroups } = await getUserGlobalPermissionsApi({
        userId,
      });

      setGlobalPermissions(userGroups);
      setError("");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const loadGlobalPermissionGroups: LoadPermissionGroups = async () => {
    const { data } = await getGlobalPermissionGroupsApi();

    if (globalPermissions && globalPermissions.permissionGroups.length) {
      const assignedGroupIds = globalPermissions.permissionGroups.map(
        (item) => item.id,
      );

      return data.filter(({ id }) => !assignedGroupIds.includes(id));
    }

    return data;
  };

  return {
    isInitialized,
    globalPermissions,
    error,
    deleteGroup,
    updatePermissionsList,
    addGroupAndUpdateList,
    isFetching,
    loadGlobalPermissionGroups,
  };
};

export default useGetGlobalPermissions;
