import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { AppDispatch } from "../../../store/store";
import {
  selectUsersListState,
  setPageNumber,
  setPageSize,
  setUsers,
} from "../../../store/slices/usersListSlice";
import handleRequestError from "../../../utils/handleRequestError";
import { createUserApi, getUsersListApi } from "../../../api/user.api";
import { TUserForm } from "../../../globalTypes";
import useNavigateWithOutletRedirect from "../../../hooks/navigation/useNavigateWithOutletRedirect";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";

const useGetUsersHook = () => {
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();
  const { hasUserReadRole } = useUserHasPermission({});
  const [isFetching, setIsFetching] = useState(true);
  const { users, total, pageNumber, pageSize } =
    useSelector(selectUsersListState);

  useEffect(() => {
    loadUsers({ pageNumber, pageSize });
  }, [pageNumber, pageSize]);

  const loadUsers = async ({
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  }) => {
    try {
      setIsFetching(true);

      const { data } = await getUsersListApi({
        pageSize,
        pageNumber,
      });

      const { items, totalItems } = data;
      dispatch(setUsers({ users: items, total: totalItems }));
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const updatePage = (pageNumber: number) => {
    dispatch(setPageNumber(pageNumber));
  };

  const updatePageSize = (pageSize: number) => {
    dispatch(setPageNumber(1));
    dispatch(setPageSize(pageSize));
  };

  const onCreateUser = async ({ values }: { values: TUserForm }) => {
    const { data: newUser } = await createUserApi({ userData: values });

    if (hasUserReadRole) {
      navigateWithOutletRedirect(`/users/${newUser.id}/details`);
    } else {
      await loadUsers({ pageNumber, pageSize });
    }
  };

  const toggleOpenDrawer = (open: boolean) => {
    setDrawerVisible(open);
  };

  return {
    users,
    isFetching,
    total,
    pageSize,
    pageNumber,
    updatePage,
    updatePageSize,
    drawerVisible,
    toggleOpenDrawer,
    onCreateUser,
  };
};

export default useGetUsersHook;
