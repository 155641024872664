import React from "react";
import { Route, Routes } from "react-router-dom";
import { UserPermissions } from "../../store/slices/userData/constants";
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";
import NotFoundPage from "../../pages/NotFoundPage";
import GlobalLabelsPage from "../../pages/labels/GlobalLabelsPage";

const GlobalLabelsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedByRoleRoute permission={UserPermissions.ROLE_G18N_LIST}>
            <GlobalLabelsPage />
          </ProtectedByRoleRoute>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default GlobalLabelsRoutes;
