import { Skeleton } from "antd";
import { TableCell, TableRow } from "../common/ui/table";
import { FC } from "react";

export const CampaignListItemSkeleton: FC<{}> = () => {
  const randomWidth = Math.floor(Math.random() * 200) + 100;
  const randomCompanyWidth = Math.floor(Math.random() * 50) + 100;

  return (
    <TableRow className="h-[40px]">
      <TableCell>
        <Skeleton.Button active style={{ width: randomWidth, height: 20 }} />
      </TableCell>
      <TableCell>
        <div className="flex items-center gap-2">
          <Skeleton.Avatar active size="small" />
          <Skeleton.Button
            active
            style={{ width: randomCompanyWidth, height: 20 }}
          />
        </div>
      </TableCell>
      <TableCell>
        <Skeleton.Button active style={{ width: 80, height: 20 }} />
      </TableCell>
      <TableCell>
        <Skeleton.Button active style={{ width: 100, height: 20 }} />
      </TableCell>
      <TableCell>
        <div className="w-[40px] h-[40px] flex items-center justify-center">
          <Skeleton.Avatar active size="small" />
        </div>
      </TableCell>
    </TableRow>
  );
};
