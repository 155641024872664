import { PostHogProvider } from "posthog-js/react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Env } from "./types";

export const getCurrentEnv = () => {
  const urlMapping: Record<string, Env | undefined> = {
    "https://app": Env.PROD,
    "https://preprod": Env.PREPROD,
    "https://qa": Env.QA,
    "https://dev": Env.DEV,
    "http://localhost": Env.LOCAL,
  };

  const currentUrl = window.location.href;

  for (const prefix in urlMapping) {
    if (currentUrl.startsWith(prefix)) {
      return urlMapping[prefix];
    }
  }

  return Env.LOCAL;
};

// TODO: refactor to use getCurrentEnv
const getPostHogApiKey = () => {
  const urlMapping: { [key: string]: string | undefined } = {
    "https://app": process.env.REACT_APP_PUBLIC_POSTHOG_PROD_KEY,
    "https://preprod": process.env.REACT_APP_PUBLIC_POSTHOG_PREPROD_KEY,
    "https://qa": process.env.REACT_APP_PUBLIC_POSTHOG_QA_KEY,
    "https://dev": process.env.REACT_APP_PUBLIC_POSTHOG_DEV_KEY,
  };

  const currentUrl = window.location.href;

  for (const prefix in urlMapping) {
    if (currentUrl.startsWith(prefix)) {
      return urlMapping[prefix];
    }
  }

  return process.env.REACT_APP_PUBLIC_POSTHOG_DEV_KEY;
};

const postHogApiKey = getPostHogApiKey();
const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};
const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <PostHogProvider apiKey={postHogApiKey} options={options}>
    <App />
  </PostHogProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
