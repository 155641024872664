import React, { FC, useEffect, useState } from "react";
import UserDrawer from "./UserDrawer/UserDrawer";
import { ChevronLeftIcon, Icons } from "../common/Icons";
import { Button } from "../common/ui/button";
import { Skeleton } from "antd";
import { useGoBack } from "../../hooks/useGoBack";
import { TUser, TUserForm } from "../../globalTypes";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../store/slices/appSlice";
import { getUserDataApi, updateUserApi } from "../../api/user.api";
import handleRequestError from "../../utils/handleRequestError";

type Props = {
  goBackDefaultPath?: string;
  hasUserUpdateRole: boolean;
};

const UserDetailsMainInfo: FC<Props> = ({
  goBackDefaultPath,
  hasUserUpdateRole,
}) => {
  const { userId } = useParams();
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState("");
  const [userInfo, setUserInfo] = useState<TUser | null>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const goBack = useGoBack(goBackDefaultPath);

  useEffect(() => {
    if (userId) {
      loadUserInfo({ userId });
    }
  }, [userId]);

  const loadUserInfo = async ({ userId }: { userId: string }) => {
    try {
      const { data } = await getUserDataApi({
        userId,
      });

      setUserInfo(data);
      setError("");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);
      console.error(customError);
    } finally {
      setIsInitialized(true);
    }
  };

  const onUpdateUser = async ({ values }: { values: TUserForm }) => {
    if (!userId) {
      console.warn("There is no user ID for updating!");
      return;
    }

    const { data } = await updateUserApi({
      userData: values,
      userId,
    });

    setUserInfo(data);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-[20px]">
        <div
          onClick={goBack}
          className="flex gap-[4px] items-center font-medium text-[#475569] transition-colors text-base cursor-pointer hover:text-vePrimary"
        >
          <ChevronLeftIcon className="w-[20px] h-[20px]" />
          <span>Back</span>
        </div>

        {hasUserUpdateRole && (
          <Button
            icon={Icons.edit}
            variant="primaryOutline"
            className="rounded-full"
            disabled={!isInitialized || !!error}
            onClick={() => setDrawerVisible(true)}
          >
            Edit User
          </Button>
        )}
      </div>

      {error && <div className="text-red-600">Error: {error}</div>}

      {isInitialized ? (
        <>
          {userInfo && (
            <div className="w-full flex flex-col">
              <h1 className="font-semibold text-[24px] text-vePrimary">
                {userInfo.firstName} {userInfo.lastName}
              </h1>
              <div className="flex justify-between py-[12px]">
                <div className="flex flex-col w-[30%] gap-[4px]">
                  <span className="font-bold text-[14px] text-[#475569]">
                    Email
                  </span>
                  <span>{userInfo.email}</span>
                </div>

                <div className="flex flex-col w-[30%] gap-[4px]">
                  <span className="font-bold text-[14px] text-[#475569]">
                    Position
                  </span>
                  <span className="font-normal text-[16px] text-[#0F172A]">
                    {userInfo.position}
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Skeleton active />
      )}

      {hasUserUpdateRole && userInfo && (
        <UserDrawer
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          initialFormValues={{
            lastName: userInfo.lastName,
            firstName: userInfo.firstName,
            position: userInfo.position,
            email: userInfo.email,
          }}
          onUpdateUser={onUpdateUser}
        />
      )}
    </div>
  );
};

export default UserDetailsMainInfo;
