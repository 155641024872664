import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCompaniesListState,
  setCompanies,
} from "src/store/slices/companiesListSlice";
import {
  CompaniesListRequestParams,
  createCompanyApi,
  getCompaniesApi,
} from "../../../api/companies.api";
import { selectMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import { TCompanyForm } from "../../../components/companies/CompanyDrawer/CompanyDrawer";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import useNavigateWithOutletRedirect from "../../../hooks/navigation/useNavigateWithOutletRedirect";
import { SortingDirection } from "src/types/docTemplates";
import { TCompany } from "src/store/slices/companiesSlice";
import { useCompaniesSearchParams } from "./useCompaniesSearchParams";
import { DEFAULT_LISTING_PAGE_SIZE } from "src/constants";

const useCompaniesListHook = () => {
  const dispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const { hasCompanyReadRole } = useUserHasPermission({});
  const [loading, setLoading] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();
  const { requestParams, updateSearchParams } = useCompaniesSearchParams();
  const { list, total } = useSelector(selectCompaniesListState);

  useEffect(() => {
    if (requestParams.pageNumber && requestParams.pageSize) {
      loadCompanies(requestParams);
    } else {
      updateSearchParams({
        ...requestParams,
        pageNumber: 1,
        pageSize: DEFAULT_LISTING_PAGE_SIZE,
      });
    }
  }, [requestParams]);

  const loadCompanies = async (params: CompaniesListRequestParams) => {
    try {
      setLoading(true);
      const { data } = await getCompaniesApi(params);
      dispatch(setCompanies(data));
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
    }
  };

  const updatePage = (pageNumber: number) => {
    updateSearchParams({
      ...requestParams,
      pageNumber,
    });
  };

  const updatePageSize = (pageSize: number) => {
    updateSearchParams({
      ...requestParams,
      pageSize,
      pageNumber: 1,
    });
  };

  const updateSorting = (
    sortBy?: keyof TCompany,
    orderBy?: SortingDirection,
  ) => {
    if (!sortBy || !orderBy) {
      updateSearchParams({
        ...requestParams,
        sortBy: undefined,
        orderBy: undefined,
      });
      return;
    }
    updateSearchParams({ ...requestParams, sortBy, orderBy });
  };

  const onCreateCompany = async ({ values }: { values: TCompanyForm }) => {
    try {
      const { data } = await createCompanyApi({ payload: values });

      setDrawerVisible(false);

      if (hasCompanyReadRole) {
        navigateWithOutletRedirect(`/companies/${data.id}/details`);
      } else {
        await loadCompanies({
          pageNumber: requestParams.pageNumber,
          pageSize: requestParams.pageSize,
        });
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const updateSearch = (search: string) => {
    updateSearchParams({
      ...requestParams,
      name: search || undefined,
      pageNumber: 1,
    });
  };

  return {
    list,
    total,
    loading,
    updatePage,
    updatePageSize,
    onCreateCompany,
    setDrawerVisible,
    updateSorting,
    drawerVisible,
    requestParams,
    updateSearch,
  };
};

export default useCompaniesListHook;
