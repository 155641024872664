import React, { FC, useEffect, useRef, useState } from "react";
import { getMessageApi } from "../../store/slices/appSlice";
import {
  selectComputationInfo,
  selectEventMessages,
} from "../../store/slices/computationMessages/slice";
import { useParams } from "react-router-dom";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { stopExecuteCampaignApi } from "../../api/campaigns.api";
import handleRequestError from "../../utils/handleRequestError";
import { Empty, Modal, Segmented, Table } from "antd";
import ComputationProgress from "./ComputationProgress";
import { Button } from "../common/ui/button";
import { TComputationState } from "../../store/slices/ccVariablesSlice";
import Column from "antd/es/table/Column";
import {
  getClassNameForTypeCol,
  getFilteredMessages,
  getPathNode,
  getStateConfig,
} from "./utils";
import { useSelector } from "react-redux";
import {
  TComputationMessage,
  TComputationStatus,
  TSocketMsgType,
} from "../../store/slices/computationMessages/types";
import localStorageProvider from "../../utils/localStorageProvider";
import { FilterOption } from "./constants";

type Props = {
  onCloseAndClear: () => void;
  setIsListOfMessagesOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isListOfMessagesOpened: boolean;
  campaignId: number;
};

const EventsLogModal: FC<Props> = ({
  onCloseAndClear,
  setIsListOfMessagesOpened,
  isListOfMessagesOpened,
  campaignId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterValue, setFilterValue] = useState<FilterOption>(
    localStorageProvider.getEventLogFilter() || FilterOption.ALL,
  );
  const [filteredMessages, setFilteredMessages] = useState<
    TComputationMessage[]
  >([]);
  const messageApi = getMessageApi();
  const computationInfo = useSelector(selectComputationInfo);
  const messages = useSelector(selectEventMessages);
  const { companyId } = useParams();
  const { hasCampaignComputationStopRole } = useUserHasPermission({
    companyId,
  });
  const filterOptions = Object.values(FilterOption);
  const tableRef = useRef<any>(null);

  useEffect(() => {
    const filtered = getFilteredMessages({
      filter: filterValue,
      messages,
    });

    setFilteredMessages(filtered);
  }, [messages]);

  const onStopCampaignExecution = async () => {
    try {
      setIsLoading(true);
      await stopExecuteCampaignApi({ campaignId });

      messageApi.success(
        "Your request to stop the execution of the campaign has been sent",
      );
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const onFilterChange = (filter: FilterOption) => {
    const filtered = getFilteredMessages({
      filter,
      messages,
    });

    if (tableRef.current && tableRef.current.scrollTo) {
      tableRef.current.scrollTo({ top: 0 });
    }

    localStorageProvider.setEventLogFilter(filter);
    setFilteredMessages(filtered);
    setFilterValue(filter);
  };

  return (
    <Modal
      title="Events log"
      open={isListOfMessagesOpened}
      onCancel={() => setIsListOfMessagesOpened(false)}
      width={1150}
      centered
      footer={
        <div className="flex justify-between items-end flex-wrap">
          <ComputationProgress />
          <div className="flex gap-[6px]">
            {computationInfo?.status !== "finished" &&
              computationInfo?.status !== "interrupted" &&
              hasCampaignComputationStopRole && (
                <Button
                  size="sm"
                  loading={isLoading}
                  onClick={onStopCampaignExecution}
                  className="rounded-full"
                  variant="outline"
                >
                  Stop Execution
                </Button>
              )}

            <Button
              size="sm"
              onClick={onCloseAndClear}
              className="rounded-full"
              variant="primaryOutline"
            >
              Clear and Close
            </Button>
          </div>
        </div>
      }
    >
      <div className="h-[550px]">
        {messages.length ? (
          <div className="flex flex-col gap-2">
            <Segmented
              className="self-start"
              size="small"
              onChange={onFilterChange}
              value={filterValue}
              options={filterOptions}
            />
            <Table
              size="small"
              ref={tableRef}
              dataSource={filteredMessages}
              pagination={false}
              scroll={{ x: 1100, y: 480 }}
              virtual
            >
              <Column
                title="Type"
                dataIndex="type"
                key="type"
                width={200}
                render={(type: TSocketMsgType) => {
                  const className = getClassNameForTypeCol(type);

                  return <div className={className}>{type}</div>;
                }}
              />
              <Column
                title="Time"
                dataIndex="localTime"
                key="localTime"
                width={200}
              />
              <Column
                title="Item"
                dataIndex="path"
                key="key"
                render={(
                  value: string | null,
                  {
                    errorMessage,
                    computationTime,
                    message,
                    phaseId,
                    stepId,
                    item,
                  }: TComputationMessage,
                ) => {
                  const keyNode = getPathNode({
                    companyId,
                    campaignId,
                    phaseId: phaseId,
                    stepId: stepId,
                    ccItemKey: item?.id.key,
                    path: value,
                  });

                  return (
                    <div className="flex flex-col">
                      <div className="flex items-center gap-[12px]">
                        {keyNode}

                        {computationTime !== undefined && (
                          <span className="text-blue-800 text-[12px]">
                            {`(${computationTime}ms)`}
                          </span>
                        )}
                      </div>

                      {message && (
                        <div className="text-blue-800 text-[12px]">
                          {message}
                        </div>
                      )}

                      {errorMessage && (
                        <div className="text-red-800 text-[12px]">
                          {errorMessage}
                        </div>
                      )}
                    </div>
                  );
                }}
              />
              <Column
                title="Status"
                dataIndex="status"
                key="status"
                width={80}
                align="center"
                render={(state: TComputationState | TComputationStatus) => {
                  const { icon, className } = getStateConfig(state);

                  return (
                    <div className={`flex justify-center ${className}`}>
                      {icon}
                    </div>
                  );
                }}
              />
            </Table>
          </div>
        ) : (
          <Empty
            imageStyle={{ height: "400px" }}
            className="font-sans font-semibold text-[14px]"
            description="There are no computation events"
          />
        )}
      </div>
    </Modal>
  );
};

export default EventsLogModal;
