import type { PresetStatusColorType } from "antd/es/_util/colors";
import { TSocketMsgType } from "../../store/slices/computationMessages/types";

type SocketConnectionStateBadgeProps = {
  tooltipTitle: string;
  tooltipColor: string;
  badgeStatus: PresetStatusColorType;
};

export enum SocketConnectionState {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
}

export const socketConnectionStateMap: Record<
  SocketConnectionState,
  SocketConnectionStateBadgeProps
> = {
  [SocketConnectionState.CONNECTED]: {
    tooltipTitle: "Connected",
    tooltipColor: "green",
    badgeStatus: "success",
  },
  [SocketConnectionState.DISCONNECTED]: {
    tooltipTitle: "Disconnected",
    tooltipColor: "red",
    badgeStatus: "error",
  },
};

export const eventMessageTypeClassMap: Record<TSocketMsgType, string> = {
  CI_UPDATED: "bg-green-100 text-green-900",
  CI_ERROR: "bg-red-100 text-red-900",
  PHASE_ERROR: "bg-red-100 text-red-900",
  CAMPAIGN_ERROR: "bg-red-100 text-red-900",
  STEP_ERROR: "bg-red-100 text-red-900",
  DEPLOY_ERROR: "bg-red-100 text-red-900",
  ASSET_ERROR: "bg-red-100 text-red-900",
  ZIP_EXPORT_ERROR: "bg-red-100 text-red-900",
  ZIP_IMPORT_ERROR: "bg-red-100 text-red-900",
  POST_PROCESSING: "bg-blue-100 text-blue-900",
  ZIP_EXPORT_INFO: "bg-blue-100 text-blue-900",
  MICROSITE_DEPLOY: "bg-purple-100 text-purple-900",
  COMPUTATION_STATUS: "bg-purple-100 text-purple-900",
};

export enum FilterOption {
  ALL = "All",
  ERROR = "Error",
  COMPUTED = "Computed",
  IN_PROGRESS = "In Progress",
  CREATED = "Created",
}
