import { AxiosError, AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import { downloadFileUtil } from "../../../../utils/cm.utils";

type Props = {
  download: (path: string) => Promise<AxiosResponse<ArrayBuffer | Blob, any>>;
};
const useDownload = ({ download }: Props) => {
  const messageApi = useSelector(selectMessageApi);
  return async (path: string) => {
    try {
      messageApi.open({
        key: "download_file",
        type: "loading",
        content: "Downloading...",
      });
      const response = await download(path);
      downloadFileUtil(response);
      messageApi.open({
        key: "download_file",
        type: "success",
        content: "Download completed successfully",
      });
    } catch (e: any) {
      console.error(e);

      let errorMessage = e?.message;

      if (e instanceof AxiosError && e.response ) {
        errorMessage = JSON.parse(
          new TextDecoder().decode(e.response.data),
        ).message;
      }

      messageApi.open({
        key: "download_file",
        type: "error",
        content: errorMessage,
      });
    }
  };
};
export default useDownload;
