import { Progress, Typography } from "antd";
import { selectComputationInfo } from "../../store/slices/computationMessages/slice";
import { getProgressInfo } from "./utils";
import { useSelector } from "react-redux";

const ComputationProgress = () => {
  const computationInfo = useSelector(selectComputationInfo);
  const progress = computationInfo?.progress || 0;

  const { status, className } = getProgressInfo({
    progress,
    computationStatus: computationInfo?.status,
  });

  return (
    <div className="flex flex-col items-start">
      <div className="flex gap-[16px] items-center">
        <div className="flex items-center gap-[12px]">
          <span className="font-sans font-bold text-[#475569] text-[14px]">
            Status:
          </span>
          <div className={className}>
            {computationInfo?.status || "On hold"}
          </div>
        </div>

        {computationInfo && (
          <div className="flex items-center gap-[12px]">
            <Typography.Text code>
              {computationInfo.executionType} ({computationInfo.type})
            </Typography.Text>
          </div>
        )}
      </div>

      <div className="flex items-center gap-[12px]">
        <span className="font-sans font-bold text-[#475569] text-[14px]">
          Progress:
        </span>
        <Progress
          className="w-[300px] pt-[6px]"
          percent={progress}
          status={status}
        />
      </div>
    </div>
  );
};

export default ComputationProgress;
