import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  NavigateToOutletLocationState,
  TNavigationItem,
} from "../../globalTypes";

type Props = {
  navigationItems: TNavigationItem[];
};

const useRedirectToFirstChildOutlet = ({ navigationItems }: Props) => {
  const location = useLocation();
  const locationState = location.state as NavigateToOutletLocationState | null;
  const navigate = useNavigate();

  useEffect(() => {
    if (navigationItems.length && locationState?.navigateToOutlet) {
      navigate(navigationItems[0].path, { replace: true });
    }
  }, [navigationItems, locationState]);
};

export default useRedirectToFirstChildOutlet;
