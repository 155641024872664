import { deleteMicrositeApi } from "../../../api/microsite.api";
import {
  selectCompanyStorageMicroSiteData,
  setCompanyMicroSiteData,
} from "../../../store/slices/companyStorageSlice";
import handleRequestError from "../../../utils/handleRequestError";
import { AppDispatch } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useState } from "react";

const useDeleteMicroSite = () => {
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const microSite = useSelector(selectCompanyStorageMicroSiteData);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRemoveMicroSite = async () => {
    if (!microSite) return;

    const { companyId, subDomain } = microSite;

    try {
      setLoading(true);

      await deleteMicrositeApi({ companyId, subDomain });

      dispatch(setCompanyMicroSiteData(null));
      setIsDeleteModalOpen(false);
    } catch (error: any) {
      const customError = handleRequestError(error);

      messageApi.error(customError.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    isDeleteModalOpen,
    loading,
    handleRemoveMicroSite,
    setIsDeleteModalOpen,
  };
};

export default useDeleteMicroSite;
