import { useSelector } from "react-redux";
import { selectUserData } from "../store/slices/userData/slice";
import Intercom, { shutdown } from "@intercom/messenger-js-sdk";
import { useEffect } from "react";

const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

const useInitializeIntercomChat = () => {
  const { firstName, lastName, lastLoginTime, email, id } =
    useSelector(selectUserData);

  useEffect(() => {
    if (APP_ID && !isLocalhost) {
      Intercom({
        app_id: APP_ID,
        user_id: id.toString(),
        name: `${firstName} ${lastName}`,
        email,
        created_at: new Date(lastLoginTime).getTime(),
        vertical_padding: 80,
      });
    }

    return () => {
      if (!isLocalhost) {
        shutdown();
      }
    };
  }, []);
};

export default useInitializeIntercomChat;
