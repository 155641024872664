import React from "react";
import SecurityGroupForm from "../../../components/securityGroups/SecurityGroupForm";
import { Icons } from "../../../components/common/Icons";
import { Button } from "../../../components/common/ui/button";
import { TUserPermissionGroupFormValues } from "../../../types/securityGroups";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import PermissionsTree from "../../../components/securityGroups/PermissionsTree";
import useGetSecurityGroupData from "./useGetSecurityGroupData.hook";
import SkeletonList from "../../../components/common/SkeletonList";
import { useSelector } from "react-redux";
import { selectSystemPermissions } from "../../../store/slices/appSlice";

const SecurityGroupDataPage = () => {
  const {
    isInitialized,
    initialValues,
    securityGroupId,
    isSuperUser,
    setCheckedCompanyPermissions,
    checkedGlobalPermissions,
    setCheckedGlobalPermissions,
    checkedCompanyPermissions,
    onSaveData,
    isFetching,
    form,
    navigateToGroupList,
  } = useGetSecurityGroupData();
  const permissionsTree = useSelector(selectSystemPermissions);

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TUserPermissionGroupFormValues) => {
      await onSaveData(values);
    },
  });

  return (
    <div>
      <div className="flex items-center justify-between mb-3">
        <div className="flex gap-[16px] items-center">
          <Button
            variant={"ghost"}
            icon={Icons.back}
            onClick={navigateToGroupList}
            className="p-[12px] h-auto"
          />
          <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal">
            {securityGroupId ? "Edit" : "Create"} Security Group
          </h1>
        </div>
        {!isSuperUser && (
          <Button
            className="rounded-full"
            loading={isFetching}
            disabled={isFetching || !isInitialized}
            onClick={submitForm}
          >
            Save Group
          </Button>
        )}
      </div>

      {isInitialized ? (
        <>
          {permissionsTree && isInitialized && (
            <div className="flex gap-[48px]">
              <SecurityGroupForm
                form={form}
                initialValues={initialValues}
                isSuperUser={isSuperUser}
              />
              <div className="flex flex-col gap-[24px]">
                <PermissionsTree
                  permissionsTree={permissionsTree.globalLevel}
                  setCheckedKeys={setCheckedGlobalPermissions}
                  checkedKeys={checkedGlobalPermissions}
                  isSuperUser={isSuperUser}
                  level="GLOBAL"
                />
                <PermissionsTree
                  permissionsTree={permissionsTree.companyLevel}
                  setCheckedKeys={setCheckedCompanyPermissions}
                  checkedKeys={checkedCompanyPermissions}
                  isSuperUser={isSuperUser}
                  level="COMPANY"
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <SkeletonList quantity={3} />
      )}
    </div>
  );
};

export default SecurityGroupDataPage;
