import { useSelector } from "react-redux";
import { selectUserPermissions } from "src/store/slices/userData/slice";
import { UserPermissions } from "../constants";
import { buildPermissions, hasUserPermissionAnywhere } from "../utils";

type UseUserHasPermissions = {
  companyId?: string | number;
};

export const useUserHasPermission = ({ companyId }: UseUserHasPermissions) => {
  const userPermissions = useSelector(selectUserPermissions);
  const { globalPermissions, companyPermissions, companyPermissionsDeny } =
    userPermissions;

  const permissionsSet = buildPermissions({
    globalPermissions,
    companyPermissions,
    companyPermissionsDeny,
    companyId,
  });

  const hasPermission = (permission: UserPermissions): boolean =>
    permissionsSet.has(permission);

  const hasPermissionAnywhere = (permission: UserPermissions): boolean => {
    return hasUserPermissionAnywhere({ permission, userPermissions });
  };

  return {
    hasCompanyMicrositeUserListRole: hasPermission(
      UserPermissions.ROLE_COMPANY_MICROSITE_USER_LIST,
    ),
    hasCompanyMicrositeUserCreateRole: hasPermission(
      UserPermissions.ROLE_COMPANY_MICROSITE_USER_CREATE,
    ),
    hasCompanyMicrositeUserDeleteRole: hasPermission(
      UserPermissions.ROLE_COMPANY_MICROSITE_USER_DELETE,
    ),
    hasCompanyMicrositeUserResetPwdRole: hasPermission(
      UserPermissions.ROLE_COMPANY_MICROSITE_USER_RESET_PWD,
    ),
    hasPermGroupListRole: hasPermission(UserPermissions.ROLE_PERM_GROUP_LIST),
    hasPermGroupReadRole: hasPermission(UserPermissions.ROLE_PERM_GROUP_READ),
    hasPermGroupCreateRole: hasPermission(
      UserPermissions.ROLE_PERM_GROUP_CREATE,
    ),
    hasPermGroupUpdateRole: hasPermission(
      UserPermissions.ROLE_PERM_GROUP_UPDATE,
    ),
    hasPermGroupDeleteRole: hasPermission(
      UserPermissions.ROLE_PERM_GROUP_DELETE,
    ),
    hasAiConfigurationListRole: hasPermission(
      UserPermissions.ROLE_AI_CONFIGURATION_LIST,
    ),
    hasAiConfigurationReadRole: hasPermission(
      UserPermissions.ROLE_AI_CONFIGURATION_READ,
    ),
    hasAiConfigurationCreateRole: hasPermission(
      UserPermissions.ROLE_AI_CONFIGURATION_CREATE,
    ),
    hasAiConfigurationUpdateRole: hasPermission(
      UserPermissions.ROLE_AI_CONFIGURATION_UPDATE,
    ),
    hasAiConfigurationDeleteRole: hasPermission(
      UserPermissions.ROLE_AI_CONFIGURATION_DELETE,
    ),
    hasAiConfigurationSetDefaultRole: hasPermission(
      UserPermissions.ROLE_AI_CONFIGURATION_SET_DEFAULT,
    ),

    // Microsite storage
    hasMsFsFileListRole: hasPermission(UserPermissions.ROLE_MS_FS_FILE_LIST),
    hasMsFsUploadRole: hasPermission(UserPermissions.ROLE_MS_FS_UPLOAD),
    hasMsFsEditRole: hasPermission(UserPermissions.ROLE_MS_FS_EDIT),
    hasMsFsCopyRole: hasPermission(UserPermissions.ROLE_MS_FS_COPY),
    hasMsFsReadRole: hasPermission(UserPermissions.ROLE_MS_FS_READ),
    hasMsFsDeleteRole: hasPermission(UserPermissions.ROLE_MS_FS_DELETE),

    hasUserCompanyPermReadRole: hasPermission(
      UserPermissions.ROLE_USER_COMPANY_PERM_READ,
    ),
    hasUserCompanyPermCreateRole: hasPermission(
      UserPermissions.ROLE_USER_COMPANY_PERM_CREATE,
    ),
    hasUserCompanyPermDeleteRole: hasPermission(
      UserPermissions.ROLE_USER_COMPANY_PERM_DELETE,
    ),
    hasDocumentTemplateListRole: hasPermission(
      UserPermissions.ROLE_DOCUMENT_TEMPLATE_LIST,
    ),
    hasDocumentTemplateImportRole: hasPermission(
      UserPermissions.ROLE_DOCUMENT_TEMPLATE_IMPORT,
    ),
    hasDocumentTemplateCreateRole: hasPermission(
      UserPermissions.ROLE_DOCUMENT_TEMPLATE_CREATE,
    ),
    hasDocumentTemplateUpdateRole: hasPermission(
      UserPermissions.ROLE_DOCUMENT_TEMPLATE_UPDATE,
    ),
    hasDocumentTemplateDeleteRole: hasPermission(
      UserPermissions.ROLE_DOCUMENT_TEMPLATE_DELETE,
    ),
    hasDocumentTemplateReadRole: hasPermission(
      UserPermissions.ROLE_DOCUMENT_TEMPLATE_READ,
    ),
    hasDocumentTemplateHtml2XslRole: hasPermission(
      UserPermissions.ROLE_DOCUMENT_TEMPLATE_HTML_2_XSL,
    ),

    // User
    hasUserListRole: hasPermission(UserPermissions.ROLE_USER_LIST),
    hasUserReadRole: hasPermission(UserPermissions.ROLE_USER_READ),
    hasUserCreateRole: hasPermission(UserPermissions.ROLE_USER_CREATE),
    hasUserUpdateRole: hasPermission(UserPermissions.ROLE_USER_UPDATE),
    hasUserDeleteRole: hasPermission(UserPermissions.ROLE_USER_DELETE),
    hasUserDisableRole: hasPermission(UserPermissions.ROLE_USER_DISABLE),
    hasUserEnableRole: hasPermission(UserPermissions.ROLE_USER_ENABLE),
    hasUserCompaniesReadRole: hasPermission(
      UserPermissions.ROLE_USER_COMPANIES_READ,
    ),
    hasUserCompaniesAssignRole: hasPermission(
      UserPermissions.ROLE_USER_COMPANIES_ASSIGN,
    ),
    hasUserCompaniesUnassignRole: hasPermission(
      UserPermissions.ROLE_USER_COMPANIES_UNASSIGN,
    ),

    // Company
    hasCompanyListRole: hasPermission(UserPermissions.ROLE_COMPANY_LIST),
    hasCompanyReadRole: hasPermission(UserPermissions.ROLE_COMPANY_READ),
    hasCompanyCreateRole: hasPermission(UserPermissions.ROLE_COMPANY_CREATE),
    hasCompanyUpdateRole: hasPermission(UserPermissions.ROLE_COMPANY_UPDATE),
    hasCompanyDeleteRole: hasPermission(UserPermissions.ROLE_COMPANY_DELETE),
    hasCompanyUsersRole: hasPermission(UserPermissions.ROLE_COMPANY_USERS),
    hasCompanyDisableRole: hasPermission(UserPermissions.ROLE_COMPANY_DISABLE),
    hasCompanyEnableRole: hasPermission(UserPermissions.ROLE_COMPANY_ENABLE),
    hasCompanyUsersReadRole: hasPermission(
      UserPermissions.ROLE_COMPANY_USERS_READ,
    ),
    hasCompanyUsersAssignRole: hasPermission(
      UserPermissions.ROLE_COMPANY_USERS_ASSIGN,
    ),
    hasCompanyUsersUnassignRole: hasPermission(
      UserPermissions.ROLE_COMPANY_USERS_UNASSIGN,
    ),

    hasTemplateFsFileListRole: hasPermission(
      UserPermissions.ROLE_TEMPLATE_FS_FILE_LIST,
    ),
    hasTemplateFsUploadRole: hasPermission(
      UserPermissions.ROLE_TEMPLATE_FS_UPLOAD,
    ),
    hasTemplateFsCopyRole: hasPermission(UserPermissions.ROLE_TEMPLATE_FS_COPY),
    hasTemplateFsReadRole: hasPermission(UserPermissions.ROLE_TEMPLATE_FS_READ),
    hasTemplateFsDeleteRole: hasPermission(
      UserPermissions.ROLE_TEMPLATE_FS_DELETE,
    ),

    hasCompanyPermOvrListRole: hasPermission(
      UserPermissions.ROLE_COMPANY_PERM_OVR_LIST,
    ),
    hasCompanyPermOvrCreateRole: hasPermission(
      UserPermissions.ROLE_COMPANY_PERM_OVR_CREATE,
    ),
    hasCompanyPermOvrDeleteRole: hasPermission(
      UserPermissions.ROLE_COMPANY_PERM_OVR_DELETE,
    ),
    hasUserGlobalPermReadRole: hasPermission(
      UserPermissions.ROLE_USER_GLOBAL_PERM_READ,
    ),
    hasUserGlobalPermCreateRole: hasPermission(
      UserPermissions.ROLE_USER_GLOBAL_PERM_CREATE,
    ),
    hasUserGlobalPermDeleteRole: hasPermission(
      UserPermissions.ROLE_USER_GLOBAL_PERM_DELETE,
    ),
    hasGlobalPermOvrCreateRole: hasPermission(
      UserPermissions.ROLE_GLOBAL_PERM_OVR_CREATE,
    ),
    hasGlobalPermOvrDeleteRole: hasPermission(
      UserPermissions.ROLE_GLOBAL_PERM_OVR_DELETE,
    ),
    hasCompanyMicrositeReadRole: hasPermission(
      UserPermissions.ROLE_COMPANY_MICROSITE_READ,
    ),
    hasCompanyMicrositeCreateRole: hasPermission(
      UserPermissions.ROLE_COMPANY_MICROSITE_CREATE,
    ),
    hasCompanyMicrositeDeleteRole: hasPermission(
      UserPermissions.ROLE_COMPANY_MICROSITE_DELETE,
    ),

    // Global Labels
    hasG18nListRole: hasPermission(UserPermissions.ROLE_G18N_LIST),
    hasG18nStatRole: hasPermission(UserPermissions.ROLE_G18N_STAT),
    hasG18nCreateRole: hasPermission(UserPermissions.ROLE_G18N_CREATE),
    hasG18nUpdateRole: hasPermission(UserPermissions.ROLE_G18N_UPDATE),
    hasG18nDeleteRole: hasPermission(UserPermissions.ROLE_G18N_DELETE),

    // Global Public Storage
    hasPublicRootFsFileListRole: hasPermission(
      UserPermissions.ROLE_PUBLIC_ROOT_FS_FILE_LIST,
    ),
    hasPublicRootFsUploadRole: hasPermission(
      UserPermissions.ROLE_PUBLIC_ROOT_FS_UPLOAD,
    ),
    hasPublicRootFsEditRole: hasPermission(
      UserPermissions.ROLE_PUBLIC_ROOT_FS_EDIT,
    ),
    hasPublicRootFsCopyRole: hasPermission(
      UserPermissions.ROLE_PUBLIC_ROOT_FS_COPY,
    ),
    hasPublicRootFsReadRole: hasPermission(
      UserPermissions.ROLE_PUBLIC_ROOT_FS_READ,
    ),
    hasPublicRootFsDeleteRole: hasPermission(
      UserPermissions.ROLE_PUBLIC_ROOT_FS_DELETE,
    ),

    // Global Private Storage
    hasPrivateRootFsFileListRole: hasPermission(
      UserPermissions.ROLE_PRIVATE_ROOT_FS_FILE_LIST,
    ),
    hasPrivateRootFsUploadRole: hasPermission(
      UserPermissions.ROLE_PRIVATE_ROOT_FS_UPLOAD,
    ),
    hasPrivateRootFsEditRole: hasPermission(
      UserPermissions.ROLE_PRIVATE_ROOT_FS_EDIT,
    ),
    hasPrivateRootFsCopyRole: hasPermission(
      UserPermissions.ROLE_PRIVATE_ROOT_FS_COPY,
    ),
    hasPrivateRootFsReadRole: hasPermission(
      UserPermissions.ROLE_PRIVATE_ROOT_FS_READ,
    ),
    hasPrivateRootFsDeleteRole: hasPermission(
      UserPermissions.ROLE_PRIVATE_ROOT_FS_DELETE,
    ),

    // Company Public Storage
    hasPublicFsFileListRole: hasPermission(
      UserPermissions.ROLE_PUBLIC_FS_FILE_LIST,
    ),
    hasPublicFsUploadRole: hasPermission(UserPermissions.ROLE_PUBLIC_FS_UPLOAD),
    hasPublicFsEditRole: hasPermission(UserPermissions.ROLE_PUBLIC_FS_EDIT),
    hasPublicFsCopyRole: hasPermission(UserPermissions.ROLE_PUBLIC_FS_COPY),
    hasPublicFsReadRole: hasPermission(UserPermissions.ROLE_PUBLIC_FS_READ),
    hasPublicFsDeleteRole: hasPermission(UserPermissions.ROLE_PUBLIC_FS_DELETE),

    // Company Private Storage
    hasPrivateFsFileListRole: hasPermission(
      UserPermissions.ROLE_PRIVATE_FS_FILE_LIST,
    ),
    hasPrivateFsUploadRole: hasPermission(
      UserPermissions.ROLE_PRIVATE_FS_UPLOAD,
    ),
    hasPrivateFsEditRole: hasPermission(UserPermissions.ROLE_PRIVATE_FS_EDIT),
    hasPrivateFsCopyRole: hasPermission(UserPermissions.ROLE_PRIVATE_FS_COPY),
    hasPrivateFsReadRole: hasPermission(UserPermissions.ROLE_PRIVATE_FS_READ),
    hasPrivateFsDeleteRole: hasPermission(
      UserPermissions.ROLE_PRIVATE_FS_DELETE,
    ),

    // Computation
    hasCampaignComputationRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_COMPUTATION,
    ),
    hasCampaignComputationStopRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_COMPUTATION_STOP,
    ),
    hasCampaignEnvComputationRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_ENV_COMPUTATION,
    ),
    hasCampaignCcItemComputationRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_CC_ITEM_COMPUTATION,
    ),
    hasCampaignPhaseComputationRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_PHASE_COMPUTATION,
    ),
    hasCampaignStepComputationRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_STEP_COMPUTATION,
    ),

    // Env items
    hasCampaignEnvItemListRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_ENV_ITEM_LIST,
    ),
    hasCampaignEnvItemReadRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_ENV_ITEM_READ,
    ),
    hasCampaignEnvItemCreateRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_ENV_ITEM_CREATE,
    ),
    hasCampaignEnvItemUpdateRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_ENV_ITEM_UPDATE,
    ),
    hasCampaignEnvItemRenameRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_ENV_ITEM_RENAME,
    ),
    hasCampaignEnvItemLookupRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_ENV_ITEM_LOOKUP,
    ),
    hasCampaignEnvItemDeleteRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_ENV_ITEM_DELETE,
    ),
    hasCampaignEnvItemImportRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_ENV_ITEM_IMPORT,
    ),
    hasCampaignEnvItemExportRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_ENV_ITEM_EXPORT,
    ),

    // Campaign labels
    hasCampaignI18nListRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_I18N_LIST,
    ),
    hasCampaignI18nCreateRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_I18N_CREATE,
    ),
    hasCampaignI18nUpdateRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_I18N_UPDATE,
    ),
    hasCampaignI18nDeleteRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_I18N_DELETE,
    ),
    hasCampaignI18nGCreateRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_I18N_G_CREATE,
    ),
    hasCampaignI18nGReplaceRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_I18N_G_REPLACE,
    ),

    // Campaign
    hasCampaignListRole: hasPermission(UserPermissions.ROLE_CAMPAIGN_LIST),
    hasCampaignCreateRole: hasPermission(UserPermissions.ROLE_CAMPAIGN_CREATE),
    hasCampaignUpdateRole: hasPermission(UserPermissions.ROLE_CAMPAIGN_UPDATE),
    hasCampaignDeleteRole: hasPermission(UserPermissions.ROLE_CAMPAIGN_DELETE),
    hasCampaignArchiveRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_ARCHIVE,
    ),
    hasCampaignCloneRole: hasPermission(UserPermissions.ROLE_CAMPAIGN_CLONE),
    hasCampaignPublishRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_PUBLISH,
    ),
    hasCampaignUnpublishRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_UNPUBLISH,
    ),
    hasCampaignMicrositeDeployRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_MICROSITE_DEPLOY,
    ),
    hasCampaignStartRole: hasPermission(UserPermissions.ROLE_CAMPAIGN_START),
    hasCampaignReadRole: hasPermission(UserPermissions.ROLE_CAMPAIGN_READ),
    hasCampaignExportRole: hasPermission(UserPermissions.ROLE_CAMPAIGN_EXPORT),
    hasCampaignImportRole: hasPermission(UserPermissions.ROLE_CAMPAIGN_IMPORT),
    hasCampaignChangeCompanyRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_CHANGE_COMPANY,
    ),
    hasAiConfigurationManagerRole: hasPermission(
      UserPermissions.ROLE_AI_CONFIGURATION_MANAGER,
    ),
    hasCampaignUIShowGridParametersRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_UI_SHOW_GRID_PARAMETERS,
    ),
    hasCampaignTemplatesListRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_TEMPLATES_LIST,
    ),

    // Phase
    hasPhaseListRole: hasPermission(UserPermissions.ROLE_PHASE_LIST),
    hasPhaseCreateRole: hasPermission(UserPermissions.ROLE_PHASE_CREATE),
    hasPhaseUpdateRole: hasPermission(UserPermissions.ROLE_PHASE_UPDATE),
    hasPhaseDeleteRole: hasPermission(UserPermissions.ROLE_PHASE_DELETE),
    hasPhaseMoveRole: hasPermission(UserPermissions.ROLE_PHASE_MOVE),
    hasPhaseCloneRole: hasPermission(UserPermissions.ROLE_PHASE_CLONE),
    hasPhaseDeployRole: hasPermission(UserPermissions.ROLE_PHASE_DEPLOY),
    hasPhaseExportRole: hasPermission(UserPermissions.ROLE_PHASE_EXPORT),
    hasPhaseCopyRole: hasPermission(UserPermissions.ROLE_PHASE_COPY),
    hasPhaseShowHiddenRole: hasPermission(
      UserPermissions.ROLE_PHASE_SHOW_HIDDEN,
    ),

    // Step
    hasStepListRole: hasPermission(UserPermissions.ROLE_STEP_LIST),
    hasStepCreateRole: hasPermission(UserPermissions.ROLE_STEP_CREATE),
    hasStepUpdateRole: hasPermission(UserPermissions.ROLE_STEP_UPDATE),
    hasStepDeleteRole: hasPermission(UserPermissions.ROLE_STEP_DELETE),
    hasStepMoveRole: hasPermission(UserPermissions.ROLE_STEP_MOVE),
    hasStepCloneRole: hasPermission(UserPermissions.ROLE_STEP_CLONE),
    hasStepDeployRole: hasPermission(UserPermissions.ROLE_STEP_DEPLOY),
    hasStepExportRole: hasPermission(UserPermissions.ROLE_STEP_EXPORT),
    hasStepCopyRole: hasPermission(UserPermissions.ROLE_STEP_COPY),
    hasStepShowHiddenRole: hasPermission(UserPermissions.ROLE_STEP_SHOW_HIDDEN),
    hasStepShowDocViewRole: hasPermission(
      UserPermissions.ROLE_STEP_SHOW_DOC_VIEW,
    ),
    hasStepTemplatePreviewRole: hasPermission(
      UserPermissions.ROLE_STEP_TEMPLATE_PREVIEW,
    ),

    // CC items
    hasCampaignCcListRole: hasPermission(UserPermissions.ROLE_CAMPAIGN_CC_LIST),
    hasCampaignCcReadRole: hasPermission(UserPermissions.ROLE_CAMPAIGN_CC_READ),
    hasCampaignCcGraphRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_CC_GRAPH,
    ),
    hasCampaignCcCreateRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_CC_CREATE,
    ),
    hasCampaignCcUpdateRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_CC_UPDATE,
    ),
    hasCampaignCcRenameRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_CC_RENAME,
    ),
    hasCampaignCcDeleteRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_CC_DELETE,
    ),
    hasCampaignCcCloneRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_CC_CLONE,
    ),
    hasCampaignCcOverrideRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_CC_OVERRIDE,
    ),
    hasCampaignCcUpdateStateRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_CC_UPDATE_STATE,
    ),
    hasCampaignCcSwapRole: hasPermission(UserPermissions.ROLE_CAMPAIGN_CC_SWAP),
    hasCampaignUICCGetReferenceRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_UI_CC_GET_REFERENCE,
    ),
    hasCampaignUICCPromptModificationRole: hasPermission(
      UserPermissions.ROLE_CAMPAIGN_UI_CC_PROMPT_MODIFICATION,
    ),

    // Template Classes
    hasDictionarySearchRole: hasPermission(
      UserPermissions.ROLE_DICTIONARY_SEARCH,
    ),
    hasDictionaryCreateRole: hasPermission(
      UserPermissions.ROLE_DICTIONARY_CREATE,
    ),
    hasDictionaryDeleteRole: hasPermission(
      UserPermissions.ROLE_DICTIONARY_DELETE,
    ),

    // Assets
    hasAssetListRole: hasPermission(UserPermissions.ROLE_ASSET_LIST),
    hasAssetCreateRole: hasPermission(UserPermissions.ROLE_ASSET_CREATE),
    hasAssetUpdateRole: hasPermission(UserPermissions.ROLE_ASSET_UPDATE),
    hasAssetReadRole: hasPermission(UserPermissions.ROLE_ASSET_READ),
    hasAssetDeleteRole: hasPermission(UserPermissions.ROLE_ASSET_DELETE),
    hasAssetApproveRole: hasPermission(UserPermissions.ROLE_ASSET_APPROVE),
    hasAssetApprovePubRole: hasPermission(
      UserPermissions.ROLE_ASSET_APPROVE_PUB,
    ),
    hasAssetDeployRole: hasPermission(UserPermissions.ROLE_ASSET_DEPLOY),
    hasAssetUIGetPubAWSLink: hasPermission(
      UserPermissions.ROLE_ASSET_UI_GET_PUB_AWS_LINK,
    ),

    // Only for UI
    hasUiBuildInfoRole: hasPermission(UserPermissions.ROLE_UI_BUILD_INFO),
    hasUiShowDetailedPermissionsListRole: hasPermission(
      UserPermissions.ROLE_UI_SHOW_DETAILED_PERMISSIONS_LIST,
    ),

    hasPermissionAnywhere,
    hasPermission,
  };
};
