import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TLabelLanguage, TLabels } from "../../globalTypes";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import {
  bulkDeleteCampaignLabelsApi,
  createCampaignLabelApi,
  getCampaignLabelsApi,
  updateCampaignLabelApi,
} from "../../api/campaign-labels.api";

const initialState = {
  labels: {} as TLabels,
};

type InitialStateType = typeof initialState;

const campaignLabelsSlice = createSlice({
  name: "campaignLabels",
  initialState,
  reducers: {
    setCampaignLabels: (
      state: InitialStateType,
      action: PayloadAction<TLabels>,
    ) => {
      state.labels = action.payload;
    },
    addCampaignLabel: (
      state: InitialStateType,
      action: PayloadAction<{ key: string; value: string }>,
    ) => {
      const { key, value } = action.payload;

      state.labels[key] = value;
    },
    deleteCampaignLabels: (
      state: InitialStateType,
      action: PayloadAction<Array<string>>,
    ) => {
      action.payload.forEach((key) => {
        delete state.labels[key];
      });
    },
  },
});

export const { setCampaignLabels, addCampaignLabel, deleteCampaignLabels } =
  campaignLabelsSlice.actions;

export default campaignLabelsSlice.reducer;

/* eslint-disable*/
export const getCampaignLabels = (): TLabels =>
  useSelector((state: RootState) => state.campaignLabels.labels);

type TSaveLabelProps = {
  label: string;
  key: string;
  lang: TLabelLanguage;
  action: "create" | "update";
  campaignId: string;
};

export const saveCampaignLabelThunk = createAsyncThunk<
  undefined,
  TSaveLabelProps,
  { rejectValue: TCustomError }
>("campaignLabels/saveLabel", async (props, { rejectWithValue, dispatch }) => {
  try {
    const { label, lang, key, action, campaignId } = props;

    if (action === "create") {
      await createCampaignLabelApi({
        campaignId,
        label,
        key,
        lang,
      });
    } else {
      await updateCampaignLabelApi({
        campaignId,
        label,
        key,
        lang,
      });
    }

    dispatch(addCampaignLabel({ key, value: label }));
  } catch (e: any) {
    const customError = handleRequestError(e);
    console.error(`An error occurred while trying to save campaign label:`, e);

    return rejectWithValue(customError);
  }
});

type TDeleteLabelsProps = {
  keys: Array<string>;
  lang: TLabelLanguage;
  campaignId: string;
};

export const deleteCampaignLabelsThunk = createAsyncThunk<
  undefined,
  TDeleteLabelsProps,
  { rejectValue: TCustomError }
>(
  "campaignLabels/deleteLabels",
  async (props, { rejectWithValue, dispatch }) => {
    try {
      const { keys, lang, campaignId } = props;

      await bulkDeleteCampaignLabelsApi({
        campaignId,
        keys,
        lang,
      });

      dispatch(deleteCampaignLabels(keys));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to delete campaign labels:`,
        e,
      );

      return rejectWithValue(customError);
    }
  },
);

export const getCampaignLabelsThunk = createAsyncThunk<
  undefined,
  number,
  { rejectValue: TCustomError }
>(
  "campaignLabels/getLabelsAndOpenModal",
  async (campaignId, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getCampaignLabelsApi({ campaignId, lang: "en" });

      dispatch(setCampaignLabels(data));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to get campaign labels:`,
        e,
      );
      dispatch(setCampaignLabels({}));
      return rejectWithValue(customError);
    }
  },
);
