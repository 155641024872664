import { Form, Input, InputRef, Modal, Typography } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import useSubmitFormOnEnter from "src/hooks/useSubmitFormOnEnter";
import { CopyFileParams } from "src/types";
import submitFormWithTrim from "src/utils/submitFormWithTrim";
import { TStorageFileItem } from "../../../globalTypes";
import { minMax } from "../../../utils/validations";
import SelectStorageItems from "../SelectStorageItems/SelectStorageItems";
import customizeFormLabels from "../../../utils/customizeFormLabels";

const { Text } = Typography;

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedPath: string;
  onCopy: (props: CopyFileParams) => Promise<void>;
  getFolderFiles: (path?: string) => Promise<TStorageFileItem[]>;
  pathPrefix: string;
};

type CopyFileFormValues = {
  newName: string;
  pathCopy: string;
};

export const CopyFileModal: FC<Props> = ({
  open,
  setOpen,
  selectedPath,
  onCopy,
  getFolderFiles,
  pathPrefix,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [form] = Form.useForm<CopyFileFormValues>();
  const pathCopy = Form.useWatch<string | undefined>("pathCopy", form);
  const inputTagRef = useRef<InputRef>(null);
  const splittedPath = selectedPath.split("/");
  const originFileName = splittedPath.pop();
  const originPath = splittedPath.join("/") + "/";

  useSubmitFormOnEnter(() => submitForm(), { condition: !isFetching });
  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus({
        cursor: "end",
      });
    }
  }, []);

  const formatPath = (path: string) => {
    const trimmedPath = path.startsWith(pathPrefix)
      ? path.slice(pathPrefix.length)
      : path;

    const parts = trimmedPath.split("/").filter(Boolean);

    const formattedParts = parts.map((part) => {
      if (part.startsWith("ni-")) {
        part = part.slice(3);
      }
      const lastDashIndex = part.lastIndexOf("-");
      return lastDashIndex !== -1 ? part.slice(0, lastDashIndex) : part;
    });

    return formattedParts.join("/") + "/";
  };

  const filteredSelectedPath = formatPath(pathCopy || "");

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: CopyFileFormValues) => {
      setIsFetching(true);

      await onCopy({
        sourcePath: selectedPath,
        targetPath: `${values.pathCopy}${values.newName}`,
      });

      setIsFetching(false);
      setOpen(false);
    },
  });

  return (
    <Modal
      title="Copy File"
      open={open}
      onOk={submitForm}
      okButtonProps={{ disabled: isFetching, loading: isFetching }}
      onCancel={() => setOpen(false)}
      className="w-[700px]"
    >
      <p>
        You're about to copy <Text code>{originFileName}</Text> file to the{" "}
        <Text code>{filteredSelectedPath}</Text> folder.
      </p>
      <Form
        form={form}
        layout="vertical"
        requiredMark={customizeFormLabels}
        style={{ marginTop: 12 }}
        initialValues={{ newName: originFileName, pathCopy: originPath }}
      >
        <Form.Item
          name="newName"
          label="New File Name"
          tooltip="Only letters, numbers, minus signs and underscores are allowed"
          rules={[
            minMax({ max: 255, text: "File name" }),
            { required: true, message: "Required field!" },
          ]}
        >
          <Input ref={inputTagRef} placeholder="Enter new file name" />
        </Form.Item>
        <Form.Item name="pathCopy" label="Choose folder to copy">
          <SelectStorageItems
            selectType="FOLDER"
            pathPrefix={pathPrefix}
            getFolderFiles={getFolderFiles}
            onSelect={(value) => form.setFieldValue("pathCopy", value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
