import clsx from "clsx";
import { Handle, Position } from "reactflow";
import { TComputationState } from "../../store/slices/ccVariablesSlice";
import StateIcon from "./StateIcon";
import MenuDropdown, { MenuDropdownItem } from "../common/MenuDropdown";
import { Button } from "../common/ui/button";
import { Icons } from "../common/Icons";
import React, { FC } from "react";

export type NodeProps = {
  label: string;
  touched?: boolean;
  state?: TComputationState;
  hasOverride?: boolean;
  nodeMenuItems?: MenuDropdownItem[];
};

type Props = {
  //set data props for each node in useGetNodes hook
  data: NodeProps;
};

export const DefaultNode: FC<Props> = ({ data }) => {
  const { label, touched, state, hasOverride, nodeMenuItems } = data;
  const isNodeMenuEmpty = !nodeMenuItems?.length;

  return (
    <div
      className={clsx("w-full h-full pl-[8px]", {
        "bg-[#eab308] bg-opacity-30": hasOverride,
      })}
    >
      <Handle
        className="!left-[-1px] opacity-0"
        type="target"
        position={Position.Left}
        isConnectable={false}
      />

      <div className="relative w-full h-full flex justify-between items-center">
        <div className="absolute -top-[9px] -left-[29px]">
          <StateIcon state={state} />
        </div>
        <div
          className={clsx(
            "w-[85%] text-[#475569] text-xs overflow-hidden text-ellipsis whitespace-nowrap",
            {
              "underline !text-yellow-600 text-bold": touched,
              "!text-[#967206]": hasOverride,
              "!w-full": isNodeMenuEmpty,
            },
          )}
        >
          {label}
        </div>

        {!isNodeMenuEmpty && nodeMenuItems && (
          <div className="absolute top-[6px] right-[4px]">
            <MenuDropdown items={nodeMenuItems} align="end">
              <Button
                variant={"ghostPrimary"}
                className="p-[1px] size-[25px]"
                onClick={(e) => e.stopPropagation()}
              >
                <Icons.MoreDots className="size-[20px]" />
              </Button>
            </MenuDropdown>
          </div>
        )}
      </div>

      <Handle
        className="!right-[-2px] opacity-0"
        type="source"
        position={Position.Right}
        isConnectable={false}
      />
    </div>
  );
};

export const GroupNode = ({ data }: any) => {
  return (
    <div className="absolute">
      <strong className="relative top-[-5px] text-info">{data.label}</strong>
    </div>
  );
};
