import { ExclamationTriangleIcon } from "@heroicons/react/16/solid";
import { Select, Tooltip } from "antd";
import { FC } from "react";
import { ITemplateOption } from "src/globalTypes";
import { tooltipTitle } from "./TooltipTitle";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";

type Props = {
  options?: ITemplateOption[];
  isTemplatesLoading: boolean;
  currentTemplateId?: number;
  setSelectedTemplate: (id: number) => void;
  selectedOption?: ITemplateOption;
  isVeTemplate: boolean;
};
export const DocViewSelector: FC<Props> = ({
  options,
  isTemplatesLoading,
  currentTemplateId,
  setSelectedTemplate,
  selectedOption,
  isVeTemplate,
}) => {
  const { companyId } = useParams();
  const { hasDocumentTemplateListRole } = useUserHasPermission({ companyId });
  const isDataLoaded =
    typeof selectedOption?.info.foundKeys === "number" &&
    typeof selectedOption?.info.totalKeys === "number" &&
    typeof selectedOption?.info.mediaType === "string";

  return (
    <>
      <Tooltip
        title={tooltipTitle({
          display: options?.length === 0,
          hasDocumentTemplateListRole,
        })}
        color="white"
      >
        <div className="flex items-center gap-2">
          <Select<number, ITemplateOption>
            options={options}
            loading={isTemplatesLoading}
            value={isTemplatesLoading ? undefined : currentTemplateId}
            onChange={setSelectedTemplate}
            placeholder="Choose template"
            optionRender={(option) => {
              return (
                <div className="inline-flex">
                  {option.data.info.name}
                  <div className="inline-flex text-slate-900 bg-teal-200 rounded-md px-1 ml-1">
                    {option.data.info.foundKeys}/{option.data.info.totalKeys}
                  </div>
                  <div className="inline-flex text-slate-900 bg-sky-200 rounded-md px-1 mx-1">
                    {option.data.info.mediaType.split("/")[1].toUpperCase()}
                  </div>
                </div>
              );
            }}
            className="w-[200px]"
            style={{
              border: "black",
            }}
            dropdownStyle={{
              maxHeight: "300px",
              minWidth: "360px",
              overflow: "auto",
            }}
          />
          {isDataLoaded && (
            <>
              <div className="flex items-center gap-2">
                <Tooltip
                  title={`You have ${selectedOption?.info.foundKeys} of ${selectedOption?.info.totalKeys} keys added`}
                >
                  <div className="text-slate-900 bg-teal-200 rounded-md px-1">
                    {selectedOption?.info.foundKeys}/
                    {selectedOption?.info.totalKeys}
                  </div>
                </Tooltip>
              </div>
              <Tooltip title={selectedOption?.info.mediaType}>
                <div className="inline-flex text-slate-900 bg-sky-200 rounded-md px-1">
                  {selectedOption?.info.mediaType.split("/")[1].toUpperCase()}
                </div>
              </Tooltip>
            </>
          )}
        </div>
      </Tooltip>
      {!isVeTemplate && (
        <Tooltip
          title={
            <span>
              <b>Warning!</b> This is not VE template, you can't edit it
            </span>
          }
        >
          <ExclamationTriangleIcon className="size-4 text-yellow-600" />
        </Tooltip>
      )}
    </>
  );
};
