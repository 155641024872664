import { TSocketMsg, TSocketMsgType } from "../types";
import { format } from "date-fns";
import processEventMessages from "./processEventMessages";
import { bulkUpdateCcVariables } from "../../ccVariablesSlice";
import {
  addBulkComputationMessages,
  addSocket,
  removeSocket,
  resetComputationMessages,
  setComputationInfo,
  setSocketShouldReconnect,
} from "../slice";
import parseMessageDataFromWS from "./parseMessageDataFromWS";
import { PING_INTERVAL, PONG_TIMEOUT } from "../constants";
import { RootState } from "../../../store";

type Props = {
  url: string;
  shouldUpdateCurrentStepCcItems?: boolean;
  campaignId: number;
  dispatch: any;
  getState: () => RootState;
};

export const initializeWebsocket = ({
  url,
  shouldUpdateCurrentStepCcItems,
  dispatch,
  campaignId,
  getState,
}: Props) => {
  const ws = new WebSocket(url);
  const messageQueue: TSocketMsg<TSocketMsgType>[][] = [];
  let isProcessing = false;
  let pingInterval: number;
  let pongTimeout: number | null = null;

  const startPing = () => {
    pingInterval = window.setInterval(() => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: "ACK", message: "ping" }));

        // Set a wait timer for pong
        if (pongTimeout) {
          clearTimeout(pongTimeout);
        }

        pongTimeout = window.setTimeout(() => {
          console.error("Pong not received. Closing socket.");

          ws.close(4000, "No pong response within timeout");
        }, PONG_TIMEOUT); // Close the connection if pong is not received after PONG_TIMEOUT ms
      }
    }, PING_INTERVAL); // Send a ping every PING_INTERVAL ms
  };

  const stopPing = () => {
    clearInterval(pingInterval);
    if (pongTimeout) {
      clearTimeout(pongTimeout);
    }
  };

  ws.onopen = async function () {
    startPing();

    dispatch(addSocket({ campaignId, socket: ws }));
    dispatch(setSocketShouldReconnect(false));

    const openTime = format(Date.now(), "yyyy-MM-dd HH:mm:ss.SSS");

    console.log(
      `Socket connected. campaignId: ${campaignId}, openTime: ${openTime}`,
    );
  };

  ws.onmessage = async function ({ data }) {
    if (typeof data === "string") {
      try {
        const message = JSON.parse(data);

        if (message?.type === "ACK" && message?.message === "pong") {
          // If a pong message is received, reset the timer
          if (pongTimeout) {
            clearTimeout(pongTimeout);
          }
        }
      } catch (e) {
        console.warn("Error parsing string to JSON: ", data);
      }
    }

    const messages = await parseMessageDataFromWS(data);

    if (!messages.length) return;

    let currentStepId: number | undefined = undefined;

    if (shouldUpdateCurrentStepCcItems !== undefined) {
      const {
        steps: { current },
      } = getState();
      if (current) {
        currentStepId = current.id;
      }
    }

    const processData = async (newMessages: TSocketMsg<TSocketMsgType>[]) => {
      isProcessing = true;

      const { eventMessagesMap, itemsToUpdate, computationInfo } =
        processEventMessages({
          messages: newMessages,
          currentStepId,
        });

      if (computationInfo) {
        dispatch(setComputationInfo(computationInfo));
      }

      if (itemsToUpdate.length) {
        dispatch(bulkUpdateCcVariables(itemsToUpdate));
      }

      if (eventMessagesMap) {
        dispatch(addBulkComputationMessages(eventMessagesMap));
      }

      isProcessing = false;

      // If there is data in the queue - process the following element
      if (messageQueue.length > 0) {
        const nextMessages = messageQueue.shift(); // Extracting some of the data from the beginning of the queue
        if (nextMessages) {
          processData(nextMessages);
        }
      }
    };

    if (isProcessing) {
      messageQueue.push(messages);
    } else {
      processData(messages);
    }
  };

  ws.onclose = async function ({ wasClean, code }) {
    stopPing();

    dispatch(removeSocket(campaignId));

    // if ws.close(4000) - need to reconnect
    if (!wasClean || code === 4000) {
      dispatch(setSocketShouldReconnect(true));
    } else {
      dispatch(setSocketShouldReconnect(false));
      dispatch(setComputationInfo(null));
      dispatch(resetComputationMessages());

      const disconnectTime = format(Date.now(), "yyyy-MM-dd HH:mm:ss.SSS");

      console.log(
        `Socket disconnected. campaignId: ${campaignId}, disconnectTime: ${disconnectTime}`,
      );
    }
  };

  ws.onerror = function () {
    stopPing();

    dispatch(removeSocket(campaignId));
    dispatch(setSocketShouldReconnect(true));
  };
};
