import { Spin } from "antd";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import {
  deleteAssetApi,
  getAssetApi,
  publishAssetApi,
  toggleApproveAssetApi,
  toggleApprovePublishApi,
} from "../../api/assets.api";
import { TAsset } from "../../globalTypes";
import { selectMessageApi } from "../../store/slices/appSlice";
import handleRequestError from "../../utils/handleRequestError";
import { Table, TableBody } from "../common/ui/table";
import { TableHeader } from "../table/Header";
import AssetListItem from "./AssetListItem";
import { EmptyResult } from "../common/EmptyResult";

type Props = {
  assets: TAsset[];
  campaignId: string;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditAsset: React.Dispatch<React.SetStateAction<TAsset | null>>;
  loadAndSetAssets: () => Promise<void>;
};

const headerClassName = "font-sans font-bold text-[#0F172A]";
const tableHeaderItems = [
  {
    title: "Name",
    className: headerClassName,
  },
  {
    title: "Microsite Context Folder",
    className: headerClassName,
  },
  {
    title: "Asset approved",
    className: headerClassName,
  },
  {
    title: "Publish approved",
    className: headerClassName,
  },
  {
    title: "Publish date",
    className: headerClassName,
  },
  null,
];

const AssetsList: FC<Props> = ({
  assets,
  campaignId,
  setIsModalOpen,
  setEditAsset,
  loadAndSetAssets,
}) => {
  const messageApi = useSelector(selectMessageApi);
  const [isFetching, setIsFetching] = useState(false);

  const onApproveAsset = async (assetId: number, approved: boolean) => {
    try {
      setIsFetching(true);
      await toggleApproveAssetApi({
        campaignId,
        assetId,
        approved,
      });

      await loadAndSetAssets();

      messageApi.success("The status has been changed");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onDeleteAsset = async (assetId: number, removeFiles?: boolean) => {
    try {
      setIsFetching(true);
      await deleteAssetApi({
        campaignId,
        assetId,
        removeFiles,
      });

      await loadAndSetAssets();

      messageApi.success("The asset has been deleted");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onEditAsset = async (asset: TAsset) => {
    try {
      setIsFetching(true);

      const { data } = await getAssetApi({
        campaignId,
        assetId: asset.id,
      });

      setIsModalOpen(true);
      setEditAsset(data);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onApprovePublish = async (
    selectedAsset: TAsset,
    publishToMicroSite: boolean,
  ) => {
    try {
      setIsFetching(true);
      if (!selectedAsset.microSiteTargetFolder && publishToMicroSite) {
        messageApi.error(`Publishing path not defined`);
        return;
      }

      await toggleApprovePublishApi({
        campaignId,
        assetId: selectedAsset.id,
        publishToMicroSite,
      });

      await loadAndSetAssets();

      messageApi.success("The publish status has been changed");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };
  const onPublishAsset = async (assetId: number) => {
    try {
      setIsFetching(true);

      await publishAssetApi({
        campaignId,
        assetId,
      });

      await loadAndSetAssets();

      messageApi.success("The asset has been published");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Spin spinning={isFetching}>
      {!assets.length ? (
        <EmptyResult message="There are no assets in this step." />
      ) : (
        <div className="rounded-md border w-full mx-auto">
          <Table>
            <TableHeader items={tableHeaderItems} />
            <TableBody>
              {assets.map((asset) => (
                <AssetListItem
                  key={asset.id}
                  onApprovePublish={onApprovePublish}
                  onApproveAsset={onApproveAsset}
                  onEditAsset={onEditAsset}
                  onPublishAsset={onPublishAsset}
                  onDeleteAsset={onDeleteAsset}
                  asset={asset}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </Spin>
  );
};

export default AssetsList;
