import { Link, useLocation } from "react-router-dom";
import UserAvatar from "../common/Avatar";
import { useSelector } from "react-redux";
import { selectUserData } from "../../store/slices/userData/slice";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { Menu, MenuProps } from "antd";
import clsx from "clsx";
import "./Header.scss";
import ReleasesInfo from "../ReleasesInfo/ReleasesInfo";
import { UserPermissions } from "../../store/slices/userData/constants";
import React from "react";

type HeaderNavItem = {
  label: string;
  active: boolean;
  path: string;
};

const Header = () => {
  const { pathname } = useLocation();
  const user = useSelector(selectUserData);
  const {
    hasDocumentTemplateListRole,
    hasAiConfigurationListRole,
    hasPermGroupListRole,
    hasPermissionAnywhere,
    hasCampaignTemplatesListRole,
  } = useUserHasPermission({});

  const navDataList = [
    hasPermissionAnywhere(UserPermissions.ROLE_COMPANY_LIST) && {
      label: "Companies",
      active: pathname.startsWith("/companies"),
      path: "/companies",
    },
    hasPermissionAnywhere(UserPermissions.ROLE_USER_LIST) && {
      label: "Users",
      active: pathname.startsWith("/users"),
      path: "/users",
    },
    hasPermissionAnywhere(UserPermissions.ROLE_CAMPAIGN_LIST) && {
      label: "Campaigns",
      active: pathname.startsWith("/campaigns"),
      path: "/campaigns",
    },
    (hasDocumentTemplateListRole || hasCampaignTemplatesListRole) && {
      label: "Templates",
      active: pathname.startsWith("/templates/"),
      path: hasDocumentTemplateListRole
        ? "/templates/documents"
        : "/templates/campaigns",
    },
    hasAiConfigurationListRole && {
      label: "Model Configs",
      active: pathname.startsWith("/model-configs"),
      path: "/model-configs",
    },
    hasPermGroupListRole && {
      label: "Security",
      active: pathname.startsWith("/security-groups"),
      path: "/security-groups",
    },
  ].filter(Boolean) as HeaderNavItem[];

  const menuItems: MenuProps["items"] = [
    ...navDataList.map(({ path, label, active }) => ({
      key: path,
      label: (
        <Link
          to={path}
          className={clsx(
            {
              "!text-vePrimary": active,
            },
            "text-slate-700 text-sm font-semibold leading-[14px]",
          )}
        >
          {label}
        </Link>
      ),
      style: {
        display: "flex",
        alignItems: "center",
      },
    })),
  ];

  const activeNavKeys = navDataList
    .filter((item) => item.active)
    .map((item) => item.path);

  return (
    <div className="w-full h-[64px] flex items-center border-b border-[#E2E8F0] font-sans justify-between px-[48px]">
      <div className="w-full flex gap-2 items-center">
        <img src={"/logo.svg"} alt="App logo" className="h-[24px]" />
        <Menu
          mode="horizontal"
          selectedKeys={activeNavKeys}
          items={menuItems}
          style={{
            height: "64px",
            border: "none",
            width: "70%",
          }}
          className="custom-menu flex gap-3"
        />
      </div>

      <div className="flex gap-[24px] items-center">
        <UserAvatar user={user} />
        <ReleasesInfo />
      </div>
    </div>
  );
};

export default Header;
