import { SlidersOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { computationApi } from "src/api/computation.api";
import {
  ArrowTopRightOnSquareIcon,
  CloneIcon,
  DeleteIcon,
  GlobeIcon,
  Icons,
  LabelsIcon,
  Square2StackIcon,
  SquaresPlusIcon,
  StorageIcon,
} from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { ChangeCampaignsCompanyModal } from "src/components/common/modals/ChangeCampaignsCompanyModal";
import { Button } from "src/components/common/ui/button";
import { ComputationType, START_PUBLISHING_TEXT_MESSAGE } from "src/constants";
import useModals from "src/hooks/useModals";
import {
  cloneCampaignApi,
  deleteCampaignApi,
  publishCampaignToMicroSiteApi,
} from "../../../api/campaigns.api";
import { TPhaseFormValues } from "../../../globalTypes";
import useConfirm from "../../../hooks/useConfirm";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { TCampaign } from "../../../store/slices/campaignsSlice";
import { getEnvVariablesWithTypesThunk } from "../../../store/slices/envVariablesSlice";
import {
  getCurrentPhase,
  savePhaseThunk,
} from "../../../store/slices/phasesSlice";
import { saveStepThunk } from "../../../store/slices/stepsSlice";
import { AppDispatch } from "../../../store/store";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { TStepFormValues } from "../../campaignSteps/CampaignSteps/CampaignSteps";
import CloneEntityModal, {
  TCloneEntityFormValues,
} from "../../common/modals/CloneEntityModal/CloneEntityModal";
import DeleteWithFilesModal from "../../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import PhaseModal from "../../common/modals/PhaseModal/PhaseModal";
import StepModal from "../../common/modals/StepModal/StepModal";
import EditCampaignModal from "../EditCampaignModal/EditCampaignModal";
import ExportCampaignModal from "../ExportCampaignModal/ExportCampaignModal";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useGoBack } from "../../../hooks/useGoBack";
import { resetComputationMessages } from "../../../store/slices/computationMessages/slice";
import useNavigateWithOutletRedirect from "../../../hooks/navigation/useNavigateWithOutletRedirect";
import ClonedWithErrorsMessage from "../../common/ClonedWithErrorsMessage";

type Props = {
  companyId: number;
  currentCampaign: TCampaign;
};

const Settings: FC<Props> = ({ currentCampaign, companyId }) => {
  const { id: campaignId, name: campaignName } = currentCampaign;
  const dispatch: AppDispatch = useDispatch();
  const currentPhase = getCurrentPhase();
  const navigate = useNavigate();
  const goBack = useGoBack("/campaigns");
  const messageApi = useSelector(selectMessageApi);
  const confirm = useConfirm();
  const { execute, modal } = useModals();
  const [addPhaseForm] = Form.useForm<TPhaseFormValues>();
  const [addStepForm] = Form.useForm<TStepFormValues>();
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);
  const [isAddPhaseModalOpen, setIsAddPhaseModalOpen] = useState(false);
  const [isAddStepModalOpen, setIsAddStepModalOpen] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isChangeCompanyModalOpen, setIsChangeCompanyModalOpen] =
    useState(false);
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();
  const {
    hasCampaignComputationRole,
    hasCampaignEnvItemListRole,
    hasCampaignI18nListRole,
    hasCampaignUpdateRole,
    hasCampaignExportRole,
    hasCampaignCloneRole,
    hasPhaseCreateRole,
    hasStepCreateRole,
    hasCampaignMicrositeDeployRole,
    hasCampaignChangeCompanyRole,
    hasCampaignDeleteRole,
  } = useUserHasPermission({ companyId });

  const onOpenVars = async () => {
    try {
      await dispatch(getEnvVariablesWithTypesThunk()).unwrap();
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onDelete = async (removeFiles: boolean) => {
    try {
      await deleteCampaignApi({ campaignId, removeFiles });

      setIsDeleteModalOpen(false);

      goBack();
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onPublishCampaignToMicroSite = async () => {
    try {
      await publishCampaignToMicroSiteApi({ campaignId });

      messageApi.success(START_PUBLISHING_TEXT_MESSAGE);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onClone = async (values: TCloneEntityFormValues) => {
    try {
      const { data } = await cloneCampaignApi({
        campaignId,
        ...values,
      });

      setIsCloneModalOpen(false);

      navigate(`/campaigns/company/${companyId}/campaign/${data.campaign.id}`);

      if (data.errorMessages.length) {
        messageApi.warning(
          <ClonedWithErrorsMessage errorMessages={data.errorMessages} />,
        );
      } else {
        messageApi.success("The campaign has been successfully cloned");
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error("An error occurred while trying to clone the campaign:", e);
    }
  };

  async function onCreatePhase({
    name,
    hidden,
    microSiteContextFolder,
  }: TPhaseFormValues) {
    try {
      const newPhase = await dispatch(
        savePhaseThunk({
          phaseName: name,
          hidden,
          microSiteContextFolder,
          addType: "AFTER",
        }),
      ).unwrap();

      onAddPhaseModalCancel();

      navigate(
        `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${newPhase.id}`,
        { replace: true },
      );

      messageApi.success("The phase has been successfully created");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  }

  const onAddPhaseModalCancel = () => {
    addPhaseForm.resetFields();
    setIsAddPhaseModalOpen(false);
  };

  const onSubmitAddPhaseForm = submitFormWithTrim({
    form: addPhaseForm,
    onSuccessValidationCb: async (values: TPhaseFormValues) => {
      await onCreatePhase(values);
    },
  });

  async function onCreateStep(values: TStepFormValues) {
    try {
      const data = await dispatch(
        saveStepThunk({
          phaseId: currentPhase!.id,
          stepName: values.name,
          hidden: values.hidden,
          microSiteContextFolder: values.microSiteContextFolder,
          classes: values.classes,
          addType: "AFTER",
        }),
      ).unwrap();

      const { phaseId, stepId } = data;

      onAddStepModalCancel();
      navigate(
        `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}`,
        { replace: true },
      );
      messageApi.success("The step has been successfully created");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  }

  const onAddStepModalCancel = () => {
    addStepForm.resetFields();
    setIsAddStepModalOpen(false);
  };

  const onSubmitAddStepForm = submitFormWithTrim({
    form: addStepForm,
    onSuccessValidationCb: async (values: TStepFormValues) => {
      await onCreateStep(values);
    },
  });

  const settingsItems = [
    hasCampaignEnvItemListRole && {
      key: "1",
      label: "Environment variables",
      icon: SlidersOutlined,
      onClick: onOpenVars,
    },
    hasCampaignI18nListRole && {
      key: "2",
      label: "Labels",
      icon: LabelsIcon,
      onClick: () =>
        navigate(
          `/campaigns/company/${companyId}/campaign/${campaignId}/labels`,
        ),
    },
    {
      key: "3",
      label: "Storage",
      icon: StorageIcon,
      onClick: () =>
        navigateWithOutletRedirect(
          `/campaigns/company/${companyId}/campaign/${campaignId}/storage`,
        ),
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const actionsItems = [
    hasCampaignUpdateRole && {
      key: "0",
      label: "Edit Campaign",
      icon: Icons.edit,
      onClick: () => setIsEditModalOpen(true),
    },
    hasCampaignExportRole && {
      key: "1",
      label: "Export Campaign",
      icon: ArrowTopRightOnSquareIcon,
      onClick: () => setIsOpenExportModal(true),
    },
    hasCampaignCloneRole && {
      key: "2",
      label: "Clone Campaign",
      icon: CloneIcon,
      onClick: () => setIsCloneModalOpen(true),
    },
    hasPhaseCreateRole && {
      key: "3",
      label: "Add Phase",
      icon: Square2StackIcon,
      onClick: () => setIsAddPhaseModalOpen(true),
    },
    hasStepCreateRole && {
      key: "4",
      label: "Add Step",
      disabled: !currentPhase,
      icon: SquaresPlusIcon,
      onClick: () => setIsAddStepModalOpen(true),
    },
    hasCampaignMicrositeDeployRole &&
      companyId !== undefined && {
        label: "Micro Site Publish",
        key: "5",
        onClick: () =>
          confirm({
            action: onPublishCampaignToMicroSite,
            title: "Publish to Micro Site",
          }),
        icon: GlobeIcon,
      },
    hasCampaignChangeCompanyRole && {
      key: "6",
      label: "Change Company",
      icon: ArrowTopRightOnSquareIcon,
      onClick: () => setIsChangeCompanyModalOpen(true),
    },
    hasCampaignDeleteRole && {
      key: "7",
      label: "Delete Campaign",
      danger: true,
      icon: DeleteIcon,
      className: "!text-red-500",
      onClick: () => setIsDeleteModalOpen(true),
    },
  ].filter(Boolean) as MenuDropdownItem[];

  return (
    <>
      {modal}

      {!!settingsItems.length && (
        <MenuDropdown items={settingsItems} dropdownClassName="w-[210px]">
          <Button
            icon={Icons.Settings}
            iconClassName="size-5"
            variant={"ghostPrimary"}
            className="text-sm font-medium"
          >
            Settings
            <Icons.More className="size-4 ml-2" />
          </Button>
        </MenuDropdown>
      )}

      {!!actionsItems.length && (
        <MenuDropdown items={actionsItems} dropdownClassName="w-[190px]">
          <Button variant={"primaryOutline"} className="text-sm font-medium">
            Actions
            <Icons.More className="size-4 ml-2" />
          </Button>
        </MenuDropdown>
      )}

      {hasCampaignComputationRole && (
        <Button
          className="rounded-full"
          icon={Icons.rerun}
          iconClassName="size-5"
          onClick={() => {
            execute({
              title: "Re-Run Campaign",
              executionType: "EXECUTION_CAMPAIGN",
              requestExecute: async (
                computationType: ComputationType,
                republish: boolean,
              ) => {
                try {
                  const { data: isExecuting } = await computationApi({
                    campaignId,
                  }).validateCampaign();

                  if (!isExecuting) {
                    await computationApi({ campaignId }).executeCampaign({
                      computationType,
                      republish,
                    });

                    dispatch(resetComputationMessages());
                    messageApi.success("Execution started successfully");
                  } else {
                    messageApi.error(
                      "There are some active computation elements in progress, please wait before execute",
                    );
                  }
                } catch (e: any) {
                  const customError = handleRequestError(e);

                  messageApi.error(customError.message);
                  console.error(customError);
                }
              },
            });
          }}
        >
          Re-Run Campaign
        </Button>
      )}
      {hasCampaignExportRole && isOpenExportModal && (
        <ExportCampaignModal
          isOpenExportModal={isOpenExportModal}
          currentCampaign={currentCampaign}
          setIsOpenExportModal={setIsOpenExportModal}
          campaignId={campaignId}
        />
      )}
      {hasPhaseCreateRole && isAddPhaseModalOpen && (
        <PhaseModal
          onCancel={onAddPhaseModalCancel}
          onSubmitForm={onSubmitAddPhaseForm}
          form={addPhaseForm}
          isModalOpen={isAddPhaseModalOpen}
          editPhase={null}
        />
      )}
      {hasStepCreateRole && isAddStepModalOpen && (
        <StepModal
          onSubmitForm={onSubmitAddStepForm}
          onCancel={onAddStepModalCancel}
          form={addStepForm}
          isModalOpen={isAddStepModalOpen}
          editStep={null}
        />
      )}
      {hasCampaignCloneRole && isCloneModalOpen && (
        <CloneEntityModal
          entity="Campaign"
          entityTitle={campaignName}
          entityTitleTooltip="Please enter a string between 4 and 255 characters."
          onSubmitForm={onClone}
          isModalOpen={isCloneModalOpen}
          onCancel={() => setIsCloneModalOpen(false)}
        />
      )}
      {hasCampaignDeleteRole && isDeleteModalOpen && (
        <DeleteWithFilesModal
          entity="Campaign"
          onDelete={onDelete}
          title={campaignName}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
        />
      )}
      {hasCampaignUpdateRole && isEditModalOpen && (
        <EditCampaignModal
          setIsModalOpen={setIsEditModalOpen}
          isModalOpen={isEditModalOpen}
          currentCampaign={currentCampaign}
        />
      )}
      {hasCampaignChangeCompanyRole && isChangeCompanyModalOpen && (
        <ChangeCampaignsCompanyModal
          open={isChangeCompanyModalOpen}
          setOpen={setIsChangeCompanyModalOpen}
          campaignId={campaignId}
        />
      )}
    </>
  );
};

export default Settings;
