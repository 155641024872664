import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getCcVarApi } from "src/api/cc-variables.api";
import { selectCurrentCampaign } from "src/store/slices/campaignsSlice";
import { TCcVariable } from "src/store/slices/ccVariablesSlice";
import { selectCurrentStep } from "src/store/slices/stepsSlice";
import { KeyPanelMode } from "src/types";
import AddCCVarForm from "./AddCCVarForm";
import { VarKeyPanel } from "./KeyPanel";
import {
  applyAnchorTags,
  applyHoverEffect,
  closestEditableElement,
} from "../helpers";
import { getMessageApi } from "../../../store/slices/appSlice";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import handleRequestError from "../../../utils/handleRequestError";
import { useParams } from 'react-router-dom';

type DocViewIframeProps = {
  initialContent: string;
  ccVariables: TCcVariable[];
  onUpdated: () => void;
  onTemplateLoaded: (doc: Document) => void;
  scrollTop: number;
  setScrollTop: (scrollTop: number) => void;
  onKeyAdded: () => void;
  isPdf?: boolean;
  pdfSrc?: string;
};

const DocViewIframe: FC<DocViewIframeProps> = ({
  initialContent,
  ccVariables,
  onUpdated,
  onTemplateLoaded,
  scrollTop,
  setScrollTop,
  isPdf,
  pdfSrc,
  onKeyAdded,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [selectedVariable, selectVariable] = useState<TCcVariable>();
  const [mode, setMode] = useState<KeyPanelMode | undefined>();
  const currentCampaign = useSelector(selectCurrentCampaign);
  const currentStep = useSelector(selectCurrentStep);
  const [createKey, setCreateKey] = useState<string>();
  const messageApi = getMessageApi();
  const { companyId } = useParams();
  const { hasCampaignCcUpdateRole, hasCampaignCcCreateRole } =
    useUserHasPermission({ companyId });

  const handleCloseKeyPanel = () => {
    setMode(undefined);
  };

  useEffect(() => {
    const recentlyAddedVariable = ccVariables.find((variable) => {
      return variable.id.key === createKey;
    });

    if (recentlyAddedVariable && recentlyAddedVariable.state === "computed") {
      setCreateKey(undefined);
      onUpdated();
      onKeyAdded();
    }

    const updatedSelectedVariable = ccVariables.find((variable) => {
      return variable.id.key === selectedVariable?.id.key;
    });

    if (updatedSelectedVariable?.state === "computed") {
      selectVariable(updatedSelectedVariable);
      onUpdated();
    }
  }, [ccVariables]);

  useEffect(() => {
    if (isPdf) {
      return;
    }

    const iframe = iframeRef.current;
    const doc = iframe?.contentDocument || iframe?.contentWindow?.document;

    if (!doc) {
      messageApi.error("Unable to load the template");
      return;
    }

    doc.open();
    doc.write(initialContent);
    doc.close();

    const applyScrollPosition = () => {
      if (scrollTop) {
        const scrollingElement = doc.scrollingElement || doc.documentElement;
        scrollingElement.scrollTop = scrollTop;
      }
    };

    const handleClick = async (event: MouseEvent) => {
      if (!hasCampaignCcUpdateRole && !hasCampaignCcCreateRole) {
        return;
      }

      try {
        const closestElement = closestEditableElement(event);
        const veContentKey = closestElement
          ? closestElement.getAttribute("ve-content")
          : null;

        if (!veContentKey) {
          return;
        }

        if (!currentCampaign || !currentStep) {
          messageApi.error("Unable to get [currentCampaign] or [currentStep]");
          return;
        }

        const { data: ccItem } = await getCcVarApi({
          campaignId: currentCampaign.id,
          stepId: currentStep.id,
          key: veContentKey,
        });

        if (ccItem) {
          setMode(KeyPanelMode.Edit);
          selectVariable(ccItem);
        } else if (!ccItem && veContentKey) {
          setMode(KeyPanelMode.Create);
          setCreateKey(veContentKey);
        }
      } catch (e: any) {
        const customError = handleRequestError(e);

        messageApi.error(customError.message);
        console.error(customError);
      }
    };

    const handleScroll = () => {
      const scrollingElement = doc.scrollingElement || doc.documentElement;
      setScrollTop(scrollingElement.scrollTop);
    };

    if (hasCampaignCcUpdateRole || hasCampaignCcCreateRole) {
      applyHoverEffect(doc);
    }

    applyAnchorTags(doc);
    onTemplateLoaded(doc);

    const handleLoad = () => {
      applyScrollPosition();
    };

    doc.addEventListener("click", handleClick);
    doc.addEventListener("scroll", handleScroll);
    iframe.addEventListener("load", handleLoad);

    return () => {
      doc.removeEventListener("click", handleClick);
      doc.removeEventListener("scroll", handleScroll);
      iframe.removeEventListener("load", handleLoad);
    };
  }, [initialContent]);

  return (
    <div className="w-full mt-[24px] border border-slate-300 rounded-md h-[600px]">
      <div>
        {isPdf && pdfSrc ? (
          <iframe
            className="w-full h-[600px] rounded-md"
            title="preview"
            src={pdfSrc}
          />
        ) : (
          <iframe
            ref={iframeRef}
            className="w-full h-[600px] rounded-md"
            title="preview"
          />
        )}
      </div>
      {hasCampaignCcUpdateRole && mode === KeyPanelMode.Edit && (
        <VarKeyPanel
          onClose={handleCloseKeyPanel}
          variableData={selectedVariable}
          onSaved={onUpdated}
          ccVariables={ccVariables}
          mode={mode}
          isOpen={!!mode}
        />
      )}
      {hasCampaignCcCreateRole && mode === KeyPanelMode.Create && (
        <AddCCVarForm
          isOpened={!!mode}
          setIsOpened={(val) => setMode(val ? KeyPanelMode.Create : undefined)}
          ccVarData={null}
          varKeysLowerCase={ccVariables.map((item) =>
            item.id.key.toLowerCase(),
          )}
          createKey={createKey}
          ccVariables={ccVariables}
        />
      )}
    </div>
  );
};

export default DocViewIframe;
