import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import { useEffect, useMemo, useState } from "react";
import { TNavigationItem } from "../../../../globalTypes";
import handleRequestError from "../../../../utils/handleRequestError";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { useGoBack } from "src/hooks/useGoBack";
import {
  cleanUpCompanyStorageData,
  loadCompanyStorageDataThunk,
  selectCompanyStorageCompanyData,
  selectCompanyStorageMicroSiteData,
} from "../../../../store/slices/companyStorageSlice";
import { AppDispatch } from "../../../../store/store";

const useCompanyStorageData = () => {
  const { companyId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const companyData = useSelector(selectCompanyStorageCompanyData);
  const companyMicroSite = useSelector(selectCompanyStorageMicroSiteData);
  const {
    hasPublicFsFileListRole,
    hasPrivateFsFileListRole,
    hasMsFsFileListRole,
  } = useUserHasPermission({ companyId });
  const onGoBack = useGoBack("/campaigns/");

  const navigationItems = useMemo(() => {
    return [
      hasPublicFsFileListRole && {
        path: "public",
        title: "Public",
      },
      hasPrivateFsFileListRole && {
        path: "private",
        title: "Private",
      },
      companyMicroSite &&
        hasMsFsFileListRole && {
          path: "microsite",
          title: "Microsite",
        },
    ].filter(Boolean) as TNavigationItem[];
  }, [
    hasPublicFsFileListRole,
    hasPrivateFsFileListRole,
    companyMicroSite,
    hasMsFsFileListRole,
  ]);

  useEffect(() => {
    getCampaignData();

    return () => {
      dispatch(cleanUpCompanyStorageData());
    };
  }, []);

  const getCampaignData = async () => {
    if (!companyId) {
      messageApi.error("Unable to load the company view");
      console.error("Unable to get [companyId]");
      setIsLoading(false);
      return;
    }

    try {
      await dispatch(loadCompanyStorageDataThunk({ companyId })).unwrap();

      setError(null);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
      setError(customError.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    companyName: companyData?.name,
    onGoBack,
    navigationItems,
  };
};
export default useCompanyStorageData;
