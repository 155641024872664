import { FC } from "react";
import { getCurrentCompany } from "src/store/slices/companiesSlice";
import { TCampaignListOptionType } from "src/globalTypes";
import { CustomSelect } from "../common/ui/CustomSelect";
import { ALL_TEMPLATES_ID } from "src/pages/campaigns/useCampaignsList";

type TChooseCampaignTypeSelectOption = {
  value: number | string;
  label: string;
  disabled?: boolean;
};

type CampaignTypeSelectorProps = {
  campaignType: TCampaignListOptionType;
  onChanged: (campaignType: TCampaignListOptionType) => void;
  enabledTypes?: TCampaignListOptionType[];
};

const CAMPAIGN_TYPES: TCampaignListOptionType[] = [
  "ALL",
  "DRAFT_TEMPLATE",
  "PUBLISHED_TEMPLATE",
  "CAMPAIGN",
];

const CampaignTypeSelector: FC<CampaignTypeSelectorProps> = ({
  campaignType,
  onChanged,
  enabledTypes = CAMPAIGN_TYPES,
}) => {
  const currentCompany = getCurrentCompany();
  const options: Array<TChooseCampaignTypeSelectOption> = [
    { value: "ALL", label: "All Types" },
    { value: "DRAFT_TEMPLATE", label: "Draft Templates" },
    { value: "PUBLISHED_TEMPLATE", label: "Published Templates" },
    { value: ALL_TEMPLATES_ID, label: "All Templates" },
    {
      value: "CAMPAIGN",
      label: "Campaigns",
      disabled: currentCompany === null,
    },
  ];

  const filteredOptions = options.filter((option) => {
    if (enabledTypes) {
      return enabledTypes.includes(option.value as TCampaignListOptionType);
    }
    return true;
  });

  const handleTypeChange = (value: string) => {
    onChanged(value as TCampaignListOptionType);
  };
  return (
    <CustomSelect
      options={filteredOptions.map((option) => ({
        value: option.value.toString(),
        label: option.label,
      }))}
      value={campaignType?.toString()}
      onChange={handleTypeChange}
      label="Select Campaign Type"
      className="w-[180px]"
    />
  );
};
export default CampaignTypeSelector;
