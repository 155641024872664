import React from "react";
import { Route, Routes } from "react-router-dom";
import GlobalStoragePage from "../../pages/storage/GlobalStoragePage";
import GlobalPublicStorage from "../../components/storage/globalStorage/globalPublicStorage/GlobalPublicStorage";
import GlobalPrivateStorage from "../../components/storage/globalStorage/globalPrivateStorage/GlobalPrivateStorage";
import { UserPermissions } from "../../store/slices/userData/constants";
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";
import NotFoundPage from "../../pages/NotFoundPage";

const GlobalStorageRoutes = () => {
  return (
    <Routes>
      {/* TODO add multiple permissions to ProtectedByRoleRoute*/}
      {/* use - hasPublicRootFsFileListRole || hasPrivateRootFsFileListRole for GlobalStoragePage*/}
      <Route path="/" element={<GlobalStoragePage />}>
        <Route
          path="public"
          element={
            <ProtectedByRoleRoute
              permission={UserPermissions.ROLE_PUBLIC_ROOT_FS_FILE_LIST}
            >
              <GlobalPublicStorage />
            </ProtectedByRoleRoute>
          }
        />
        <Route
          path="private"
          element={
            <ProtectedByRoleRoute
              permission={UserPermissions.ROLE_PRIVATE_ROOT_FS_FILE_LIST}
            >
              <GlobalPrivateStorage />
            </ProtectedByRoleRoute>
          }
        />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default GlobalStorageRoutes;
