import { useParams, useSearchParams } from "react-router-dom";
import { CampaignViewTypes } from "../../../../types";
import { stringifyObjectValues } from "src/utils/stringifyObjectValues";

type TCampaignParams = {
  stepId?: number;
  phaseId?: number;
  campaignId?: number;
  companyId?: number;
  view: CampaignViewTypes;
  documentTemplateId?: number;
  updateSearchParams: (params: Partial<CampaignSearchParams>) => void;
  searchParams: URLSearchParams;
  updateCampaignView: (viewType: CampaignViewTypes) => void;
};

type CampaignSearchParams = Pick<
  TCampaignParams,
  "documentTemplateId" | "view"
>;

export const useCampaignParams = (): TCampaignParams => {
  const { stepId, phaseId, campaignId, companyId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const viewFromParams = searchParams.get("view") as CampaignViewTypes;
  const view = Object.values(CampaignViewTypes).includes(viewFromParams)
    ? viewFromParams
    : CampaignViewTypes.GRID;

  const documentTemplateIdFromParams = searchParams.get("documentTemplateId");
  const documentTemplateId = documentTemplateIdFromParams
    ? +documentTemplateIdFromParams
    : undefined;

  const updateSearchParams = (params: Partial<CampaignSearchParams>) => {
    const currentHash = window.location.hash;

    const stringifiedParams = stringifyObjectValues({
      view,
      documentTemplateId,
      ...params,
    } as CampaignSearchParams);
    setSearchParams(stringifiedParams);

    window.location.hash = currentHash;
  };

  const updateCampaignView = (viewType: CampaignViewTypes) => {
    updateSearchParams({
      view: viewType,
      // remove documentTemplateId when switch to Grid
      documentTemplateId:
        viewType === CampaignViewTypes.DOC ? documentTemplateId : undefined,
    });
  };

  return {
    stepId: stepId ? +stepId : undefined,
    phaseId: phaseId ? +phaseId : undefined,
    campaignId: campaignId ? +campaignId : undefined,
    companyId: companyId ? +companyId : undefined,
    view,
    documentTemplateId,
    updateSearchParams,
    searchParams,
    updateCampaignView,
  };
};
