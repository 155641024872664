type Stringify<T extends Record<string, any>> = {
  [K in keyof T]: string;
};
export const stringifyObjectValues = <T extends Record<string, any>>(
  requestParams: T,
): Stringify<T> => {
  return Object.entries(requestParams).reduce<Stringify<T>>(
    (acc, [key, value]) => {
      if (key in requestParams) {
        if (value !== undefined) {
          acc[key as keyof T] = value?.toString() ?? "";
        }
      }
      return acc;
    },
    {} as Stringify<T>,
  );
};
