import clsx from "clsx";
import { ArrowDown, ArrowUp, ArrowUpDownIcon } from "lucide-react";
import { FC } from "react";
import {
  SortingDirection,
  TDocTemplateTableHeaderItem,
} from "src/types/docTemplates";
import { TableHeader as Header, TableHead, TableRow } from "../common/ui/table";

export type Sorting<K> = {
  field?: K;
  direction?: SortingDirection;
};

interface Props {
  sorting?: Sorting<string>;
  handleUpdateSorting?: (field: string) => void;
  items: TDocTemplateTableHeaderItem[];
}

export const TableHeader: FC<Props> = ({
  sorting,
  items,
  handleUpdateSorting,
}) => {
  return (
    <Header className="rounded-t-2xl">
      <TableRow className="bg-slate-50">
        {items.map((item) => {
          if (!item) return <TableHead key={Math.random()} />;

          const { title, className, name, isSortable } = item;
          return (
            <TableHead
              key={title}
              className={clsx(className, {
                "cursor-pointer hover:text-black": isSortable,
              })}
              onClick={
                isSortable ? () => handleUpdateSorting?.(name!) : undefined
              }
            >
              <span className="inline-flex items-center font-semibold">
                {title}
                {isSortable ? (
                  sorting?.field === name ? (
                    sorting?.direction === SortingDirection.ASC ? (
                      <ArrowUp className="ml-2 h-[16px] w-[16px]" />
                    ) : (
                      <ArrowDown className="ml-2 h-[16px] w-[16px]" />
                    )
                  ) : (
                    <ArrowUpDownIcon className="ml-2 h-[16px] w-[16px]" />
                  )
                ) : null}
              </span>
            </TableHead>
          );
        })}
      </TableRow>
    </Header>
  );
};
