import React, { FC } from "react";
import { TUserPermissionGroupFormValues } from "../../types/securityGroups";
import { Form, FormInstance, Input, InputNumber } from "antd";
import { minMax, required } from "../../utils/validations";
import customizeFormLabels from "../../utils/customizeFormLabels";

type Props = {
  form: FormInstance<TUserPermissionGroupFormValues>;
  isSuperUser: boolean;
  initialValues?: TUserPermissionGroupFormValues;
};
const SecurityGroupForm: FC<Props> = ({ initialValues, form, isSuperUser }) => {
  return (
    <Form
      name="securityGroupForm"
      form={form}
      layout="vertical"
      initialValues={initialValues}
      requiredMark={customizeFormLabels}
      className="w-[35%]"
      disabled={isSuperUser}
    >
      <Form.Item
        name="title"
        label="Title"
        tooltip="Please enter a value between 2 and 255 characters."
        rules={[required(true), minMax({ min: 2, max: 255, text: "Title" })]}
      >
        <Input placeholder="Enter unique title" className="h-[48px]" />
      </Form.Item>
      <Form.Item
        name="level"
        tooltip="Please enter the value between 0 and 1000000."
        label="Level"
        rules={[required()]}
        className="flex-1"
      >
        <InputNumber
          placeholder="Enter the level value"
          className="h-[48px] w-full py-[8px]"
          step={1}
          max={1000000}
          min={0}
        />
      </Form.Item>
      {isSuperUser && (
        <span className="text-[10px] italic text-slate-500">
          *the super user cannot be edited
        </span>
      )}
    </Form>
  );
};

export default SecurityGroupForm;
