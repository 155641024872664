import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "src/components/common/ui/button";
import { getAssetsApi, uploadAssetApi } from "../../api/assets.api";
import { TAsset } from "../../globalTypes";
import handleRequestError from "../../utils/handleRequestError";
import { Icons } from "../common/Icons";
import AssetsList from "./AssetsList";
import AssetModal from "./AssetModal";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../store/slices/appSlice";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import UploadAssetsModal from "../common/modals/UploadAssetsModal";

const CampaignAssets = () => {
  const { companyId, campaignId, stepId } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [assets, setAssets] = useState<TAsset[]>([]);
  const messageApi = useSelector(selectMessageApi);
  const { hasAssetCreateRole } = useUserHasPermission({ companyId });
  const [isUploadAssetsModalOpen, setIsUploadAssetsModalOpen] = useState(false);
  const [isAddAssetModalOpen, setIsAddAssetModalOpen] = useState(false);
  const [editAssetData, setEditAssetData] = useState<TAsset | null>(null);

  useEffect(() => {
    onReloadAssets();
  }, [campaignId, stepId]);

  if (!stepId || !campaignId) {
    return (
      <span className="text-red-600">
        Unable to get [stepId] or [campaignId] from URL
      </span>
    );
  }

  const loadAndSetAssets = async () => {
    if (!campaignId || !stepId) {
      messageApi.error("Unable to get [campaignId] or [stepId] from URL");
      return;
    }

    const { data } = await getAssetsApi({ campaignId, stepId });

    setAssets(data);
  };

  const onReloadAssets = async () => {
    try {
      setIsFetching(true);

      await loadAndSetAssets();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onAddAsset = () => {
    setIsAddAssetModalOpen(true);
    setEditAssetData(null);
  };

  const onUploadAssets = () => {
    setIsUploadAssetsModalOpen(true);
  };

  const onUploadAsset = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    await uploadAssetApi({
      campaignId,
      stepId,
      formData,
    });

    await loadAndSetAssets();
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-[6px]">
        {hasAssetCreateRole && (
          <>
            <Button
              onClick={onAddAsset}
              variant="primaryOutline"
              className="rounded-full self-start"
              size="sm"
              icon={Icons.Plus}
            >
              Add Asset
            </Button>

            <Button
              onClick={onUploadAssets}
              variant="primaryOutline"
              className="rounded-full self-start"
              size="sm"
              icon={Icons.UploadIcon}
            >
              Upload Assets
            </Button>
          </>
        )}

        <Button
          onClick={onReloadAssets}
          className="rounded-full"
          variant="primaryOutline"
          icon={Icons.rerun}
          size="sm"
          iconClassName="size-4"
        >
          Reload Assets
        </Button>
      </div>
      <Spin spinning={isFetching}>
        <AssetsList
          campaignId={campaignId}
          assets={assets}
          setIsModalOpen={setIsAddAssetModalOpen}
          setEditAsset={setEditAssetData}
          loadAndSetAssets={loadAndSetAssets}
        />
      </Spin>

      {isAddAssetModalOpen && (
        <AssetModal
          editAsset={editAssetData}
          loadAndSetAssets={loadAndSetAssets}
          isModalOpen={isAddAssetModalOpen}
          setIsModalOpen={(open) => setIsAddAssetModalOpen(open)}
          campaignId={campaignId}
          stepId={stepId}
        />
      )}

      {isUploadAssetsModalOpen && (
        <UploadAssetsModal
          uploadAsset={onUploadAsset}
          setIsModalOpen={(open) => setIsUploadAssetsModalOpen(open)}
          isModalOpen={isUploadAssetsModalOpen}
        />
      )}
    </div>
  );
};

export default CampaignAssets;
