import { Button, Checkbox, Tooltip } from "antd";
import clsx from "clsx";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ChangeNodeProps } from "../../pages/graph/hooks/useGetNodes.hook";
import { selectMessageApi } from "../../store/slices/appSlice";
import {
  saveCcItemResultOvrThunk,
  TCcVariable,
} from "../../store/slices/ccVariablesSlice";
import handleRequestError from "../../utils/handleRequestError";
import { Badge } from "../common/ui/badge";
import { Textarea } from "../common/ui/textarea";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { AppDispatch } from "../../store/store";
import { RestoreIcon } from "../common/Icons";
import useManageCCItemOverride from "../../hooks/useManageCCItemOverride"; /*
  Override Dialog Behavior:

  1. No Override Present on Open:
     - Show the original text in "Override Version" text area as grayed-out placeholder.
     - Disable the "Save" button.
     - When user edits text, text turns dark and "Save" button becomes enabled.
     - On clicking "Save," the override is saved, and dialog closes.

  2. Override Already in Place on Open:
     - Show current override text as dark text in "Override Version" area.
     - Disable "Save" button initially.
     - When user edits text, enable the "Save" button.
     - On clicking "Save," update the override and close the dialog.

  3. Removing the Override:
     - Option 1: "Restore Original" button:
       - Replace override text with original text, set text to gray, and enable "Save" button to remove override.
     - Option 2: Clear all text in "Override Version" area and click "Save."
       - This action removes the override.
*/

/*
  Override Dialog Behavior:

  1. No Override Present on Open:
     - Show the original text in "Override Version" text area as grayed-out placeholder.
     - Disable the "Save" button.
     - When user edits text, text turns dark and "Save" button becomes enabled.
     - On clicking "Save," the override is saved, and dialog closes.

  2. Override Already in Place on Open:
     - Show current override text as dark text in "Override Version" area.
     - Disable "Save" button initially.
     - When user edits text, enable the "Save" button.
     - On clicking "Save," update the override and close the dialog.

  3. Removing the Override:
     - Option 1: "Restore Original" button:
       - Replace override text with original text, set text to gray, and enable "Save" button to remove override.
     - Option 2: Clear all text in "Override Version" area and click "Save."
       - This action removes the override.
*/

type Props = {
  ccItem: TCcVariable;
  changeNodeProps?: ChangeNodeProps;
  setItem: Dispatch<SetStateAction<TCcVariable | null>>;
  onOverrideSave?: () => void;
};

const PaneResultOverride: FC<Props> = ({
  ccItem,
  changeNodeProps,
  setItem,
  onOverrideSave,
}) => {
  const { id, result, resultOvr } = ccItem;
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = useSelector(selectMessageApi);
  const [isFetching, setIsFetching] = useState(false);
  const { companyId } = useParams();
  const { hasCampaignCcOverrideRole } = useUserHasPermission({ companyId });
  const {
    handleResetDependenciesChange,
    handleOverrideValueChange,
    setOverrideValue,
    shouldSaveOverride,
    isSaveButtonDisabled,
    handleRestoreOriginalClick,
    overrideValue,
    resetDependencies,
  } = useManageCCItemOverride({ result, resultOvr });

  useEffect(() => {
    setOverrideValue(ccItem.resultOvr || ccItem.result || "");
  }, [ccItem]);

  const handleSaveOverride = async () => {
    try {
      setIsFetching(true);

      const value = shouldSaveOverride ? overrideValue : "";

      await dispatch(
        saveCcItemResultOvrThunk({
          varId: id,
          value,
          resetDependencies,
        }),
      ).unwrap();

      setItem((item) => {
        return item ? { ...item, resultOvr: value } : null;
      });

      changeNodeProps?.({
        nodeId: `${id.key}_${id.stepId}`,
        props: { touched: true },
      });
      onOverrideSave?.();
      messageApi.success("Successfully saved");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="flex justify-between px-[2px]">
      <div className="w-[49%] relative">
        <Badge
          className="absolute top-0 z-10 left-[12px] bg-green-300 text-green-950"
          variant={"table"}
        >
          Override Version
        </Badge>

        <div className="w-full h-full relative mt-[10px] overflow-hidden rounded-md">
          {shouldSaveOverride && (
            <Tooltip title="Override">
              <div className="absolute top-[-5px] right-[-10px] w-[30px] h-[20px] bg-gradient-to-t from-white to-yellow-500 rotate-45" />
            </Tooltip>
          )}

          <Textarea
            className={clsx("w-full h-full p-[14px]", {
              "text-black/40": overrideValue === result,
            })}
            onChange={(e) => handleOverrideValueChange(e.target.value)}
            value={overrideValue}
            disabled={!hasCampaignCcOverrideRole}
          />
        </div>

        {hasCampaignCcOverrideRole && (
          <div className="flex flex-col gap-[8px] mt-[12px]">
            <div>
              <Checkbox
                onChange={(e) =>
                  handleResetDependenciesChange(e.target.checked)
                }
              >
                <span className="font-sans font-bold text-[#475569] text-[14px]">
                  Reset downstream dependencies
                </span>
              </Checkbox>
            </div>
            <div className="flex gap-[12px]">
              <Button
                size="small"
                onClick={handleRestoreOriginalClick}
                disabled={overrideValue === result}
                icon={<RestoreIcon />}
                shape="round"
              >
                Restore Original
              </Button>
              <Button
                size="small"
                type="primary"
                loading={isFetching}
                disabled={isSaveButtonDisabled}
                onClick={handleSaveOverride}
                shape="round"
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="w-[49%] relative">
        <Badge
          className="absolute top-0 left-[12px] bg-blue-300 text-blue-950"
          variant={"table"}
        >
          Original Output
        </Badge>
        <Textarea
          className="w-full mt-[10px] h-full p-[14px]"
          defaultValue={result || ""}
          disabled
        />
      </div>
    </div>
  );
};

export default PaneResultOverride;
