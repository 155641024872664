import React, { useState } from "react";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { useGoBack } from "../../hooks/useGoBack";
import { Button } from "../common/ui/button";
import { Icons } from "../common/Icons";
import GlobalLabelsModal from "./GlobalLabelsModal";

const GlobalLabelsHeader = () => {
  const { hasG18nCreateRole } = useUserHasPermission({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const goBack = useGoBack("/campaigns");

  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-[12px] items-center">
        <Button
          variant="ghost"
          icon={Icons.back}
          onClick={goBack}
          className="p-[12px] h-auto"
        />
        <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal capitalize">
          Global Labels
        </h1>
      </div>

      {hasG18nCreateRole && (
        <Button
          icon={Icons.Plus}
          className="rounded-full"
          onClick={() => setIsEditModalOpen(true)}
        >
          Add Label
        </Button>
      )}

      {hasG18nCreateRole && isEditModalOpen && (
        <GlobalLabelsModal
          isOpened={isEditModalOpen}
          setIsOpened={setIsEditModalOpen}
          labelData={null}
        />
      )}
    </div>
  );
};

export default GlobalLabelsHeader;
