export const buildWebSocketUrl = (campaignId: number): string => {
  const protocol = window.location.protocol === "https:" ? "wss" : "ws";
  const host =
    window.location.hostname === "localhost" && window.location.port === "3000"
      ? "localhost:8080"
      : window.location.host;
  return `${protocol}://${host}/secured/ws-integration?campaignId=${campaignId}`;
};

export const getProgressNumber = (
  total: number,
  toComplete: number,
): number => {
  if (total <= 0 || toComplete < 0 || toComplete > total) {
    return 0;
  }

  const progress = (1 - toComplete / total) * 100;
  const roundedProgress = Math.min(Math.max(progress, 0), 100).toFixed(2);

  return parseFloat(roundedProgress);
};
