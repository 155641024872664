import { FC } from "react";
import { useParams } from "react-router-dom";
import { getCompanyPrivateFilesListApi } from "../../../../api/company-storage.api";
import { TFileTreeItem } from "../../../../globalTypes";
import Storage from "../../dynamicStorage/Storage";
import useDynamicStorage from "../../dynamicStorage/hooks/useDynamicStorage.hook";
import { LoadFolderFiles, LoadRootFiles } from "../../dynamicStorage/types";
import ItemMenu from "./ItemMenu";
import { useSelector } from "react-redux";
import { selectCompanyStorageCompanyData } from "../../../../store/slices/companyStorageSlice";

const CompanyPrivateStorage: FC = () => {
  const { companyId } = useParams();
  const companyData = useSelector(selectCompanyStorageCompanyData);

  const loadRootFiles: LoadRootFiles = async () => {
    if (!companyId || !companyData) {
      return null;
    }

    const rootFolderPath = `/${companyData.name}/`;
    const rootFolderName = companyData.name;

    const { data } = await getCompanyPrivateFilesListApi({
      path: rootFolderPath,
    });

    return {
      rootFolderFiles: data,
      rootStoragePath: rootFolderPath,
      rootStorageName: rootFolderName,
    };
  };

  const loadFolderFiles: LoadFolderFiles = async ({ path }) => {
    const { data } = await getCompanyPrivateFilesListApi({
      path,
    });

    return data;
  };

  const {
    setSelectedItem,
    selectedItem,
    treeData,
    setTreeData,
    getFolderFiles,
    isLoading,
    error,
    rootPath,
  } = useDynamicStorage({ loadRootFiles, loadFolderFiles });

  const renderMenu = ({
    isHovering,
    isSelected,
    item,
  }: {
    isHovering: boolean;
    isSelected: boolean;
    item: TFileTreeItem;
  }) => {
    return (
      <ItemMenu
        isSelected={isSelected}
        isHovering={isHovering}
        setTreeData={setTreeData}
        item={item}
        treeData={treeData}
        contextPath={rootPath}
      />
    );
  };

  return (
    <Storage
      setSelectedItem={setSelectedItem}
      getFolderFiles={getFolderFiles}
      isLoading={isLoading}
      error={error}
      treeData={treeData}
      selectedItem={selectedItem}
      renderMenu={renderMenu}
    />
  );
};

export default CompanyPrivateStorage;
