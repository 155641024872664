import { logoutThunk } from "../../store/slices/userData/slice";
import instance from "./axiosInstance";

const axiosInterceptor = (store: any) => {
  instance.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err?.response?.status === 401) {
        store.dispatch(logoutThunk({ cleanLogout: false }));
      }
      return Promise.reject(err);
    },
  );
};

export default axiosInterceptor;
