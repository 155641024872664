import { UserPermissions } from "../constants";
import { UserMyPermissions } from "../types";

type Props = {
  userPermissions: UserMyPermissions;
  permission: UserPermissions;
};
export const hasUserPermissionAnywhere = ({
  permission,
  userPermissions,
}: Props): boolean => {
  const { globalPermissions, companyPermissions, companyPermissionsDeny } =
    userPermissions;

  if (globalPermissions.includes(permission)) {
    return true;
  }

  for (const [companyId, permissions] of Object.entries(companyPermissions)) {
    // Search permission in company permissions.
    // If founded in any company, check if it's NOT denied in that company then return TRUE.
    if (
      permissions.includes(permission) &&
      (!companyPermissionsDeny[companyId] ||
        !companyPermissionsDeny[companyId].includes(permission))
    ) {
      return true;
    }
  }

  return false;
};
