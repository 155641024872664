import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appSlice from "./slices/appSlice";
import campaignLabelsSlice from "./slices/campaignLabelsSlice";
import campaignsListSlice from "./slices/campaignsListSlice";
import campaignsSlice from "./slices/campaignsSlice";
import ccVariablesSlice from "./slices/ccVariablesSlice";
import companiesListSlice from "./slices/companiesListSlice";
import companiesSlice from "./slices/companiesSlice";
import computationMessagesSlice from "./slices/computationMessages/slice";
import docTemplatesSlice from "./slices/docTemplatesSlice";
import envVariablesSlice from "./slices/envVariablesSlice";
import globalLabelsSlice from "./slices/globalLabelsSlice";
import modelConfigsSlice from "./slices/modelConfigsSlice";
import phasesSlice from "./slices/phasesSlice";
import stepsSlice from "./slices/stepsSlice";
import usersListSlice from "./slices/usersListSlice";
import userSlice from "./slices/userData/slice";
import companyStorageSlice from "./slices/companyStorageSlice";
import templateOptionsSlice from "./slices/templateOptionsSlice";

const reducers = combineReducers({
  app: appSlice,
  userData: userSlice,
  usersList: usersListSlice,
  ccVariables: ccVariablesSlice,
  envVariables: envVariablesSlice,
  campaignsList: campaignsListSlice,
  steps: stepsSlice,
  phases: phasesSlice,
  companies: companiesSlice,
  campaigns: campaignsSlice,
  computationMessages: computationMessagesSlice,
  globalLabels: globalLabelsSlice,
  campaignLabels: campaignLabelsSlice,
  companiesList: companiesListSlice,
  docTemplates: docTemplatesSlice,
  modelConfigs: modelConfigsSlice,
  companyStorage: companyStorageSlice,
  templateOptions: templateOptionsSlice,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;
