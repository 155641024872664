import { Empty, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUiTemplatesOptions } from "src/hooks/useTemplatesOptions";
import CampaignClasses from "src/pages/campaign/components/StepData/CampaignClasses";
import { selectCurrentPhase } from "src/store/slices/phasesSlice";
import {
  selectCurrentStep,
  updateCurrentStepTemplateThunk,
} from "src/store/slices/stepsSlice";
import {
  downloadStepTemplatePDFApi,
  getTemplatePreview,
} from "../../api/steps.api";
import { selectMessageApi } from "../../store/slices/appSlice";
import { AppDispatch, RootState } from "../../store/store";
import handleRequestError from "../../utils/handleRequestError";
import { CampaignViewTypes, FileMimeType } from "src/types";
import EditableIframe from "./components/DocViewIframe";
import CampaignStateIndicator from "./components/CampaignStateIndicator";
import { DocViewSelector } from "./components/DocViewSelector";
import { DocViewActions } from "./components/DocViewActions";
import { downloadFileUtil } from "../../utils/cm.utils";
import { DocumentTemplateState } from "src/types/docTemplates";
import { useCampaignParams } from "src/pages/campaign/components/CampaignEditorPage/useCampaignParams";

const CampaignDocView: FC = () => {
  const {
    documentTemplateId,
    updateSearchParams,
    searchParams,
    view: currentCampaignView,
  } = useCampaignParams();
  const dispatch = useDispatch<AppDispatch>();
  const currentStep = useSelector(selectCurrentStep);
  const currentPhase = useSelector(selectCurrentPhase);
  const messageApi = useSelector(selectMessageApi);
  const ccVariables = useSelector((state: RootState) => state.ccVariables.list);
  const [isFetching, setIsFetching] = useState(false);
  const [blob, setBlob] = useState<Blob>();
  const [content, setContent] = useState<string>("");
  const {
    options,
    selectedOption,
    load: requestUiTemplateOptions,
    isLoading: isTemplatesLoading,
  } = useUiTemplatesOptions();
  const [scrollTop, setScrollTop] = useState(0);
  const [isPdf, setIsPdf] = useState(false);
  const [pdfSrc, setPdfSrc] = useState("");
  const [isVeTemplate, setIsVeTemplate] = useState(true);

  const isHTMLTemplateSelected =
    selectedOption?.info.mediaType === FileMimeType.HTML;

  const currentTemplateId =
    documentTemplateId || currentStep?.documentTemplateId || undefined;

  useEffect(() => {
    if (currentCampaignView === CampaignViewTypes.DOC) {
      // TODO: fix two preview calls when selecting template from the dropdown
      handlePreview(selectedOption ? currentTemplateId : undefined);
    }
  }, [currentTemplateId, currentCampaignView, currentStep, selectedOption]);

  useEffect(() => {
    setScrollTop(0);
  }, [currentStep?.id]);

  useEffect(() => {
    if (currentCampaignView !== CampaignViewTypes.DOC) return;

    if (documentTemplateId) {
      if (documentTemplateId !== currentTemplateId) {
        handlePreview(documentTemplateId);
      }
    } else if (currentTemplateId) {
      updateSearchParams({ documentTemplateId: currentTemplateId });
    }
  }, [
    currentTemplateId,
    documentTemplateId,
    searchParams,
    currentCampaignView,
  ]);

  const updateCurrentStepTemplate = (templateId: number | null) => {
    dispatch(updateCurrentStepTemplateThunk({ templateId }));
    updateSearchParams({
      documentTemplateId: templateId || undefined,
    });
  };

  const handlePreview = async (templateId?: number | null) => {
    if (!templateId) {
      setBlob(undefined);
      setContent("");
      return;
    }

    try {
      setIsFetching(true);

      if (!currentPhase?.id || !currentStep?.id) {
        messageApi.error("Missing required data!");
        return;
      }

      const { data } = await getTemplatePreview({
        phaseId: currentPhase.id,
        stepId: currentStep.id,
        documentTemplateId: templateId,
      });

      const isPdf = selectedOption?.info.mediaType === FileMimeType.PDF;

      const sourceBlob = new Blob([data], {
        type: isPdf ? "application/pdf" : "text/html",
      });

      const pdfSrc = URL.createObjectURL(sourceBlob);
      setIsPdf(isPdf);
      setPdfSrc(pdfSrc);
      setBlob(sourceBlob);

      if (sourceBlob) {
        const blobContent = await sourceBlob.text();
        setContent(blobContent);
      } else {
        setContent("");
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onTabPreview = async () => {
    try {
      if (!blob) {
        messageApi.error("No blob data!");
        return;
      }

      setIsFetching(true);

      const blobContent = URL.createObjectURL(blob);

      window.open(blobContent, "_blank");
      URL.revokeObjectURL(blobContent);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const handleTemplateLoaded = (doc: Document) => {
    const veEl = doc.querySelector("[ve-content]");
    setIsVeTemplate(!!veEl);
  };

  const handleReload = () => {
    handlePreview(currentTemplateId || undefined);
  };

  const handleDownloadPDF = async () => {
    try {
      const response = await downloadStepTemplatePDFApi({
        phaseId: currentPhase?.id!,
        stepId: currentStep?.id!,
        template: currentTemplateId!,
      });

      downloadFileUtil(response);
      messageApi.success("The document was successfully downloaded");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const getUiTemplateOptions = async () => {
    if (!currentStep) return;

    await requestUiTemplateOptions({
      states: [DocumentTemplateState.PUBLISHED],
      classes: currentStep?.classes || [],
      stepId: currentStep?.id,
    });
  };

  useEffect(() => {
    getUiTemplateOptions();
  }, [currentStep?.classes]);

  const handleKeyAdded = () => {
    getUiTemplateOptions();
  };

  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <CampaignClasses />
          <div className="flex items-center gap-2 ev-ant">
            <CampaignStateIndicator ccVariables={ccVariables} />
            <DocViewSelector
              options={options}
              isTemplatesLoading={isTemplatesLoading}
              currentTemplateId={currentTemplateId}
              setSelectedTemplate={updateCurrentStepTemplate}
              selectedOption={selectedOption}
              isVeTemplate={isVeTemplate}
            />
          </div>
          <DocViewActions
            disabled={!currentTemplateId}
            isBlob={!!blob}
            onReloadClick={handleReload}
            onTabPreviewClick={onTabPreview}
            handleDownloadPDF={
              isHTMLTemplateSelected ? handleDownloadPDF : undefined
            }
          />
        </div>

        {isFetching ? (
          <Spin className="flex justify-center items-center w-full mt-4 border border-slate-300 rounded-md h-[calc(200px)]" />
        ) : (
          <>
            {content ? (
              <EditableIframe
                ccVariables={ccVariables}
                initialContent={content}
                onUpdated={handleReload}
                onTemplateLoaded={handleTemplateLoaded}
                scrollTop={scrollTop}
                setScrollTop={setScrollTop}
                isPdf={isPdf}
                pdfSrc={pdfSrc}
                onKeyAdded={handleKeyAdded}
              />
            ) : (
              <Empty
                imageStyle={{ height: "30vh" }}
                className="rounded-md border w-full mx-auto py-6 mt-4"
                description={
                  <div>
                    <span className="font-sans font-medium text-[#0F172A]">
                      Select template to preview
                    </span>
                  </div>
                }
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CampaignDocView;
