import { Tooltip } from "antd";
import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Icons } from "src/components/common/Icons";
import { Button } from "src/components/common/ui/button";
import { getMessageApi } from "src/store/slices/appSlice";
import {
  TCcVariable,
  TCcVariableType,
} from "src/store/slices/ccVariablesSlice";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";

type Props = {
  variable: TCcVariable;
  value: string;
  onClickEdit: () => void;
};

const unableToEditTypes: TCcVariableType[] = ["string", "asset"];

const ROW_CONTENT_MAX_LENGTH = 500;

const GridItemResult: React.FC<Props> = ({ value, onClickEdit, variable }) => {
  const { companyId } = useParams();
  const { type, resultOvr } = variable;
  const [copied, setCopied] = useState(false);
  const messageApi = getMessageApi();
  const [animate, setAnimate] = useState(false);
  const { hasCampaignCcOverrideRole } = useUserHasPermission({ companyId });
  const firstRender = useRef(true);

  useEffect(() => {
    if (value.length && !firstRender.current) {
      setAnimate(true);
      const timeout = setTimeout(() => setAnimate(false), 2500);
      return () => clearTimeout(timeout);
    }

    firstRender.current = false;
  }, [value]);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  const processedValue = useMemo(() => {
    return value.length > ROW_CONTENT_MAX_LENGTH
      ? value.slice(0, ROW_CONTENT_MAX_LENGTH) + "..."
      : value;
  }, [value]);

  const content = useMemo(() => {
    if (value.length > ROW_CONTENT_MAX_LENGTH) {
      return (
        <Tooltip
          overlayStyle={{
            maxWidth: "30vW",
            maxHeight: "30vh",
            overflowY: "scroll",
          }}
          title={value}
          overlayInnerStyle={{
            width: "100%",
            height: "100%",
            maxHeight: "30vh",
            overflowY: "scroll",
            whiteSpace: "pre-wrap",
            fontSize: "12px",
          }}
        >
          {processedValue}
        </Tooltip>
      );
    }
    return value;
  }, [value, processedValue]);

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(value || "").then(() => {
        messageApi.success("Copied to clipboard");
        setCopied(true);
      });
    } else {
      messageApi.error("Clipboard not supported");
    }
  };

  return (
    <div className="flex flex-col p-2">
      {resultOvr && (
        <Tooltip title="Override">
          <div className="absolute top-[-5px] right-[-10px] w-[30px] h-[20px] bg-gradient-to-t from-white to-yellow-500 rotate-45" />
        </Tooltip>
      )}
      <span
        className={clsx("w-full whitespace-pre-line", {
          "animate-highlight-cc-updated": animate,
        })}
      >
        {content}
      </span>
      <div className="pt-1">
        {hasCampaignCcOverrideRole && !unableToEditTypes.includes(type) && (
          <Button
            onClick={onClickEdit}
            icon={Icons.edit}
            size="sm"
            variant="primaryGhost"
            className="rounded-full"
          >
            Edit
          </Button>
        )}
        <Button
          onClick={handleCopy}
          iconClassName="size-5"
          icon={copied ? Icons.checkmark : Icons.copy}
          size="sm"
          variant="primaryGhost"
          className="rounded-full"
        >
          <span>{copied ? "Copied" : "Copy"}</span>
        </Button>
      </div>
    </div>
  );
};

export default GridItemResult;
