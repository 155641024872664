import { MicrositeState } from "../../../globalTypes";

export const stateLabels: Record<
  MicrositeState,
  { label: string; className: string }
> = {
  INITIAL: { label: "INITIAL", className: "!text-gray-500 !bg-gray-100" },
  PENDING_VALIDATION: {
    label: "PENDING VALIDATION",
    className: "!text-gray-500 !bg-gray-100",
  },
  CREATED: { label: "CREATED", className: "!text-green-500 !bg-green-100" },
  ERROR: { label: "ERROR", className: "!bg-red-100 !text-red-900" },
};

const sftUserHeaderClassName = "font-sans font-bold text-[#0F172A] text-[14px]";
export const sftUserTableHeaderItems = [
  {
    title: "Username",
    className: sftUserHeaderClassName,
  },
  {
    title: "Password",
    className: sftUserHeaderClassName,
  },
  {
    title: "SFTP Endpoint",
    className: sftUserHeaderClassName,
  },
  {
    title: "",
    className: "w-12",
  },
];